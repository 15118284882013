import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PdfCustomizeService } from 'src/app/modules/memos/service/pdf-customize.service';

@Component({
  selector: 'app-base-popup-setting-panel-item',
  templateUrl: './base-popup-setting-panel-item.component.html',
  styleUrls: ['./base-popup-setting-panel-item.component.scss'],
})
export class BasePopupSettingPanelItemComponent {
  @Input() dragdropDiv;
  @Input() isOpenPopup = false;

  @Output() openConfigList = new EventEmitter();

  @Input() markerIdentity;
  constructor(private pdfCustomizeService: PdfCustomizeService) {}

  toolsPopupList(dragdropDiv: HTMLDivElement) {
    const Y_THRESHOLD =
      this.pdfCustomizeService.pdfElementHeight * 0.7;
    const X_THRESHOLD =
      this.pdfCustomizeService.pdfElementWidth * 0.7;
    const X_INIT = this.markerIdentity.screenCoordinate?.x || 0;
    const Y_INIT = this.markerIdentity.screenCoordinate?.y || 0;
    const W_DRAGDROP = dragdropDiv.offsetWidth;
    const H_DRAGDROP = dragdropDiv.offsetHeight;
    const W_POPUP = 40;
    const W_PADDING = 5;
    const position = {
      left: undefined,
      right: undefined,
      bottom: undefined,
      top: undefined,
    };
    const isTooLeft =
      X_INIT + W_DRAGDROP + W_POPUP + W_PADDING > X_THRESHOLD;
    const isTooBottom = Y_INIT + H_DRAGDROP > Y_THRESHOLD;
    position.left = `${W_DRAGDROP + 50}px`;
    position.top = '77px';
    if (isTooLeft) {
      position.left = 'unset';
      position.top = `115px`;
      position.right = `${W_POPUP + W_PADDING}px`;
    }
    if (isTooBottom) {
      position.top = 'unset';
      position.bottom = `55px`;
      if (!isTooLeft) {
        position.left = `${W_DRAGDROP + W_PADDING}px`;
      }
    }
    return position;
  }
}
