<ng-template #attachmentModal
  let-c="close"
  let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'MEMOS.UPLOAD-ATTACHMENT'|translate }}</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="center form-group">
      <ngx-file-drop class="file-drop pointer"
          dropZoneLabel="Browse or Drop files here"
          (click)="multipleFileUpload.click()"
          (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector">
          <svg-icon src="assets/svg/upload.svg"
              [svgStyle]="{ 'fill': '#2D5B8F', 'height.px': 20, 'width.px': 20 }">
          </svg-icon>
          <span class="cus-blue pl-2">
            {{ 'UPLOAD.Browse or Drop files here' | translate }}
          </span>
        </ng-template>
      </ngx-file-drop>
      <input type="file"
          hidden
          multiple
          accept="audio/*,video/*,image/*,.csv,.ppt,.pptx,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
           application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        #multipleFileUpload
          [(ngModel)]="fileUpload"
          (change)="selectFile($event)">
      <ngx-spinner
          [name]="scanFileSpinner"
          bdColor="rgb(255 255 255 / 73%)"
          size="default"
          type="ball-elastic-dots"
          color="#0782d0"
          [fullScreen]="false">
        <p style="color: #0782d0;font-weight: bold;">Scanning...</p>
      </ngx-spinner>
      <div class="upload-table"
          style="word-break: break-all;"
          *ngIf="files.length !== 0">
        <table class="table">
          <thead>
          <tr>
            <th id="blank1"></th>
            <th id="blank2"></th>
          </tr>
          </thead>
          <tbody class="upload-name-style">
          <tr *ngFor="let item of files; let i = index">
            <td><strong>{{ item.relativePath || item.name }}</strong></td>
            <td class="text-right">
              <a href="javascript:void(0)"
                  (click)="removeFile(i,item)">
                <i class="fas fa-times text-red"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="closeModal()">
      {{cancel}}
    </button>
    <button class="btn"
        appSetTheme
        type="button"
        [disabled]="files.length === 0"
        [ladda]="isLoading"
        (click)="submit()">
      {{upload}}
    </button>
  </div>
</ng-template>