import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { ManageTypesUploadMemoService } from '../../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.service';
import { buildErrorHTMLMessage } from '../../utils/common.util';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-export-progress',
  templateUrl: './export-progress.component.html',
  styleUrls: ['./export-progress.component.scss'],
})
export class ExportProgressComponent implements OnInit, OnDestroy {
  celeryImportState = CeleryImportState;

  sendingTaskInterval;
  sendingTaskDetail: CeleryTaskStatus;

  @Input() timeout = 1000;
  @Input() resultTitle: string;
  @Input() taskId: string;
  @Output() sent = new EventEmitter<CeleryImportState>();
  @Output() path = new EventEmitter<string>();

  constructor(private apiService: ManageTypesUploadMemoService) {}

  ngOnInit(): void {
    this.cancelSendingTask();
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribe(this.taskId);
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };

    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.sendingTaskInterval = setInterval(() => {
      this.apiService.getCeleryTaskStatus(taskId).subscribe((res) => {
        // update progress
        this.sendingTaskDetail = res;
        // unsubscribe when success or failure
        if (
          res._state === CeleryImportState.Success ||
          res._state === CeleryImportState.Failure
        ) {
          this.path.emit(this.sendingTaskDetail.excel);
          this.sent.emit(res._state);
          clearInterval(this.sendingTaskInterval);
        }

        if (res._state === CeleryImportState.Failure) {
          res.detail = buildErrorHTMLMessage(res.detail);
        }
      });
    }, this.timeout);
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
  }

  replaceUnderscore(text: string, withChar = ' '): string {
    return text.split('_').join(withChar);
  }

  ngOnDestroy(): void {
    this.cancelSendingTask();
  }

  get resultKeys(): string[] {
    if (
      !this.sendingTaskDetail.rows ||
      !this.sendingTaskDetail.rows.length
    ) {
      return [];
    }

    return Object.keys(this.sendingTaskDetail.rows[0]);
  }

  get currentStateProgressType(): string {
    if (this.sendingTaskDetail._state === CeleryImportState.Success) {
      this.sendingTaskDetail.percent = 100;
      return 'success';
    }
    if (this.sendingTaskDetail._state === CeleryImportState.Failure) {
      return 'danger';
    }
    return 'info';
  }
}
