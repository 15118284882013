import { Injectable } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';

@Injectable({
  providedIn: 'root',
})
export class PdfCustomizeService {
  pdfElementWidth: number;
  pdfElementHeight: number;

  onPdfViewResized(event: ResizedEvent): void {
    if (event.newWidth !== event.oldWidth) {
      this.pdfElementWidth = event.newWidth;
    }
    if (event.newHeight !== event.oldHeight) {
      this.pdfElementHeight = event.newHeight;
    }
    if (!this.pdfElementWidth) {
      this.pdfElementWidth = event.newWidth;
    }
    if (!this.pdfElementHeight) {
      this.pdfElementHeight = event.newHeight;
    }
  }
}
