import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { InputCheckboxComponent } from '../input-checkbox/input-checkbox.component';
import { RadioOption } from '../form-inputs.model';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: [
    '../input-checkbox/input-checkbox.component.scss',
    '../input-textline/input-textline.component.scss',
    '../base-input/base-input.component.scss',
    './input-radio.component.scss',
  ],
})
export class InputRadioComponent extends InputCheckboxComponent {
  CHECK_TEXT = '●';
  @Input() checked = false;
  @Input() radioOption: RadioOption;

  @Output() radioChecked = new EventEmitter<string>();
  @Output() toggleRadio = new EventEmitter<{
    fieldName: string;
    data: string;
  }>();

  toggleCheck() {
    super.toggleCheck();
    if (this.checked) {
      this.radioChecked.emit(this.radioOption.fieldName);
    }
    this.toggleRadio.emit({
      fieldName: this.radioOption.fieldName,
      data: this.markerIdentity.data,
    });
  }
}
