import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormInputMarkerIdentity } from '../form-inputs.model';
import { InputTextlineComponent } from '../input-textline/input-textline.component';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: [
    './input-checkbox.component.scss',
    '../input-textline/input-textline.component.scss',
    '../base-input/base-input.component.scss',
  ],
})
export class InputCheckboxComponent
  extends InputTextlineComponent
  implements OnInit
{
  CHECK_TEXT = '✓';
  checked = false;

  @Input() markerIdentity: FormInputMarkerIdentity;
  autoDecreaseFontSize = false;

  @Output() toggle = new EventEmitter<string>();

  ngOnInit() {
    super.ngOnInit();
    this.checked = !!this.markerIdentity.data;
  }

  toggleCheck() {
    if (!this.canFillForm) {
      return;
    }
  }
}
