<div class="row mx-1 align-items-baseline"
    (window:resize)="onResize($event)">
  <div #tab
      class="selections-tab-container col px-1"
      id="tab">
    <div #scrollContent
        class="scroll-content"
        [ngClass]="checkPage ==='pageContractList' ? 'd-inline' : ' d-flex' "
        (scroll)="onScroll()">

      <!--------------------------------- left arrow ------------------------------->
      <ng-container>
        <div #leftArrow
            class="arrow left-arrow pointer"
            (click)="onClickScrollLeft()"
            [class.invisible]="arrowInvisibility.left ">
          <svg-icon src="assets/images/icons/back2.svg"
              [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"></svg-icon>
        </div>
      </ng-container>

      <!--------------------------------- item ------------------------------->
      <div #itemContainer
          class="tab w-100"
          [ngClass]="{'justify-content-between': newDesign && muteBadge}">
        <ng-container *ngFor="let item of items">
          <div class="item"
              *ngIf="!newDesign"
              [class.active]="activeItem?.name === item?.name"
              (click)="onClickItem(item)"
              [style.--bg]="themeList?.header_table_color"
              [style.--c]="themeList?.text_color">
            <span>{{(item.translateLabel | translate) || item.name}}</span>
            <div *ngIf="item.hasNotification && item.numberNotification > 0"
                class="item-notification">
              <div class="number">{{ item.numberNotification }}</div>
            </div>
          </div>
          <div class="item"
              [ngClass]="!muteBadge && checkPage === 'pageContractList' ? 'memo-w-100' :
              checkPage === 'pageAllDocuments' ? 'tab-menu' :'memo'"
              *ngIf="newDesign"
              [class.active]="activeItem?.name === item?.name"
              (click)="onClickItem(item)"
              [style.--bg]="item?.primaryColor"
              [style.--c]="item?.secondaryColor">
            <svg-icon [src]="'assets/images/icons/' + item.icon"
                *ngIf="item.icon"
                class="mr-2"
                [class.d-none]="!item.icon"
                [svgStyle]="{'fill': activeItem?.name === item?.name ? item?.primaryColor : 
                '#cdcdcd', 'height.px': 15, 'width.px': 15, 'marginBottom.px': 3 }">
            </svg-icon>
            <span [class.text-color]="!muteBadge && activeItem?.name === item?.name"
                [class.text-darkmute]="!muteBadge && activeItem?.name !== item?.name">
              {{ (item.translateLabel | translate) || item.name }}
            </span>
            <div *ngIf="item.hasNotification"
                class="item-notification square">
              <div class="number"
                  [class.number-color]="!muteBadge">{{ item.numberNotification }}</div>
            </div>
          </div>
        </ng-container>
      </div>

      <!--------------------------------- right arrow ------------------------------->
      <ng-container>
        <div #rightArrow
            [ngClass]="checkPage === 'pageContractList' ? 'd-none' : 'arrow right-arrow col-auto px-1 pointer'"
            (click)="onClickScrollRight()">
          <svg-icon src="assets/images/icons/next2.svg"
              [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"></svg-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>