import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownItem } from '../../../../../../../shared/models/common.model';
import { FormInputOptions } from '../../form-inputs.model';

@Component({
  selector: 'app-input-text-dropdown-setting',
  templateUrl: './input-text-dropdown-setting.component.html',
  styleUrls: ['./input-text-dropdown-setting.component.scss'],
})
export class InputTextDropdownSettingComponent {
  @Input() require: boolean;
  @Output() requireChange = new EventEmitter<boolean>();

  @Input() placeholder: string;
  @Output() placeholderChange = new EventEmitter<boolean>();

  @Input() dropdownOptions: FormInputOptions['text_dropdown'];
  @Output() dropdownOptionsChange = new EventEmitter<
    FormInputOptions['text_dropdown']
  >();

  @Output() selectedDefault = new EventEmitter<string>();

  @Input() markerIdentity;
  @Output() markerIdentityChange = new EventEmitter();

  @ViewChild('optionsContainer') optionsContainer: ElementRef;

  currentSelected: string;

  onEnter(value: string, elem: HTMLInputElement) {
    if (value === '') {
      return;
    }
    this.addItem(value);
    elem.value = '';
  }

  addItem(value: string) {
    this.dropdownOptions.items = [
      ...this.dropdownOptions.items,
      value,
    ];
    this.dropdownOptionsChange.emit(this.dropdownOptions);

    // scroll div to bottom
    setTimeout(() => {
      this.optionsContainer.nativeElement.scrollTop =
        this.optionsContainer.nativeElement.scrollHeight;
    });
  }

  removeItem(index: number) {
    const itemValue = this.dropdownOptions.items[index];
    if (itemValue === this.dropdownOptions.default) {
      this.dropdownOptions.default = null;
      this.selectedDefault.emit(null);
      this.markerIdentity.data = null;
    }

    this.dropdownOptions.items.splice(index, 1);
    this.reloadItems();
    this.dropdownOptionsChange.emit(this.dropdownOptions);
  }

  movedItem(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.dropdownOptions.items,
      event.previousIndex,
      event.currentIndex,
    );
    this.dropdownOptionsChange.emit(this.dropdownOptions);
  }

  updateItem(index: number, event): void {
    // update default if selected
    if (
      this.dropdownOptions.default ===
      this.dropdownOptions.items[index]
    ) {
      this.dropdownOptions.default = event.target.value;
    }

    if (this.currentSelected === this.dropdownOptions.items[index]) {
      this.markerIdentity.data = event.target.value;
    }
    // update option
    this.dropdownOptions.items[index] = event.target.value;
    this.reloadItems();
  }

  reloadItems(): void {
    this.dropdownOptions.items = [...this.dropdownOptions.items];
  }

  onSelectedDefault(value: string | null) {
    this.dropdownOptions.default = value;
    this.markerIdentity.options = this.dropdownOptions;
    this.markerIdentity.data = value;
    this.currentSelected = value;
    this.dropdownOptionsChange.emit(this.dropdownOptions);
    this.selectedDefault.emit(value);
  }

  onChange(
    value: any,
    field: string,
    dropdownOptions?: FormInputOptions['text_dropdown'],
  ) {
    if (dropdownOptions) {
      dropdownOptions.duplicateName = false;
    }
    if (field === 'require') {
      this.markerIdentity.require = value;
      return;
    }
    this.markerIdentity.options[field] = value;
  }

  setValidate() {
    this.markerIdentity.options.duplicateName = false;
  }
}
