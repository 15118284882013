<div class="d-flex">
  <div class="left-panel width-content" #containerRef
      *ngIf="toggleSideMenu"
      (window:resize)="onResize($event)"
      (clickOutside)="closeToggle($event)">
    <div class="content-frame m-0 left-ps">
      <div class="m-2">
        <div class="d-flex">
          <h4 class="flex-grow-1 font-weight-bolder">{{'APPROVAL.APPROVAL-REQUEST'| translate}}</h4>
          <h4 class="font-weight-bold"
              style="color:#29adce">{{ listFilterByDateTypeCount$ | async }}</h4>
        </div>
        <hr style="margin-top: 10px">
        <input type="text"
            class="form-control"
            style="padding-right: 40px"
            placeholder="{{ 'DASHBOARD.SEARCH' | translate }}"
            [(ngModel)]="search.search"
            (change)="onFilterChange()"
            (keyup)="searchInput$.next($event.target.value)">
        <div>
          <div class="span-calendar d-inline"
              (click)="showToggle=!showToggle">
            <svg-icon [svgStyle]="{'fill': search.search ||
                        search.department ||
                        search.memo_type ||
                        search.upload_memo_type ||
                        search.expireItem? '#03a9f4' : '#000'}"
                src="assets/images/icons/filter.svg"></svg-icon>
          </div>
        </div>
        <div class="position-relative filter">
          <div *ngIf="showToggle"
              class="width-box py-4">
            <div class="form-group mx-1 mb-4">
              <label>
                {{"MEMOS.DEPARTMENT"|translate}}
              </label>
              <ng-select *ngIf="!refreshDropdown"
                  [items]="departmentList"
                  placeholder="{{'MEMOS.SELECT'|translate}}"
                  [bindLabel]="currentLang === 'en' ? 'context.display_name_en': 'context.display_name'"
                  [notFoundText]="'SELECT.No items found'|translate"
                  (change)="setSearchLabel($event,'dept')"
                  class="search-filter"
                  [(ngModel)]="search.department"
                  bindValue="value">
              </ng-select>
            </div>
            <div class="form-group mx-1 mb-4">
              <label>
                {{"MEMOS.MEMO-TYPE"|translate}}
              </label>
              <ng-select *ngIf="!refreshDropdown"
                  [items]="memoTypeList"
                  placeholder="{{'MEMOS.SELECT'|translate}}"
                  [bindLabel]="currentLang === 'en' ? 'context.display_name_en': 'context.display_name'"
                  [notFoundText]="'SELECT.No items found'|translate"
                  (change)="setSearchLabel($event,'memoType')"
                  class="search-filter"
                  [(ngModel)]="search.memo_type"
                  bindValue="value">
              </ng-select>
            </div>
            <div class="form-group mx-1 mb-4">
              <label>
                {{"MEMO-UPLOAD-TYPE.Upload Memo Type" | translate}}
              </label>
              <ng-select *ngIf="!refreshDropdown"
                  [items]="memoUploadTypeList"
                  placeholder="{{'MEMOS.SELECT'|translate}}"
                  [bindLabel]="currentLang === 'en' ? 'context.name_en': 'label'"
                  [notFoundText]="'SELECT.No items found'|translate"
                  (change)="setSearchLabel($event,'uploadMemoType')"
                  class="search-filter"
                  [(ngModel)]="search.upload_memo_type"
                  bindValue="value"
                  [disabled]="search.memo_type !== 'upload'">
              </ng-select>
            </div>
            <div class="form-group mx-1 mb-4">
              <label>{{"MEMOS.Period"|translate}}</label>
              <input name="datepicker"
                  readonly
                  #dateFilter
                  class="form-control input-lg"
                  ngbDatepicker
                  autocomplete="off"
                  container="body"
                  #datepicker="ngbDatepicker"
                  [autoClose]="'outside'"
                  [value]="rangeDate"
                  (dateSelect)="onDateSelection($event)"
                  [displayMonths]="1"
                  [dayTemplate]="t"
                  placeholder="{{'MEMOS.SELECT' | translate}}"
                  (click)="datepicker.toggle()"
                  [startDate]="fromDate!">
              <ng-template #t
                  let-date
                  let-focused="focused">
                <span class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
              <span class="span-calendar">
                <i class="fa fa-calendar i-calendar"
                    aria-hidden="true"
                    ngbDatepicker
                    (click)="datepicker.toggle()"></i>
              </span>
            </div>
            <div class="form-group mx-1 mb-4">
              <label>
                {{"CERTIFICATE.EXPIRE-DATE"|translate}}
              </label>
              <ng-select *ngIf="!refreshDropdown"
                  [items]="expireItem"
                  placeholder="{{'MEMOS.SELECT'|translate}}"
                  [bindLabel]="currentLang === 'en' ? 'label_en': 'label'"
                  [notFoundText]="'SELECT.No items found'|translate"
                  class="search-filter"
                  (change)="setSearchLabel($event,'expire_item')"
                  [(ngModel)]="search.expireItem"
                  bindValue="value">
              </ng-select>
            </div>
            <div class="text-right">
              <div class="btn mx-1 btn-outline"
                  (click)="clearSearch()"
                  [style.--c]="themeList?.header_table_color"
                  [style.--bg]="'#fff'">
                {{'LOG.CLEAR'| translate}}
              </div>
              <div class="btn mx-1 p-btn"
                  appSetTheme
                  type="button"
                  (click)="onClickSearch()">
                {{'DASHBOARD.SEARCH'| translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2 d-flex"
          #searchList
          *ngIf="isSearch">
        <div class="flex-shrink-0 align-self-center mr-2">{{ 'SELECT.SEARCH' | translate }}</div>
        <div class="filter-group">
          <div class="filter m-1"
              *ngIf="search.search">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">{{ search.search }}</span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(1)">&times;</span>
            </div>
          </div>
          <div class="filter m-1"
              *ngIf="search.department && isClickSearch.department">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">
                {{ currentLang === 'en' ? search.dept.context.display_name_en : search.dept.context.display_name }}
              </span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(2)">&times;</span>
            </div>
          </div>
          <div class="filter m-1"
              *ngIf="search.memo_type && isClickSearch.memo_type">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">
                {{ currentLang === 'en' ? search.memoType.context.display_name_en : search.memoType.context.display_name
                }}
              </span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(3)">&times;</span>
            </div>
          </div>

          <div class="filter m-1"
              *ngIf="search.upload_memo_type && isClickSearch.upload_memo_type">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">
                {{ currentLang === 'en' ? search.uploadMemoType.context.name_en : search.uploadMemoType.label }}
              </span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(6)">&times;</span>
            </div>
          </div>

          <div class="filter m-1"
              *ngIf="(search.date_at_after || search.date_at_before) && isClickSearch.dateRange">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">
                {{search.date_at_after | date :"dd/MM/yyy"}} {{search.date_at_before | date :"- dd/MM/yyyy"}}
              </span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(4)">&times;</span>
            </div>
          </div>

          <div class="filter m-1"
              *ngIf="search.expireItem && isClickSearch.expireItem">
            <div class="d-flex flex-shrink-0">
              <span class="align-self-center">
                {{ currentLang === 'en' ? search.expire_item.label_en : search.expire_item.label }}
              </span>
              <span aria-hidden="true"
                  class="ml-2 pointer align-self-center f-16"
                  (click)="removeSearch(5)">&times;</span>
            </div>
          </div>
        </div>
      </div>

      <div #widthPage>
        <perfect-scrollbar class="custom-height"
          [ngStyle]="{
            'max-height': 'calc(100dvh - '+calculateHeightSearch(heightSearch)+'px',
            'height': !memoList?.length?'calc(100dvh - '+calculateHeightSearch(heightSearch)+'px': '100%'
           }"
        >
        <div class="d-flex flex-column m-2"
          *ngIf="listFilterByDateType$ | async as list">
          <div class="flex-grow-1 d-flex flex-column">
            <ng-container *ngIf="list?.length > 0">
              <ng-container *ngFor="let memo of list">
                <ng-container [ngTemplateOutlet]="memoItemTemplate"
                  [ngTemplateOutletContext]="{'memo': memo}"></ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="list?.length <= 0">
              <ng-container [ngTemplateOutlet]="noMemoTemplate"></ng-container>
            </ng-container>
          </div>
        </div>
        </perfect-scrollbar>
      </div>
<!--      <div #widthPage>-->
<!--        <perfect-scrollbar class="custom-height"-->
<!--            [ngStyle]="{'max-height': 'calc(100vh - '+calculateHeightSearch(heightSearch)+'px',-->
<!--            'height': !memoList?.length?'calc(100vh - '+calculateHeightSearch(heightSearch)+'px': '100%'}">-->
<!--          <div *ngFor="let memo of memoList"-->
<!--              href="javascript:void(0)"-->
<!--              class="memo m-2"-->
<!--              [ngClass]="{'selected': checkIfSelected(memo)}"-->
<!--              (click)="clickMemo(memo) "-->
<!--              [hidden]="!showResults">-->
<!--            <div class="p-2 pointer memo-shadow d-flex">-->
<!--              <div class="flex-grow-1">-->
<!--                <span class="memo-name pre-wrap">{{ memo.subject || ('MEMOS.UNTITLED'| translate) }}</span>-->
<!--                <div class="small pt-2">-->
<!--                  <span class="text-muted mr-2">{{ 'MEMOS.MEMO-NUMBER' | translate }} :</span>-->
<!--                  <span class="text-muted">{{ memo.memo_number }}</span>-->
<!--                </div>-->
<!--                <div class="small pt-2">-->
<!--                  <span class="text-muted mr-2">{{"MEMOS.CREATED-BY" | translate}} :</span>-->
<!--                  <span class="text-muted">{{ memo?.created_user_name || ('MEMOS.NO-ACTOR'| translate) }}</span>-->
<!--                </div>-->
<!--                <div class="small pt-2"-->
<!--                    *ngIf="memo?.expiry_date">-->
<!--                  <span class="text-muted mr-2">{{"MEMOS.Expired date" | translate}} :</span>-->
<!--                  <span class="text-muted">{{ (memo?.expiry_date| date: 'dd/MM/yyyy') || ('MEMOS.NO-ACTOR'| translate)-->
<!--                    }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="d-flex flex-row align-items-baseline justify-content-end py-2 align-self-center">-->
<!--                <div *ngIf="memo.is_review">-->
<!--                  <span class="tag review">-->
<!--                    <span>-->
<!--                      <svg-icon src="assets/images/icons/report-ack.svg"></svg-icon>-->
<!--                      {{ 'APPROVAL.WAITING-REVIEW' | translate }}-->
<!--                    </span>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div *ngIf="!memo.is_review">-->
<!--                  <span class="tag pending">-->
<!--                    <span>-->
<!--                      <svg-icon src="assets/images/icons/memo-pending.svg"></svg-icon>-->
<!--                      {{'APPROVAL.PENDING'| translate}}-->
<!--                    </span>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div *ngIf="!memoList?.length && showResults"-->
<!--              class="text-center d-flex justify-content-center"-->
<!--              style="height: 85%">-->
<!--            <div class="my-auto">-->
<!--              <svg-icon src="assets/images/icons/no-approval-memo.svg"-->
<!--                  class="d-block"-->
<!--                  [svgStyle]="{'width.px': 230, 'height.px': 230}"></svg-icon>-->
<!--              <div style="font-weight: 300">{{'APPROVAL.CONTENT-TWO'| translate}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </perfect-scrollbar>-->
<!--      </div>-->
    </div>
  </div>
  <div class="content-frame"
      (window:resize)="onResize($event)"
      style="width: 100%; min-width: calc(100% - 395px); overflow-x: auto !important"
      *ngIf="(isDesktop && memoList?.length) || selectedMemo">
    <app-executive-detail (actionChanged)="actionChange($event)"
        [memoId]="selectedMemo.id"
        [isDesktop]="isDesktop"
        [viewType]="parentViewType"
        *ngIf="selectedMemo"></app-executive-detail>
    <div class="w-100 h-100 tablet-d-none"
        *ngIf="!selectedMemo">
      <div class="no-data">
        <h5>{{'APPROVAL.CONTENT-ONE'| translate}}</h5>
      </div>
    </div>
  </div>
</div>

<ng-template #memoItemTemplate
  let-memo="memo">
  <div href="javascript:void(0)"
    class="memo mt-2"
    [ngClass]="{'selected': checkIfSelected(memo)}"
    (click)="clickMemo(memo) "
    [hidden]="!showResults">
    <div class="p-2 pointer memo-shadow d-flex">
      <div class="flex-grow-1">
        <span class="memo-name pre-wrap">{{ memo.subject || ('MEMOS.UNTITLED'| translate) }}</span>
        <div class="small pt-2">
          <span class="text-muted mr-2">{{ 'MEMOS.MEMO-NUMBER' | translate }} :</span>
          <span class="text-muted">{{ memo.memo_number }}</span>
        </div>
        <div class="small pt-2">
          <span class="text-muted mr-2">{{"MEMOS.CREATED-BY" | translate}} :</span>
          <span class="text-muted">{{ memo?.created_user_name || ('MEMOS.NO-ACTOR'| translate) }}</span>
        </div>
        <div class="small pt-2"
          *ngIf="memo?.expiry_date">
          <span class="text-muted mr-2">{{"MEMOS.Expired date" | translate}} :</span>
          <span class="text-muted">{{ (memo?.expiry_date| date: 'dd/MM/yyyy') || ('MEMOS.NO-ACTOR'| translate)
            }}</span>
        </div>
      </div>
      <div class="d-flex flex-column align-items-end justify-content-between">
        <div *ngIf="memo.is_review" class="my-2">
          <span class="tag review">
            <span>
              <svg-icon src="assets/images/icons/report-ack.svg"></svg-icon>
              {{ 'APPROVAL.WAITING-REVIEW' | translate }}
            </span>
          </span>
        </div>
        <div *ngIf="!memo.is_review" class="my-2">
          <span class="tag pending">
            <span>
              <svg-icon src="assets/images/icons/memo-pending.svg"></svg-icon>
              {{'MEMOS.PENDING'| translate}}
            </span>
          </span>
        </div>

        <div *ngIf="memo?.due_date"
          class="small text-muted">
          <span>{{ memo?.due_date | date: 'dd/MM/yyyy' }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noMemoTemplate>
  <div class="d-flex flex-column justify-content-center align-items-center mt-3">
    <svg-icon src="assets/images/icons/no-approval-memo.svg"
      class="d-block"
      [svgStyle]="{'width.px': 120, 'height.px': 120}"></svg-icon>
    <div style="font-weight: 300">{{'APPROVAL.CONTENT-TWO'| translate}}</div>
  </div>
</ng-template>
