import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export const baseOrderingItems = [
  { label: 'Name A-Z', value: 'name' },
  { label: 'Name Z-A', value: '-name' },
  { label: 'Most Recent', value: '-created_at' },
  { label: 'Least Recent', value: 'created_at' },
];

export const defaultOrdering = '-created_at';

export const roleItems = [
  { id: 0, name: 'Admin' },
  { id: 1, name: 'Requester' },
  { id: 2, name: 'Accountant' },
  { id: 3, name: 'Manager' },
];

export const baseOrderingMemo = [
  { label: 'Subject A-Z', value: 'subject' },
  { label: 'Subject Z-A', value: '-subject' },
  { label: 'Most Recent', value: '-created_at' },
  { label: 'Least Recent', value: 'created_at' },
];

export const personImage = 'assets/images/person.png';

export const memoStatusChoices = [
  { id: 'draft', name: 'Draft' },
  { id: 'pending', name: 'Pending' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'terminated', name: 'Terminated' },
];

export const operationLogDetailChoices = [
  { id: 'News feed', name: 'News feed' },
  { id: 'Pending for approve', name: 'Pending for approve' },
  { id: 'Memo list', name: 'Memo list' },
  { id: 'Dashboard', name: 'Dashboard' },
  { id: 'Manage approval', name: 'Manage approval' },
  { id: 'Add department', name: 'Add department' },
  { id: 'Manage user', name: 'Manage user' },
  { id: 'User profile', name: 'User profile' },
  { id: 'Configuration', name: 'Configuration' },
  { id: 'Login', name: 'Login' },
  { id: 'Invoice list', name: 'Invoice list' },
  { id: 'Clear PO list', name: 'Clear PO list' },
];

export const CALENDAR_LOCALE = {
  TH: {
    firstDayOfWeek: 0,
    dayNames: [
      'จันทร์',
      'อังคาร',
      'พุธ',
      'พฤหัสบดี',
      'ศุกร์',
      'เสาร์',
      'อาทิตย์',
    ],
    dayNamesShort: ['จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.', 'อา.'],
    dayNamesMin: ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'],
    monthNames: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
    monthNamesShort: [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ],
    today: 'วันนี้',
    clear: 'ล้างข้อมูล',
  },
  EN: {
    firstDayOfWeek: 0,
    dayNames: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    dayNamesShort: [
      'Mon.',
      'Tue.',
      'Wed.',
      'Thu.',
      'Fri.',
      'Sat.',
      'Sun.',
    ],
    dayNamesMin: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ],
    today: 'Today',
    clear: 'Clear',
  },
};

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export interface Result {
  id: number;
  full_name: string;
  job_position: string;
}

export interface RESPONSE<T = unknown> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export type RESPONSE$<T = unknown> = Observable<RESPONSE<T>>;

export type ApiParams =
  | FormData
  | HttpParams
  | {
      [param: string]: unknown | unknown[];
    };

export const EMAIL_PATTERN =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
