import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { DateFormatService } from 'src/app/shared/service/date-format.service';

@Component({
  selector: 'app-input-date-setting',
  templateUrl: './input-date-setting.component.html',
  styleUrls: ['./input-date-setting.component.scss'],
})
export class InputDateSettingComponent implements OnInit {
  formatItems;

  @Input() duplicateName;

  @Input() require: boolean;
  @Output() requireChange = new EventEmitter<boolean>();

  @Input() fieldName: string;
  @Output() fieldNameChange = new EventEmitter<string>();

  @Input() format: string;
  @Output() formatChange = new EventEmitter<string>();

  @Input() options;
  @Output() optionsChange = new EventEmitter();

  constructor(
    private dateFormatService: DateFormatService,
    private memoService: MemoService,
  ) {}

  ngOnInit() {
    this.formatItems =
      this.dateFormatService.constructFormatListFromDate();
  }

  changeFormat(event) {
    const data = {
      name: this.fieldName,
      event: event,
    };
    this.memoService.setWidthDate('format');
    this.memoService.setInputDate(data);
    this.formatChange.emit(event);
  }

  setValidate() {
    this.options.duplicateName = false;
  }
}
