import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativePdfFontSize',
})
export class RelativePdfFontSizePipe implements PipeTransform {
  protected _pdfElementHeight: number;

  transform(fontSize: number, pdfElementHeight: number): number {
    this._pdfElementHeight = pdfElementHeight;
    return this.calcFontSizePx(fontSize);
  }

  calcFontSizePx(size: number): number {
    // I will not use Math.round because input-textline-component will auto adjust fontsize to match width/height of textbox.
    //    if I use Math.round, text will overflow.
    return Math.floor((size / 100) * this._pdfElementHeight);
  }
}
