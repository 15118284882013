<app-base-popup-setting-panel-item [markerIdentity]="markerIdentity"
    [dragdropDiv]="dragdropDiv"
    [isOpenPopup]="markerIdentity.isShowToolsMenuList"
    (openConfigList)="openConfigList()">
  <svg-icon icon
      src="/assets/images/icons/list.svg"
      [svgStyle]="{ 'height.px': 15,'width.px': 15 ,'fill':'#2FB4FF' }">
  </svg-icon>
  <div pop-up
      class="popup-menu-list text-left pl-2 pt-1 pb-2"
      style="bottom: 45px;">
    <div *ngIf="markerIdentity.formInputType==='text_line'">
      <app-input-textline-setting [marker]="marker"
          [(markerIdentity)]="markerIdentity"></app-input-textline-setting>
    </div>
    <ng-container *ngIf="markerIdentity.formInputType === 'text_dropdown'">
      <app-input-text-dropdown-setting [require]="markerIdentity.require"
          [(markerIdentity)]="markerIdentity"
          [placeholder]="markerIdentity.options.fieldName"
          [dropdownOptions]="markerIdentity.options">
      </app-input-text-dropdown-setting>
    </ng-container>
    <ng-container *ngIf="markerIdentity.formInputType === 'date'">
      <app-input-date-setting [require]="markerIdentity.require"
          (requireChange)="onChangeValue($event, 'require')"
          [fieldName]="markerIdentity.options.fieldName"
          (fieldNameChange)="onChangeValue($event,'fieldName')"
          [format]="markerIdentity.options.dateFormat"
          [(options)]="markerIdentity.options"
          (formatChange)="onChangeValue($event,'dateFormat')">
      </app-input-date-setting>
    </ng-container>

    <ng-container *ngIf="markerIdentity.formInputType === 'number'">
      <app-input-number-setting [require]="markerIdentity.require"
          (requireChange)="onChangeValue($event, 'require')"
          [fieldName]="markerIdentity.options.fieldName"
          (fieldNameChange)="onChangeValue($event, 'fieldName')"
          [duplicateName]="markerIdentity.options.duplicateName"
          [maxValue]="markerIdentity.options.maxValue"
          (maxValueChange)="onChangeValue($event,'maxValue')"
          [minValue]="markerIdentity.options.minValue"
          (validateMinMaxSetting)="markerIdentity.options.validateMinMaxSetting = $event"
          [(errorOption)]="markerIdentity.options"
          [(option)]="markerIdentity.options"
          (minValueChange)="onChangeValue($event,'minValue')">
      </app-input-number-setting>
    </ng-container>
    <div *ngIf="markerIdentity.formInputType==='text_line' || markerIdentity.formInputType==='text_dropdown' 
        || markerIdentity.formInputType==='date' || markerIdentity.formInputType==='number'">
      <div class=mb-2>
        <label class="mt-2"
            for="fontStyleSelection">{{'UPLOAD.FONT'|translate}}<span class="text-red">*</span>
        </label>
        <ng-select class=""
            id="fontStyleSelection"
            [items]="fontList"
            [(ngModel)]="markerIdentity.fontStyle"
            bindLabel="name"
            placeholder="select font"
            [notFoundText]="'SELECT.No items found'|translate"
            [clearable]="false"
            appendTo=".template-custom-pdf-view"
            [closeOnSelect]="true">
        </ng-select>
      </div>


      <div class="mb-2">
        <label for="fontSizeInput"
            class="mt-2">
          {{'UPLOAD.SIZE'|translate}}</label>
        <input id="fontSizeInput"
            [min]="1"
            [style.height.px]="36"
            type="number"
            (keypress)="numberOnly($event)"
            class="form-control text-charcoal"
            [value]="calcFontSizePx(markerIdentity.fontSize)"
            (change)="onChange($event.target.value)">
      </div>
    </div>
    <ng-container *ngIf="markerIdentity.formInputType === 'radio' || markerIdentity.formInputType === 'radio_checkbox'">
      <app-input-radio-setting [require]="markerIdentity.require"
          [groupId]="markerIdentity.options.fieldName"
          [markers]="markers"
          [themeList]="themeList"
          [(options)]="markerIdentity.options"
          [fieldValue]="markerIdentity.options.fieldValue"
          [markerIdentity]="markerIdentity">
      </app-input-radio-setting>
    </ng-container>
  </div>
</app-base-popup-setting-panel-item>