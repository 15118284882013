<div class="progress mx-auto"
  [style.background-color]="backgroundColor">

  <div class="progress-done"
    [style.--o]="opacity"
    [style.background-color]="progressColor"
    [style.width]="calProgressStyle"></div>

  <div class="progress-text" [style.color]="textColor">
    <span>{{"LOA.LEVEL-OF-APPROVAL"|translate}} {{approvalCount}}
      <span *ngIf="approvalTotal">/ {{approvalTotal}}</span>
    </span>
  </div>

</div>
