import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InputTextlineComponent } from '../input-textline/input-textline.component';
import {
  FormInputMarkerIdentity,
  FormInputOptions,
  FormInputType,
  Modify,
} from '../form-inputs.model';

@Component({
  selector: 'app-input-text-dropdown',
  templateUrl: './input-text-dropdown.component.html',
  styleUrls: [
    './input-text-dropdown.component.scss',
    '../input-textline/input-textline.component.scss',
    '../base-input/base-input.component.scss',
  ],
})
export class InputTextDropdownComponent
  extends InputTextlineComponent
  implements OnInit
{
  displayItems = false;

  @Input() markerIdentity: FormInputMarkerIdentity;
  @Input() dropdownOptions: FormInputOptions['text_dropdown'];

  @Output() selectedDropdown = new EventEmitter<string>();
  @Input() draggingOver = false;
  @Output() draggingOverChange = new EventEmitter<boolean>();

  @Output() setDragDropOnTop = new EventEmitter();
  ngOnInit() {
    super.ngOnInit();
    if (this.canFillForm) {
      if (!this.markerIdentity.data && this.dropdownOptions.default) {
        this.onTextChange(this.dropdownOptions.default);
      }
    }
  }

  toggleDisplayItems(event) {
    event.stopPropagation();

    // กรณีกดลาก dragdrop บนปุ่ม dropdown ตอนที่ปล่อยไม่ต้องการให้ popup dropdown items เด้งขึ้นมา
    if (this.draggingOver) {
      this.draggingOverChange.emit(false);
      return;
    }
    if (this.dropdownOptions.items.length === 0) {
      return;
    }
    this.displayItems = !this.displayItems;
    this.markerIdentity.zIndex = this.displayItems ? 1 : 0;
    this.setDragDropOnTop.emit();
  }

  onSelectedItem(val: string) {
    if (!this.canFillForm) {
      return;
    }
    this.onTextChange(val);
    this.displayItems = false;
    this.selectedDropdown.emit(val);
  }
  closeDropdown() {
    this.displayItems = false;
  }

  getWidth() {
    const hideIconSetting = 24;
    if (!this.canSettingForm && !this.canFillForm) {
      return this._width - 4 + hideIconSetting;
    } else if (this.canFillForm) {
      return this._width + hideIconSetting;
    } else {
      return this._width - 2;
    }
  }
}
