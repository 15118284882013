import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  navBarNameList: { url: string; name: string }[] = [
    { url: 'news-feed', name: 'FEED.NEWS-FEED' },
    { url: 'users', name: 'SIDEBAR.MANAGE-USERS' },
    { url: 'department/add', name: 'SIDEBAR.DEPARTMENT' },
    { url: 'department/level', name: 'SIDEBAR.DEPARTMENT-LEVEL' },
    { url: 'kpi-dashboard', name: 'SIDEBAR.DASHBOARD' },
    { url: 'configuration', name: 'SIDEBAR.CONFIGURATION' },
    { url: 'cc-group', name: 'SIDEBAR.MANAGE-CC-GROUP' },
    { url: 'certificate', name: 'SIDEBAR.CERTIFICATE' },
    {
      url: 'manage-types-upload-memo/type',
      name: 'MEMO-UPLOAD-TYPE.MEMO-UPLOAD-TYPE',
    },
    {
      url: 'manage-types-upload-memo/template',
      name: 'MEMO-UPLOAD-TEMPLATE.MEMO-UPLOAD-TEMPLATE',
    },
    { url: 'loa', name: 'LOA.LINE-OF-APPROVAL' },
    { url: 'memos', name: 'SIDEBAR.MEMOS' },
    { url: 'approval', name: 'SIDEBAR.PENDING' },
    { url: 'operation-log', name: 'SIDEBAR.OPERATION-LOG' },
    { url: 'memo-report', name: 'SIDEBAR.MEMO-REPORT' },
    { url: 'profile-detail', name: 'PROFILE.PROFILE' },
  ];
  private setnavbar = new BehaviorSubject<boolean>(false);
  private navbarTitle = new BehaviorSubject<string>('News feed');

  data = this.setnavbar.asObservable();

  getNavbarTitle() {
    return this.navbarTitle;
  }

  setActiveSidebar(active: boolean) {
    this.setnavbar.next(active);
  }
  setNavbarTitle(title: string) {
    this.navbarTitle.next(title);
  }
}
