import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  ContractOtpSmsService,
  OtpSmsService,
} from '../../../modules/profile/component/setting-otp-sms/otp-sms.service';
import { SetRef } from '../../../store/otp/otp.actions';
import { AlertService } from '../../../core/services/alert.service';
import { OtpMailService } from '../../../modules/profile/component/setting-otp/otp-mail.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit {
  @Input() isSaved: boolean;
  @Input() otp: string | number;
  @Output() otpChange = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<void>();
  @Input() header;

  @ViewChild('otpGoogleAuthen', { static: false })
  otpGoogleAuthen: ElementRef;
  @ViewChild('otpEmail', { static: false })
  otpEmail: ElementRef;
  @ViewChild('otpPhoneNumber', { static: false })
  otpPhoneNumber: ElementRef;

  timeLeft = 300;
  interval;

  otpType = 'totp';
  userPhoneNumber: string;
  userEmail: string;

  refDisPlay: string;
  isLoadingOTP: boolean;

  memoId = null;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: 'each_input',
  };
  memoType: string;

  constructor(
    public modalService: NgbModal,
    private store: Store,
    private otpSmsService: OtpSmsService,
    private contractOtpSmsService: ContractOtpSmsService,
    private otpMailService: OtpMailService,
    private alert: AlertService,
  ) {}

  ngOnInit(): void {
    this.store.subscribe((s) => {
      this.otpType = this.otpType || s.auth?.otp_type;
      this.userPhoneNumber =
        this.userPhoneNumber || s.auth?.phone_number;
      this.userEmail = this.userEmail || s.auth?.email;
    });
  }

  getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.header);
  }

  openModal(forceOTPType?, memoId?: number, memoType?: string): void {
    if (forceOTPType) {
      this.otpType = forceOTPType;
    }
    if (this.otpType === 'totp') {
      this.openTOTP();
      return;
    }
    this.memoId = memoId;
    this.memoType = memoType;
    this.sendOTP();
  }

  openTOTP() {
    this.modalService.open(this.otpGoogleAuthen, {
      backdrop: 'static',
      centered: true,
    });
  }

  onOtpChange(otp: number): void {
    this.otp = otp;
  }

  async sendOTP() {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let res = null;

    try {
      if (this.otpType === 'sms') {
        const smsData = {
          memo_id: this.memoId,
          resend: false,
          detail: null,
        };

        if (this.memoType == 'contract') {
          res = await this.contractOtpSmsService
            .send(this.memoId, header, smsData)
            .toPromise();
        } else {
          res = await this.otpSmsService
            .send(smsData, header)
            .toPromise();
        }
      } else {
        res = await this.otpMailService.send({}, header).toPromise();
      }
    } catch (error) {
      console.error(error);
      this.isLoadingOTP = false;
      if (error.status === 400 && error.error) {
        const firstKey = Object.keys(error.error)[0];
        try {
          const errorObj = JSON.parse(error.error[firstKey]);
          if ('detail' in errorObj) {
            this.alert.error(errorObj.detail);
          } else {
            this.alert.error(error.error[firstKey]);
          }
        } catch (e) {
          this.alert.error(error.error[firstKey]);
        }
      } else {
        this.store.dispatch(new ErrorNotification(error));
      }
      return;
    }

    if (res.phone_number) {
      this.userPhoneNumber = res.phone_number;
    }
    this.refDisPlay = res.ref_display;
    this.store.dispatch(
      new SetRef({
        ref: res.ref,
        refDisplay: this.refDisPlay,
      }),
    );
    this.isLoadingOTP = false;

    if (this.otpType === 'sms') {
      this.openSMSOTP();
    } else {
      this.openEmailOTP();
    }
  }

  openSMSOTP() {
    this.modalService.open(this.otpPhoneNumber, {
      backdrop: 'static',
      centered: true,
    });
    this.startTimer();
  }

  openEmailOTP() {
    this.modalService.open(this.otpEmail, {
      backdrop: 'static',
      centered: true,
    });
    this.startTimer();
  }

  startTimer(): void {
    this.pauseTimer();
    this.timeLeft = 300;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  async resend() {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    let res = null;

    try {
      if (this.otpType === 'sms') {
        const smsData = {
          memo_id: this.memoId,
          resend: true,
          detail: null,
        };

        if (this.memoType == 'contract') {
          res = await this.contractOtpSmsService
            .send(this.memoId, header, smsData)
            .toPromise();
        } else {
          res = await this.otpSmsService
            .send(smsData, header)
            .toPromise();
        }
      } else {
        res = await this.otpMailService
          .send(this.memoId, header)
          .toPromise();
      }
    } catch (error) {
      console.error(error);
      this.isLoadingOTP = false;
      if (error.status === 400 && error.error) {
        const firstKey = Object.keys(error.error)[0];
        try {
          const errorObj = JSON.parse(error.error[firstKey]);
          if ('detail' in errorObj) {
            this.alert.error(errorObj.detail);
          } else {
            this.alert.error(error.error[firstKey]);
          }
        } catch (e) {
          this.alert.error(error.error[firstKey]);
        }
      } else {
        this.store.dispatch(new ErrorNotification(error));
      }
      return;
    }

    this.refDisPlay = res.ref_display;
    this.store.dispatch(
      new SetRef({
        ref: res.ref,
        refDisplay: this.refDisPlay,
      }),
    );
    this.isLoadingOTP = false;
    this.startTimer();
    this.otp = null;
  }

  close(): void {
    this.modalService.dismissAll();
    this.otp = null;
    this.memoId = null;
    this.closeModal.emit();
  }

  get invalidOTP(): boolean {
    return !this.otp || String(this.otp).length < 6;
  }

  get hiddenNumber(): string {
    if (!this.userPhoneNumber) {
      return '**********';
    }
    return (
      this.userPhoneNumber.substring(0, 3) +
      '****' +
      this.userPhoneNumber.substring(7)
    );
  }

  get hiddenEmail(): string {
    if (!this.userEmail) {
      return '';
    }
    const email_length = this.userEmail.split('@')[0].length;
    const email_slice_index = (email_length * 20) / 100;
    return (
      this.userEmail.slice(0, email_slice_index) +
      '****@' +
      this.userEmail.split('@')[1]
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
