<div class="container-input position-relative">
  <div #resizeContainer="ngResizable"
      class="resizable-widget position-relative"
      rzHandles="se"
      [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm}"
      [ngStyle]="resizableStyle"
      [ngResizable]="canSettingForm"
      [rzAspectRatio]="lockRatio"
      [rzContainment]="containment"
      [style.height]="calculateHeight + 'px'"
      (rzStop)="onResizeStop($event)"
      (rzResizing)="onResizing($event)">
    <input type="text"
        class="textline px-0"
        #inputTextLine
        [style.color]="markerIdentity.color"
        [style.font-family]="fontFamily"
        [style.font-size.px]="fontSize"
        [style.line-height.px]="fontSize * 1.1"
        [style.width.px]="getWidth()"
        [ngModel]="markerIdentity.data"
        [placeholder]="'UPLOAD.Dropdown' | translate"
        (ngModelChange)="onTextChange($event)"
        [disabled]="true">

    <div *ngIf="canSettingForm || canFillForm"
        class="toggle-dropdown"
        (click)="toggleDisplayItems($event)"
        (clickOutside)="closeDropdown()">
      <div class="btn-dropdown pr-2">
        <span class="far fa-chevron-down"></span>
      </div>
    </div>
  </div>

  <div class="dropdown-list"
      *ngIf="displayItems && dropdownOptions.items.length > 0"
      [style.top.px]="canSettingForm ? fontSize + 25 : fontSize + 10">
    <div class="dropdown-item disable-text-selection"
        *ngFor="let item of dropdownOptions.items"
        (click)="onSelectedItem(item);$event.stopPropagation()">
      <span>{{ item }}</span>
    </div>
  </div>
</div>