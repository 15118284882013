<div class="container-input position-relative">
  <div #resizeContainer="ngResizable"
      class="resizable-widget position-relative"
      rzHandles="se"
      [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm}"
      [ngStyle]="resizableStyle"
      [ngResizable]="canSettingForm"
      [rzAspectRatio]="lockRatio"
      [rzContainment]="containment"
      [rzMinHeight]="fontSize * 1.1"
      (rzStop)="onResizeStop($event)"
      [style.height.px]="onCalculateHeight()"
      [style.width.px]="getWidth()"
      (rzResizing)="onResizing($event)"
      (rzStart)="onResizeStart()">
    <form #formInput *ngIf="canSettingForm || canFillForm else previewText"
        method="get"
        action="assets/tmp.txt"
        [style.--fontStyle]="markerIdentity.fontStyle.value"
        [style.--fontSize]="fontSize"
        class="textwidget-form">
      <textarea class="text-widget-textarea bg-none px-0"
          name="text1"
          wrap="hard"
          [id]="markerIdentity.id"
          #inputTextLine
          [style.padding]="'0.1em'"
          [style.color]="getFontColor()"
          [style.font-family]="fontFamily"
          [style.font-size.px]="fontSize"
          [style.z-index]="2"
          [style.line-height.px]="fontSize * 1.1"
          [style.--line]="fontSize+'px'"
          [style.width.px]="getWidth()"
          [style.height.px]="onCalculateHeight() + calculateHeight"
          [ngModel]="markerIdentity.data"
          (input)="onTextChange($event)"
          [disabled]="!canFillForm"
          [ngClass]="{'event-none': !canFillForm}"
          [placeholder]="'UPLOAD.Text' | translate"
          ></textarea>
      <textarea #formInputTextareaHidden
          name="textHidden"
          wrap="hard"
          class="text-widget-textarea bg-none px-0 hidden"
          style="top: 100%; color: red;"
          [style.font-family]="fontFamily"
          [style.font-size.px]="fontSize"
          [style.line-height.px]="fontSize * 1.1"
          [style.width.px]="getWidth()"
          [style.height.px]="onCalculateHeight() + calculateHeight"
          [style.--line]="fontSize+'px'"></textarea>
    </form>
    <ng-template #previewText>
      <!-- ไม่ต้องการให้ textarea มัน auto ขึ้นบรรทัดใหม่ -->
      <div style="white-space: pre-line;text-wrap: nowrap;"
          [style.padding]="'0.1em'"
          [style.color]="getFontColor()"
          [style.font-family]="fontFamily"
          [style.font-size.px]="fontSize"
          [style.z-index]="2"
          [style.line-height.px]="fontSize * 1.1"
          [style.--line]="fontSize+'px'"
          [style.width.px]="getWidth()"
          [style.height.px]="onCalculateHeight() + calculateHeight"
          [ngClass]="{'event-none': !canFillForm}">{{markerIdentity?.data}}</div>
    </ng-template>
  </div>
</div>
