import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  today: Moment;
  dateFormatListEN: Array<{
    value: string;
    label: string;
    format: string;
  }>;
  dateFormatListTH: Array<{
    value: string;
    label: string;
    format: string;
  }>;

  readonly monthTH = [
    { full: 'มกราคม', short: 'ม.ค.' },
    { full: 'กุมภาพันธ์', short: 'ก.พ.' },
    { full: 'มีนาคม', short: 'มี.ค.' },
    { full: 'เมษายน', short: 'เม.ย.' },
    { full: 'พฤษภาคม', short: 'พ.ค.' },
    { full: 'มิถุนายน', short: 'มิ.ย.' },
    { full: 'กรกฎาคม', short: 'ก.ค.' },
    { full: 'สิงหาคม', short: 'ส.ค.' },
    { full: 'กันยายน', short: 'ก.ย.' },
    { full: 'ตุลาคม', short: 'ต.ค.' },
    { full: 'พฤศจิกายน', short: 'พ.ย.' },
    { full: 'ธันวาคม', short: 'ธ.ค.' },
  ];

  constructor() {
    this.today = moment();
    this.constructFormatList();
  }

  constructFormatList(date = null) {
    if (!date) {
      date = this.today;
    }
    this.dateFormatListEN = [
      {
        value: 'EN:%-d/%-m/%Y',
        label: this.getDateLabelEN('D/M/YYYY', date),
        format: 'D/M/YYYY',
      },
      {
        value: 'EN:%Y-%m-%d',
        label: this.getDateLabelEN('YYYY-MM-DD', date),
        format: 'YYYY-MM-DD',
      },
      {
        value: 'EN:%-d-%-m-%Y',
        label: this.getDateLabelEN('D-M-YYYY', date),
        format: 'D-M-YYYY',
      },
      {
        value: 'EN:%-d %b %Y',
        label: this.getDateLabelEN('D MMM YYYY', date),
        format: 'D MMM YYYY',
      },
      {
        value: 'EN:%-d %B %Y',
        label: this.getDateLabelEN('D MMMM YYYY', date),
        format: 'D MMMM YYYY',
      },
      {
        value: 'EN:%b %-d, %Y',
        label: this.getDateLabelEN('ll', date),
        format: 'll',
      },
      {
        value: 'EN:%B %-d, %Y',
        label: this.getDateLabelEN('LL', date),
        format: 'LL',
      },
    ];
    this.dateFormatListTH = [
      {
        value: 'TH:d/m/yyyy',
        label: this.getDateLabelTH('TH:d/m/yyyy', date),
        format: 'TH:d/m/yyyy',
      },
      {
        value: 'TH:d mmm yyyy',
        label: this.getDateLabelTH('TH:d mmm yyyy', date),
        format: 'TH:d mmm yyyy',
      },
      {
        value: 'TH:d mmmm yyyy',
        label: this.getDateLabelTH('TH:d mmmm yyyy', date),
        format: 'TH:d mmmm yyyy',
      },
      {
        value: 'TH:d mmmm พ.ศ.yyyy',
        label: this.getDateLabelTH('TH:d mmmm พ.ศ.yyyy', date),
        format: 'TH:d mmmm พ.ศ.yyyy',
      },
    ];
  }

  get dateFormatDropDownGroups() {
    return [
      {
        label: 'EN',
        items: this.dateFormatListEN,
      },
      {
        label: 'TH',
        items: this.dateFormatListTH,
      },
    ];
  }

  get dateFormatDropDownList() {
    return [...this.dateFormatListEN, ...this.dateFormatListTH];
  }

  get defaultFormat(): string {
    return this.dateFormatListEN[5].value;
  }

  getDateLabel(value: string): string | null {
    const type = value.slice(0, 2);
    if (type === 'EN') {
      const findObj = this.dateFormatListEN.find(
        (obj) => obj.value === value,
      );
      if (!findObj) {
        return null;
      }
      return findObj.label;
    } else if (type === 'TH') {
      const findObj = this.dateFormatListTH.find(
        (obj) => obj.value === value,
      );
      if (!findObj) {
        return null;
      }
      return findObj.label;
    } else {
      return null;
    }
  }

  constructFormatListFromDate(date: NgbDateStruct = null) {
    let d = this.today;
    if (date) {
      d = moment({
        year: date.year,
        month: date.month - 1,
        date: date.day,
      });
    }
    this.constructFormatList(d);
    return this.dateFormatDropDownList;
  }

  getDateFormat(value: string): string | null {
    const type = value.slice(0, 2);
    if (type === 'EN') {
      const findObj = this.dateFormatListEN.find(
        (obj) => obj.value === value,
      );
      if (!findObj) {
        return null;
      }
      return findObj.format;
    } else if (type === 'TH') {
      const findObj = this.dateFormatListTH.find(
        (obj) => obj.value === value,
      );
      if (!findObj) {
        return null;
      }
      return findObj.format;
    } else {
      return null;
    }
  }

  getDateLabelWithDate(
    date: NgbDateStruct,
    value: string,
  ): string | null {
    let d = moment();
    if (date) {
      d = moment({
        year: date.year,
        month: date.month - 1,
        date: date.day,
      });
    }
    const format = this.getDateFormat(value);
    const type = value.slice(0, 2);
    return type === 'EN'
      ? this.getDateLabelEN(format, d)
      : type === 'TH'
      ? this.getDateLabelTH(format, d)
      : null;
  }

  getDateLabelEN(format: string, date = null): string {
    if (!date) {
      date = this.today;
    }
    return date.format(format);
  }

  getDateLabelTH(format: string, date = null): string {
    if (!date) {
      date = this.today;
    }
    const thaiDate = moment(date);
    const year = thaiDate.year() + 543;
    const month = thaiDate.month();
    let thaiDateLabel: string;

    switch (format) {
      case 'TH:d/m/yyyy':
        thaiDateLabel = thaiDate.format(`D/M/${year}`);
        break;

      case 'TH:d mmm yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].short} ${year}`,
        );
        break;

      case 'TH:d mmmm yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].full} ${year}`,
        );
        break;

      case 'TH:d mmmm พ.ศ.yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].full} พ.ศ.${year}`,
        );
        break;

      default:
        thaiDateLabel = thaiDate.format(`D/M/${year}`);
    }

    return thaiDateLabel;
  }

  getDateLabelTHCustom(format: string, date: Moment = null): string {
    if (!date) {
      date = this.today;
    }
    const thaiDate = date;
    const year = thaiDate.year() + 543;
    const month = thaiDate.month();
    let thaiDateLabel: string;

    switch (format) {
      case 'TH:d/m/yyyy':
        thaiDateLabel = thaiDate.format(`D/M/${year}`);
        break;

      case 'TH:d mmm yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].short} ${year}`,
        );
        break;

      case 'TH:d mmm yy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].short} ${year
            .toString()
            .substring(2, 4)}`,
        );
        break;

      case 'TH:d mmmm yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].full} ${year}`,
        );
        break;

      case 'TH:d mmmm พ.ศ.yyyy':
        thaiDateLabel = thaiDate.format(
          `D ${this.monthTH[month].full} พ.ศ.${year}`,
        );
        break;

      default:
        thaiDateLabel = thaiDate.format(`D/M/${year}`);
    }

    return thaiDateLabel;
  }

  clean_time(time) {
    const time_arr = time.split(':');

    if (time_arr.length !== 2) {
    } else {
      const h = parseInt(time_arr[0].trim());
      const m = parseInt(time_arr[1].trim());
      if (isNaN(h) || isNaN(m)) {
      }
      if (h < 24 && m < 60 && h >= 0 && m >= 0) {
        return `${h.toString().padStart(2, '0')}:${m
          .toString()
          .padStart(2, '0')}`;
      } else {
      }
    }

    return null;
  }
}
