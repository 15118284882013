import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Marker } from '../../pdf-signature-customizer.model';

@Component({
  selector: 'app-color-setting-panel-item',
  templateUrl: './color-setting-panel-item.component.html',
  styleUrls: ['./color-setting-panel-item.component.scss'],
})
export class ColorSettingPanelItemComponent {
  @Input() markerIdentity;
  @Input() marker: Marker;

  @Input() markers: Marker[];
  @Output() markersChange = new EventEmitter();

  @Input() dragdropDiv;

  constructor() {}

  openColorSketch() {
    this.markerIdentity.isShowToolsSetting =
      !this.markerIdentity.isShowToolsSetting;
    this.markerIdentity.isShowToolsMenuList = false;
  }

  onChange(color: string): void {
    this.markerIdentity.color = color;
    this.markerIdentity.colorForRender =
      this.convertColorToRenderFormat(color);
  }

  convertColorToRenderFormat(color: string): {
    r: number;
    g: number;
    b: number;
  } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      color,
    );
    return result
      ? {
          r: parseInt(result[1], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          b: parseInt(result[3], 16) / 255,
        }
      : null;
  }
}
