import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PdfCustomizeService } from 'src/app/modules/memos/service/pdf-customize.service';
import { Marker } from '../../pdf-signature-customizer.model';

@Component({
  selector: 'app-setting-panel',
  templateUrl: './setting-panel.component.html',
  styleUrls: ['./setting-panel.component.scss'],
})
export class SettingPanelComponent {
  @Input() markers: Marker[];
  @Output() markersChange = new EventEmitter();

  @Input() marker: Marker;
  @Input() markerIdentity;

  @Input() dragdropDiv;

  @Output() addRadio = new EventEmitter<string>();
  @Output() refreshPdf = new EventEmitter();

  @Output() closeAllPage = new EventEmitter();

  constructor(private pdfCustomizeService: PdfCustomizeService) {}

  onAddRadio(event) {
    this.addRadio.emit(event);
  }

  closeAllSettings(event?): void {
    if (event) {
      event.stopPropagation();
      // because [exclude] is not working properly, we need to add this custom logic:
      const target = event.target as HTMLElement;
      const classList = target.classList;
      if (
        classList.contains('fa-times') ||
        classList.contains('btn-remove-item') ||
        classList.contains('ng-option') ||
        classList.contains('ng-option-label') ||
        classList.contains('ml-2') ||
        classList.contains('p-2') ||
        classList.contains('color-saturation') ||
        target.tagName === 'BODY'
      ) {
        return;
      }
    }
    this.markers.forEach((marker) => {
      marker.zIndex = 0;
      if (marker.positions) {
        marker.positions.forEach((position: any) => {
          position.enableSetting = false;
          position.isShowToolsSetting = false;
          position.isShowToolsMenuList = false;
          position.zIndex = 0;
        });
      }
    });
    this.closeAllPage.emit();
  }

  getOptionPopupWindowPosition(dragdropDiv: HTMLDivElement): {
    top: string;
    left: string;
    bottom: string;
    right: string;
  } {
    const Y_THRESHOLD = this.pdfElementHeight * 0.7;
    const X_THRESHOLD = this.pdfElementWidth * 0.7;
    const X_INIT = this.markerIdentity.screenCoordinate?.x || 0;
    const Y_INIT = this.markerIdentity.screenCoordinate?.y || 0;
    const W_DRAGDROP = dragdropDiv.offsetWidth;
    const H_DRAGDROP = dragdropDiv.offsetHeight;
    const W_POPUP = 40;
    const W_PADDING = 5;
    const position = {
      left: undefined,
      right: undefined,
      bottom: undefined,
      top: undefined,
    };
    position.left = `${W_DRAGDROP + W_PADDING}px`;
    if (Y_INIT + H_DRAGDROP > Y_THRESHOLD) {
      position.bottom = `${H_DRAGDROP + W_PADDING}px`;
      position.left = `${W_DRAGDROP - W_POPUP}px`;
    } else if (
      X_INIT + W_DRAGDROP + W_POPUP + W_PADDING >
      X_THRESHOLD
    ) {
      position.top = `${H_DRAGDROP + W_PADDING}px`;
      position.left = `${W_DRAGDROP - W_POPUP}px`;
    }

    return position;
  }

  sendRefresh() {
    this.refreshPdf.emit();
  }

  get pdfElementHeight(): number {
    return this.pdfCustomizeService.pdfElementHeight;
  }

  get pdfElementWidth(): number {
    return this.pdfCustomizeService.pdfElementWidth;
  }
}
