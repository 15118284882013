import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-approval-progress-bar',
  templateUrl: './approval-progress-bar.component.html',
  styleUrls: ['./approval-progress-bar.component.scss'],
})
export class ApprovalProgressBarComponent implements OnChanges {
  @Input() approvalCount = 0;
  @Input() approvalTotal = 1;
  @Input() progressColor = '#ffffff';
  @Input() backgroundColor = '#f7f7f7';
  @Input() textColor = '#000';

  calProgressStyle = 'calc(100% - 10px)';
  opacity = 0.4;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const countChange = changes.approvalCount;
    const totalChange = changes.approvalTotal;
    if (countChange || totalChange) {
      this.progressCal();
    }
  }

  progressCal(): void {
    const progressPercent = Math.round(
      (this.approvalCount / this.approvalTotal) * 100,
    );
    this.calProgressStyle = `calc(${progressPercent}% - 10px)`;
    if (progressPercent === 100) {
      this.textColor = '#fff';
      this.opacity = 1;
    }
  }
}
