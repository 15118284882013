<ng-select
  class="custom custom-multi"
  bindLabel="label"
  bindValue="value"
  [loading]="peopleLoading"
  [typeahead]="peopleInput$"
  [items]="people$ | async"
  [formControl]="control"
  [multiple]="false"
  [closeOnSelect]="true"
  [notFoundText]="'SELECT.No items found'|translate"
  [minTermLength]="2"
  typeToSearchText="{{ 'user.type-search' | translate }}"
>
</ng-select>
