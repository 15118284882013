import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PdfCustomizeService } from 'src/app/modules/memos/service/pdf-customize.service';
import { FONT_LIST } from 'src/app/modules/memos/service/upload-memo.constants';
import { Marker } from '../../pdf-signature-customizer.model';
import { DateFormatService } from 'src/app/shared/service/date-format.service';
import { MemoService } from 'src/app/modules/memos/service/memo.service';

@Component({
  selector: 'app-config-setting-panel-item',
  templateUrl: './config-setting-panel-item.component.html',
  styleUrls: ['./config-setting-panel-item.component.scss'],
})
export class ConfigSettingPanelItemComponent {
  @Input() markerIdentity;
  @Input() marker: Marker;

  @Input() markers: Marker[];
  @Output() markersChange = new EventEmitter();

  @Input() dragdropDiv;
  fontList = FONT_LIST;
  formatItems;
  @ViewChild('fontSizeInput') fontSizeInput: ElementRef;
  @Output() refreshPdf = new EventEmitter();

  constructor(
    private pdfCustomizeService: PdfCustomizeService,
    private dateFormatService: DateFormatService,
    private memoService: MemoService,
  ) {
    this.formatItems =
      this.dateFormatService.constructFormatListFromDate();
  }

  openConfigList() {
    this.markerIdentity.isShowToolsMenuList =
      !this.markerIdentity.isShowToolsMenuList;
    this.markerIdentity.isShowToolsSetting = false;
  }

  calcFontSizePx(size: number): number {
    return Math.round(
      (size / 100) * this.pdfCustomizeService.pdfElementHeight,
    );
  }

  onChange(sizePx: number) {
    this.markerIdentity.fontSize =
      (sizePx / this.pdfCustomizeService.pdfElementHeight) * 100;
    this.memoService.setWidthDate('font');
    this.refreshPdf.emit();
  }

  onInputChange(value, field: string) {
    if (field === 'require') {
      this.markerIdentity.require = value;
      return;
    }
    this.markerIdentity.options[field] = value;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onChangeValue(value, field: string) {
    if (field === 'require') {
      this.markerIdentity.require = value;
      return;
    }
    this.markerIdentity.options[field] = value;
    this.refreshPdf.emit();
    this.memoService.setWidthDate('format');
  }

  disabledOnWheel(event: WheelEvent) {
    event.preventDefault();
    this.fontSizeInput?.nativeElement.blur();
  }
}
