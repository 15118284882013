import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { MemoService } from '../../../memos/service/memo.service';
import { Store } from '@ngxs/store';
import { AlertService } from '../../../../core/services/alert.service';
import {
  ApprovalConfirmModalComponent,
  ConfirmModalOptions,
} from '../../../../shared/components/approval-confirm-modal/approval-confirm-modal.component';
import { SelectSignMethodModalComponent } from '../../../../shared/components/select-sign-method-modal/select-sign-method-modal.component';
import {
  ThemeList,
  ThemeService,
} from '../../../../shared/service/theme.service';
import { SelectionTabItem } from '../../../memos/components/preview-memos/selection-tab/selection-tab.component';
import {
  ColorSet,
  MemoStatusColorService,
} from '../../../../shared/service/memo-status-color.service';
import { LoaTableDataRow } from '../../../memos/components/preview-memos/loa-table/loa-table.component';
import { OtpModalComponent } from '../../../../shared/components/otp-modal/otp-modal.component';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';
import { Memo } from '../../../../store/memo/memo.model';
import { Level, Member } from '../../../loa/shared/loa.model';
import { NgbThaiDateParserFormatter } from 'src/app/modules/memos/service/NgDateParser';
import { CALENDAR_LOCALE } from 'src/app/shared/utils/constants';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ReviewerModalComponent } from '../../../../shared/components/reviewer-modal/reviewer-modal.component';
import {
  ErrorNotification,
  SaveMemo,
} from 'src/app/store/memo/memo.actions';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DetailDepartment } from 'src/app/modules/memos/components/preview-memos/preview-memo-detail/preview-memo-detail.component';
import { Subscription } from 'rxjs';
import { featureFlag } from '../../../../../environments/environment';

export type ActionType =
  | 'approve'
  | 'reject'
  | 'terminate'
  | 'ack-review'
  | 'review';

@Component({
  selector: 'app-executive-detail',
  templateUrl: './executive-detail.component.html',
  styleUrls: ['./executive-detail.component.scss'],
})
export class ExecutiveDetailComponent implements OnInit, OnDestroy {
  _featureFlag = featureFlag;
  memo: Memo;
  previewModal = false;
  reason: string;
  otp: string;

  signature: any;
  hasStoredSignature: boolean;
  useStoredSignature: boolean;

  modal: NgbModalRef;
  actionType: ActionType;

  isLoading = false;
  toggleSideMenu = true;
  themeList: ThemeList;
  activeSelectionTabItem: SelectionTabItem;
  selectionTabItems: SelectionTabItem[];
  memoStatusColorSet: ColorSet;
  tableInputData: LoaTableDataRow[];
  date;

  // PDF Viewer
  pdfSrc;
  pdfSrcName: string;
  currentDocumentPage: number;
  totalDocumentPage: number;
  isDocumentLoadingPage: boolean;

  previewSignature = null;
  httpHeaders;
  documentName: string;
  showPdfFile: any;
  isPdfLoading = true;
  nameCheck: string;
  @Input() viewType = 0;
  @Input() memoId: any;
  @Input() isDesktop: boolean;

  @Output() actionChanged = new EventEmitter();

  @ViewChild('approveConfirmModal', { static: false })
  approveConfirmModal: ApprovalConfirmModalComponent;
  @ViewChild('confirmModalComponent')
  confirmModalComponent: ApprovalConfirmModalComponent;
  @ViewChild('selectSignMethodModalComponent')
  selectSignMethodModalComponent: SelectSignMethodModalComponent;
  @ViewChild(OtpModalComponent)
  otpModalComponent: OtpModalComponent;
  @ViewChild('confirmModal')
  confirmModal: ApprovalConfirmModalComponent;
  @ViewChild('confirmSuccess')
  confirmSuccess: ConfirmModalComponent;
  @ViewChild('confirmAlertMessage')
  confirmAlertMessage: ConfirmModalComponent;
  private isRequiredComment = false;
  @ViewChild('reviewer')
  createReviewer: ReviewerModalComponent;

  cert_type: string;

  count: number;
  currentPage = 1;
  itemsPerPage = 10;
  startItem: number;
  lastItem: number;
  deptList: DetailDepartment[] = [];
  editDept: boolean;
  createUser: number;
  dptAnnouncement: string[];
  isSaveAnnounce: boolean;
  subscription: Subscription[] = [];
  isContract: boolean;
  userTypeOfOtp;
  constructor(
    private notification: NotificationService,
    public modalService: NgbModal,
    public themeService: ThemeService,
    private memoService: MemoService,
    private translate: TranslateService,
    private profileService: ProfileService,
    private store: Store,
    private alert: AlertService,
    private memoStatusColorService: MemoStatusColorService,
    private dateFormat: NgbThaiDateParserFormatter,
    private authenticationService: AuthenticationService,
    private route: Router,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
    this.httpHeaders = this.authenticationService.httpHeader;
  }

  ngOnInit(): void {
    this.store.selectSnapshot((store) => {
      this.hasStoredSignature = !!store.auth.signature;
      this.createUser = store.auth.id;
      this.userTypeOfOtp = store?.auth?.otp_type;
    });

    this.viewType = 0;
    this.loadMemoData();

    this.subscription.push(
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.dateFormatter();
        },
      ),
    );
  }
  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }
  dateFormatter(): void {
    const start = this.memo?.expiry_date;
    const dataStart = this.dateFormat.parse(start);
    const monthStart = this.convertDateFullDate(dataStart?.month);
    this.date = `${dataStart.day} ${monthStart} ${
      dataStart.year + (this.translate.currentLang === 'en' ? 0 : 543)
    }`;
  }

  convertDateFullDate(month): string {
    const en = CALENDAR_LOCALE.EN;
    const th = CALENDAR_LOCALE.TH;
    const dataLanguage =
      this.translate.currentLang === 'en' ? en : th;
    return dataLanguage.monthNames[month - 1];
  }

  initialSelectionTabData(): void {
    this.selectionTabItems = [
      {
        name: 'Approval Memo',
        hasNotification: false,
        numberNotification: null,
        translateLabel: 'SELECT-TAB.APPROVAL-MEMO',
        index: 0,
      },
      {
        name: 'Detail',
        hasNotification: false,
        numberNotification: null,
        translateLabel: 'SELECT-TAB.DETAIL',
        index: 1,
      },
      {
        name: 'List of Approval',
        hasNotification: true,
        numberNotification: this.memo.max_level,
        translateLabel: 'SELECT-TAB.LIST-APPROVAL',
        index: 2,
      },
      {
        name: 'Comments',
        hasNotification: this.memo.comment_count > 0,
        numberNotification:
          this.memo.comment_count > 0
            ? this.memo.comment_count
            : null,
        translateLabel: 'SELECT-TAB.COMMENTS',
        index: 3,
      },
      {
        name: 'Attachment',
        hasNotification: this.memo.attachment_count > 0,
        numberNotification:
          this.memo.attachment_count > 0
            ? this.memo.attachment_count
            : null,
        translateLabel: 'SELECT-TAB.ATTACHMENT',
        index: 4,
      },
      {
        name: 'Reference',
        hasNotification: true,
        numberNotification:
          (this.memo.reference_memo_numbers || []).length > 0
            ? this.memo.reference_memo_numbers.length
            : null,
        translateLabel: 'SELECT-TAB.REFERENCE',
        index: 5,
      },
      {
        name: 'History',
        hasNotification: false,
        numberNotification: null,
        translateLabel: 'SELECT-TAB.HISTORY',
        index: 6,
      },
    ];
    this.activeSelectionTabItem = this.selectionTabItems[0];
    this.validateSignatureExists();
  }

  getCommentRequired(): boolean {
    let currentLevel: number;
    try {
      currentLevel = this.memo.current_level;
    } catch (error) {
      console.error(
        new Error(
          'The data is missing current_level. This function cannot check comment need',
        ),
      );
      return false;
    }
    const typeMemo =
      this.memo.memo_type === 'contract'
        ? this.memo.contract?.signature_position
        : this.memo.upload?.signature_position;
    let currentMarker = null;
    if (this.getAllMemberRequired()) {
      currentMarker = typeMemo?.find(
        (marker) =>
          marker.level === currentLevel &&
          marker.member_seq === this.memo.current_member_seq,
      );
    } else {
      // marker.name.split(' ')[3] is member_seq when not all require.
      currentMarker = typeMemo?.find(
        (marker) =>
          marker.level === currentLevel &&
          parseInt(marker.name.split(' ')[3], 10) ===
            this.memo.current_member_seq,
      );
    }
    return currentMarker?.comment_positions?.length > 0;
  }

  getAllMemberRequired() {
    const current_level = this.memo.loa_group.levels.find(
      (level) => level.level == this.memo.current_level,
    );

    return current_level.count == current_level.min_approve_count;
  }

  getLoaProgressTableData(): void {
    this.tableInputData = this.memo.table_data.levels.map((item) => {
      if (item.level !== this.memo.current_level) {
        return item;
      }
      return {
        ...item,
        statusColor: this.memoStatusColorSet.progressBackground,
      };
    });
  }

  loadMemoData(): void {
    this.reason = null;
    const subscription = this.memoService
      .getMemoDetail(this.memoId)
      .subscribe(
        (memo) => {
          this.memo = memo;
          this.httpHeaders = this.authenticationService.httpHeader;
          this.count = this.memo.department_of_announcement.length;
          this.dptAnnouncement = [...this.memo.announcements];
          this.deptList = this.memo.department_of_announcement;
          this.documentName = this.memo.memo_number + '.pdf';
          this.pdfSrc = this.memoService.getPdfUrl(memo);
          this.isRequiredComment = this.getCommentRequired();
          this.initialSelectionTabData();
          this.convertPositionSignature(memo);
          const status =
            memo.status === 'pending' && memo.is_review
              ? 'review'
              : memo.status;
          this.memoStatusColorSet =
            this.memoStatusColorService.getStatusColorSet(status);
          if (this.memo.loa_group) {
            this.getLoaProgressTableData();
          }
          if (this.memo.expiry_date) {
            this.dateFormatter();
          }
          this.isContract = this.memo.memo_type === 'contract';
        },
        () => this.alert.error('Unable to load memo'),
      );
    this.subscription.push(subscription);
  }

  convertPositionSignature(memo): void {
    this.previewSignature = [];
    if (!memo.upload && !memo.contract) {
      this.previewSignature = this.memo.signed_document;
      return;
    }
    const name = this.store.selectSnapshot<string>(
      (s) => s.auth.first_name,
    );
    const lastname = this.store.selectSnapshot<string>(
      (s) => s.auth.last_name,
    );
    this.nameCheck = name + ' ' + lastname;
    const memoType =
      memo.memo_type === 'contract' ? memo.contract : memo.upload;
    memoType.signature_position.forEach((value) => {
      const splitName = value.name.split(': ');
      //check level split level form pattern -Level: {Name}-  then check name in store

      const splitLevel = value.name.split(' ');
      //check member split level form pattern -Level- then check current_member_seq
      if (memo.current_level === value.level && memo.can_approve) {
        if (
          splitName[1] === this.nameCheck ||
          splitLevel[3] === memo.current_member_seq.toString()
        ) {
          this.previewSignature.push(value);
        }
      }
    });
  }

  validateSignatureExists(): void {
    // replace signature from upload template if null.
    if (
      this.memo.memo_type === 'upload' ||
      this.memo.memo_type === 'contract'
    ) {
      let indexCounter = 3;
      const signaturePositions =
        this.memo.memo_type === 'upload'
          ? this.memo.upload.signature_position
          : this.memo.contract.signature_position;
      this.memo.loa_group.levels.forEach((level) => {
        if (
          level.signature_required &&
          level.members.length === level.min_approve_count
        ) {
          level.members.forEach((member) => {
            signaturePositions[indexCounter].signature =
              member.person_signature;
            if (
              !signaturePositions[indexCounter].name.includes(
                member.person_name,
              )
            ) {
              signaturePositions[indexCounter].positions.forEach(
                (position) => {
                  delete position.H;
                },
              );
              signaturePositions[
                indexCounter
              ].name = `Level ${signaturePositions[indexCounter].level}: ${member.person_name}`;
            }
            indexCounter += 1;
          });
        } else {
          indexCounter += level.min_approve_count;
        }
      });
    }
  }

  onApproveButtonClick(): void {
    this.store.dispatch(new SaveMemo(this.memo));
    this.actionType = 'approve';
    this.checkCredit(this.actionType).then(() => {
      const openSelectSignMethodModalFunction = () => {
        if (this.memo.current_level_signature_required) {
          this.openSelectSignMethodModal();
          return;
        }
        this.approveConfirmModal.open('approve');
      };
      let currentLevelInfo: Level<Member> = null;
      if (this.memo.loa_group?.levels) {
        currentLevelInfo = this.memo.loa_group.levels.find(
          (level) => {
            return level.level === this.memo.current_level;
          },
        );
      }
      if (currentLevelInfo) {
        /** Check existing comment */
        if (this.isRequiredComment) {
          this.openConfirmModal('approve', {
            reasonLabel: 'APPROVAL.PLEASE-COMMANTATE',
            confirmEmitValue: 'comment',
          });
          return;
        }
        if (currentLevelInfo.ddoc_enable) {
          this.onApproveButtonClickDDoc();
          return;
        }
      }
      openSelectSignMethodModalFunction();
    });
  }

  onApproveButtonClickDDoc() {
    const personId = this.store.selectSnapshot(
      (state) => state.auth.person_id,
    );
    const currentLevelInfo = this.memo.loa_group?.levels?.find(
      (level) => level.level === this.memo.current_level,
    );
    if (!currentLevelInfo) {
      return;
    }
    const currentMemberInfo = currentLevelInfo.members?.find(
      (member) => member.person === personId,
    );
    const openSelectSignMethodModalFunction = () => {
      if (
        this.memo.current_level_signature_required &&
        this.cert_type !== 'org_imported'
      ) {
        this.openSelectSignMethodModal();
      } else {
        this.approveConfirmModal.open('approve');
      }
    };

    if (currentMemberInfo?.ddoc_use_28) {
      const subscription = this.profileService
        .loadCertificateStatus(null, false, { type: 'm28' })
        .subscribe((data) => {
          this.cert_type = data.certificate_type;
          openSelectSignMethodModalFunction();
        });
      this.subscription.push(subscription);
    } else {
      openSelectSignMethodModalFunction();
    }
  }

  onConfirmModalSubmit(confirmEvent: any): void {
    if (
      confirmEvent === 'comment' &&
      this.memo.current_level_signature_required
    ) {
      this.openSelectSignMethodModal();
      return;
    }
    // LSP didn't want OTP
    // this.checkOtpModal();
    this.submit('');
  }

  checkOtpModal(): void {
    // if (this.memo.otp_enable) {
    //   this.modalService.dismissAll();
    //   let otpType = this.userTypeOfOtp ? this.userTypeOfOtp : null;
    //   if (this.memo.memo_type === 'contract') {
    //     otpType = 'sms';
    //   }
    //   this.otpModalComponent.openModal(
    //     otpType,
    //     this.memo.id,
    //     this.memo.memo_type,
    //   );
    // } else {
    //   this.submit('');
    // }
    this.submit('');
  }

  confirmOtpSubmit(oneTimeSigUploadOriginalFileName): void {
    this.modalService.dismissAll();
    this.submit(oneTimeSigUploadOriginalFileName);
  }

  confirmModalMessage() {
    this.modalService.dismissAll();
    this.submit('');
  }

  confirmSubmit(): void {
    this.confirmSuccess.open(this.actionType);
  }

  onIsUseStored(useStored: boolean) {
    this.useStoredSignature = useStored;
  }

  submit(oneTimeSigUploadOriginalFileName): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    switch (this.actionType) {
      case 'approve':
        this.approve(oneTimeSigUploadOriginalFileName);
        break;
      case 'reject':
        this.reject();
        break;
      case 'terminate':
        this.terminate();
        break;
      default:
        break;
    }
  }

  approve(oneTimeSigUploadOriginalFileName): void {
    const fd = new FormData();
    if (this.isRequiredComment) {
      fd.set('comment', this.reason || '');
    }
    fd.set('otp', this.otp || '');
    if (this.signature && !this.useStoredSignature) {
      fd.append('one_time_signature', this.signature);
      fd.append(
        'one_time_sig_upload_org_file_name',
        oneTimeSigUploadOriginalFileName,
      );
    }
    const ref = this.store.selectSnapshot<string>((s) => s.otp?.ref);
    if (ref) {
      fd.append('ref', ref);
    }
    const subscription = this.memoService
      .approveMemo(this.memo.id, fd)
      .subscribe(
        () => this.handleSuccess(),
        (error) =>
          this.handleError(
            error?.error?.message ||
              error?.error?.detail ||
              'Unable to Approve Memo',
          ),
      );
    this.subscription.push(subscription);
  }

  reject(): void {
    const ref = this.store.selectSnapshot<string>((s) => s.otp?.ref);
    const data = {
      reason: this.reason,
      otp: this.otp || '',
      ref: ref || '',
    };
    const subscription = this.memoService
      .rejectMemo(this.memo.id, data)
      .subscribe(
        () => this.handleSuccess(),
        (err) => {
          this.handleError(
            err?.error?.message || 'Unable to Reject Memo',
          );
        },
      );
    this.subscription.push(subscription);
  }

  terminate(): void {
    const ref = this.store.selectSnapshot<string>((s) => s.otp?.ref);
    const data = {
      reason: this.reason,
      otp: this.otp || '',
      ref: ref || '',
    };
    const subscription = this.memoService
      .terminateMemo(this.memo.id, data)
      .subscribe(
        () => this.handleSuccess(),
        (err) => {
          this.handleError(
            err?.error?.message || 'Unable to Terminate Memo',
          );
        },
      );
    this.subscription.push(subscription);
  }

  review(actor): void {
    this.actionType = 'ack-review';
    const data = {
      review_person: actor,
    };
    this.memoService.reviewMemo(this.memo.id, data).subscribe(
      () => this.handleSuccess(),
      (err) => {
        this.handleError(
          err?.error?.message || 'Unable to Reject Memo',
        );
      },
    );
  }

  handleSuccess(): void {
    this.loadMemoData();
    this.modalService.dismissAll();
    this.alert.success(this.translate.instant('MEMOS.SAVE-CHANGES'));
    this.confirmSubmit();
    this.isLoading = false;
    this.notification.updateNotificationCount();
    this.signature = null;
  }

  handleError(errorMsg: string): void {
    this.alert.error(errorMsg);
    if (this.modal) {
      this.modal.close();
    }
    this.isLoading = false;
  }

  async checkCredit(actionType: ActionType): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        !this.isContract ||
        !['approve', 'terminate'].includes(actionType)
      ) {
        resolve();
        return;
      }
      this.memoService
        .checkCredit(this.memo.id, {
          action_type: actionType,
        })
        .subscribe(
          () => resolve(),
          (error) => {
            if (typeof error.error === 'object') {
              this.alert.error(error.error.detail);
            } else {
              this.alert.error(error.error[0]);
            }
            reject();
          },
        );
    });
  }

  /**
   * @param options Use to customize the confirm modal.
   */
  openConfirmModal(
    type: ActionType,
    options?: ConfirmModalOptions,
  ): void {
    this.store.dispatch(new SaveMemo(this.memo));
    this.actionType = type;
    this.checkCredit(this.actionType).then(() => {
      this.reason = null;
      if (options) {
        Object.keys(options).forEach((optionKey) => {
          this.confirmModalComponent[optionKey] = options[optionKey];
        });
      } else {
        this.confirmModalComponent.reasonLabel = 'APPROVAL.REASON';
        this.confirmModalComponent.confirmEmitValue = undefined;
      }
      this.confirmModalComponent.open(this.actionType);
    });
  }

  openSelectSignMethodModal(): void {
    this.actionType = 'approve';
    this.otp = null;
    this.selectSignMethodModalComponent.openSelectSignMethodModal(
      true,
    );
  }

  close(): void {
    this.reason = null;
    this.otp = null;
    if (this.modal) {
      this.modal.close();
    }
  }

  selectView(view): void {
    this.viewType = view.index;
    this.updateMemoIsRead(view);
  }

  updateMemoIsRead(view): void {}

  windowDownload(): void {
    const subscription = this.memoService
      .printFile(this.pdfSrc)
      .subscribe(
        (pdfFile: any) => {
          const link: any = document.createElement('a');
          link.href = window.URL.createObjectURL(pdfFile);
          const re = /\./gi;
          link.download = this.memo.memo_number.replace(re, '-');
          link.click();
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
    this.subscription.push(subscription);
  }

  sendLogDownload(id: number): void {
    this.subscription.push(
      this.memoService.downloadMemo(id).subscribe(),
    );
  }

  closeToggle(event): void {
    const toggleSideMenu = event;
    this.actionChanged.emit(toggleSideMenu);
  }

  get documentPages(): number[] {
    return Array(this.totalDocumentPage)
      .fill(1)
      .map((value, index) => index + 1);
  }

  changeDocumentPage(page: number): void {
    this.currentDocumentPage = +page;
  }

  changeDocumentPageBack(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage - 1 >= 1
    ) {
      this.currentDocumentPage--;
    }
  }

  changeDocumentPageNext(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage + 1 <= this.totalDocumentPage
    ) {
      this.currentDocumentPage++;
    }
  }

  openAskReviewerModal() {
    this.createReviewer.open();
  }

  openAnnouncement(content: TemplateRef<NgbModalRef>, edit): void {
    this.editDept = false;
    if (edit) {
      this.editDept = true;
    }
    this.startItem = 1;
    this.listCount(this.startItem);
    this.modal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
    });
  }

  listCount(count) {
    this.currentPage = count;
    this.startItem = count;
    this.startItem = this.startItem * 10 - 9;
    this.lastItem = count * 10;
    if (this.lastItem > this.deptList.length) {
      this.lastItem = this.deptList.length;
    }
  }

  saveAnnouncement(): void {
    this.memo.announcements = [...this.dptAnnouncement];
    const data = {
      announcements: this.memo.announcements || [],
      is_require_acknowledge: this.memo.is_require_acknowledge,
    };
    this.isSaveAnnounce = true;
    const subscription = this.memoService
      .updateAnnouncement(this.memo.id, data)
      .subscribe(
        () => {
          this.isSaveAnnounce = false;
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.modal.close();
          this.loadMemoData();
        },
        (error) => {
          this.isSaveAnnounce = false;
          this.store.dispatch(new ErrorNotification(error));
          this.modal.close();
        },
      );
    this.subscription.push(subscription);
  }
  editDepartmentTextHeader() {
    if (!this.editDept) {
      return this.translate.instant('FEED.ANNOUNCEMENT');
    } else {
      return this.translate.instant('CREATE-USER.Edit-Department');
    }
  }

  get isIncoming() {
    return this.memo.memo_type === 'incoming';
  }

  get isOutgoing() {
    return this.memo.memo_type === 'outgoing';
  }

  get isInOut() {
    return this.isIncoming || this.isOutgoing;
  }

  countNotification(event, data): void {
    data.numberNotification = event;
  }
}
