import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { forEach, get, includes, remove } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MemoService } from '../../../service/memo.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ApiService } from 'src/app/core/http/api.service';
import { AlertService } from '../../../../../core/services/alert.service';
import {
  AttachmentDetail,
  AttachmentGroupByPerson,
} from '../../../model/memo.model';
import { ApproveService } from 'src/app/modules/approval/approve.service';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-memo-real-attachment',
  templateUrl: './memo-real-attachment.component.html',
  styleUrls: ['./memo-real-attachment.component.scss'],
})
export class MemoRealAttachmentComponent
  implements OnInit, OnDestroy
{
  attachmentList: AttachmentDetail[];
  attachmentGroupByPerson: AttachmentGroupByPerson[];
  showPreview = false;
  userList = [];
  documentData: any;
  showResults = false;
  isLoading = false;
  count = 0;
  documentName: string;
  currentPage = 1;
  userListCurrentPage = 1;
  attachInformation;
  searchUser = '';
  themeList: ThemeList;

  isMobile: boolean;
  canUploadUnlimited = false;
  debouncer = new Subject<string>();
  @Input() memo: any;
  @Input() memoID: any;
  @Input() isInOut = false;
  @ViewChild('attachModal', { static: true }) attachModal: any;
  @Output() countAttachment = new EventEmitter();
  @Output() previewAttachment = new EventEmitter();
  subscription: Subscription[] = [];
  constructor(
    private alert: AlertService,
    private memoService: MemoService,
    private httpClientService: ApiService,
    private modalService: NgbModal,
    private themeService: ThemeService,
    private store: Store,
    private approveService: ApproveService,
    private spinner: SpinnerService,
  ) {
    this.subscription.push(
      this.debouncer
        .pipe(debounceTime(600), distinctUntilChanged())
        .subscribe((event) => {
          this.userListCurrentPage = 1;
          this.getUserList(event);
        }),
    );
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnInit(): void {
    this.loadAttachmentList();
    this.isMobile = this.themeService.isMobile();
    this.canUploadUnlimited = this.store.selectSnapshot(
      (state) => state.auth.is_unlimited_upload,
    );
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  loadAttachmentList(): void {
    this.showResults = false;
    if (this.memoID) {
      const header = new HttpHeaders().set(
        'Authorization',
        this.memoID,
      );
      const nonUserParams = new HttpParams()
        .set('page', this.currentPage.toString())
        .set('memo', this.memo.id);
      const subscription = this.approveService
        .getAttachmentList(header, nonUserParams, this.isInOut)
        .subscribe(
          (attachments: any) => {
            this.showResults = true;
            this.count = attachments.count;
            this.countAttachment.emit(attachments.count);
            this.attachmentList = attachments.map((attachment) => {
              if (attachment.file_size >= 1048567) {
                attachment.file_size =
                  (attachment.file_size / (1000 * 1000)).toFixed(1) +
                  'MB';
                return attachment;
              } else {
                attachment.file_size =
                  (attachment.file_size / 1024).toFixed(1) + 'KB';
                return attachment;
              }
            });
            this.attachmentGroupByPerson =
              this.groupAttachmentByPerson(this.attachmentList);
          },
          (error) => {
            this.store.dispatch(new ErrorNotification(error));
            this.showResults = true;
          },
        );
      this.subscription.push(subscription);
    } else {
      const params = { memo: this.memo.id };
      const subscription = this.memoService
        .getMemoAttachment(params, this.isInOut)
        .subscribe(
          (attachments: AttachmentDetail[]) => {
            this.attachmentList = attachments;
            this.attachmentGroupByPerson =
              this.groupAttachmentByPerson(this.attachmentList);
            this.showResults = true;
            this.count = this.attachmentList.length;
            this.countAttachment.emit(this.count);
          },
          (error) => {
            this.store.dispatch(new ErrorNotification(error));
            this.showResults = true;
          },
        );
      this.subscription.push(subscription);
    }
  }

  getUserList(filterUsername?): void {
    let params = new HttpParams().set(
      'page',
      this.userListCurrentPage.toString(),
    );
    if (filterUsername) {
      params = params.set('full_name', filterUsername);
    }
  }

  changePage(): void {
    this.loadAttachmentList();
  }

  getExtention(attachment): void {
    if (attachment.file) {
      return attachment.file.substr(
        attachment.file.lastIndexOf('.') + 1,
      );
    }
    return attachment.file_extension;
  }

  closePreview(): void {
    this.showPreview = false;
  }

  preview(file): void {
    this.documentData = file.file;
    this.documentName = file.file_name;
    this.showPreview = true;
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  uploadFile(files): void {
    if (files == null || files.length === 0) {
      return;
    }

    this.isLoading = true;
    this.spinner.show();
    const fd = new FormData();
    fd.append('memo', this.memo.id);
    forEach(files, (file) => {
      const name = get(file, 'name', 'relativePath');
      file.fileEntry
        ? fd.append(name, file.fileEntry)
        : fd.append(name, file);
    });
    const subscription = this.memoService
      .uploadMemoAttachment(fd, this.isInOut)
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.spinner.hide();
          this.loadAttachmentList();
        },
        (err) => {
          if (err?.error?.details) {
            this.alert.error(err?.error?.details);
          } else {
            this.alert.error(
              'System error, Please contact technical support.',
            );
          }
          this.isLoading = false;
          this.spinner.hide();
        },
      );
    this.subscription.push(subscription);
  }

  openAttachmentModal(): void {
    this.attachModal.openFromOutside();
  }

  checkIfRemovable(): boolean {
    return (
      Number(localStorage.getItem('user_id')) ===
      get(this.memo, 'created_user', '')
    );
  }

  deleteAttachmentFile(data: { data: AttachmentDetail }): void {
    const subscription = this.memoService
      .removeMemoAttachment(data.data.id, this.isInOut)
      .subscribe(
        () => this.loadAttachmentList(),
        (error) => this.alert.error(error.status),
      );
    this.subscription.push(subscription);
  }

  downloadDocument(attachment: AttachmentDetail): void {
    this.documentName = attachment.file_name;
    const subscription = this.httpClientService
      .download(attachment.file)
      .subscribe(
        (res: any) => {
          const blob: Blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.file_name;
          link.click();
          this.sendLogDownload(attachment.id);
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
    this.subscription.push(subscription);
  }

  sendLogDownload(id: number): void {
    this.subscription.push(
      this.memoService
        .downloadMemoAttachment(id, null, this.isInOut)
        .subscribe(),
    );
  }

  callAttachmentModal(attachment, attachmentModal): void {
    if (!attachment.accessible) {
      this.alert.error('ไม่ได้รับสิทธิ์ในการเข้าถึงเอกสารฉบับนี้');
      return;
    }
    this.attachInformation = attachment;
    this.attachModal = this.modalService.open(attachmentModal);
    this.attachModal.result.then(
      () => {},
      () => {
        this.alert.success();
      },
    );
  }

  selectUserForVisibility(userID): void {
    if (includes(this.attachInformation.profiles, userID)) {
      remove(this.attachInformation.profiles, (id) => {
        return id === userID;
      });
    } else {
      this.attachInformation.profiles.push(userID);
    }
  }

  isIncludes(collection: Array<any>, value): void {
    return includes(collection, value);
  }

  submitAttachment(): void {
    this.isLoading = true;
    const currentUser = parseInt(
      localStorage.getItem('profile_id'),
      10,
    );
    const data = {
      profiles: this.attachInformation.profiles.length
        ? [...this.attachInformation.profiles, currentUser]
        : this.attachInformation.profiles,
    };
    const subscription = this.memoService
      .updateMemoAttchment(
        this.attachInformation.id,
        data,
        this.isInOut,
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.alert.success();
          this.attachModal.close();
        },
        (error) => {
          this.isLoading = false;
          this.store.dispatch(new ErrorNotification(error));
        },
      );
    this.subscription.push(subscription);
  }

  triggerDebouncer(event): void {
    this.debouncer.next(event);
  }

  groupAttachmentByPerson(
    attachments: AttachmentDetail[],
  ): AttachmentGroupByPerson[] {
    const groups: { [k: number]: AttachmentGroupByPerson } = {};
    attachments.forEach((attach) => {
      if (!attach?.created_person) {
        return;
      }
      const createdPerson = attach.created_person;
      if (createdPerson.id in groups) {
        groups[createdPerson.id].attachments.push(attach);
      } else {
        groups[createdPerson.id] = {
          full_name: createdPerson.full_name,
          attachments: [attach],
        };
      }
    });
    return Object.values(groups);
  }
}
