<div class="container-input">
  <div #resizeContainer="ngResizable"
    class="resizable-widget position-relative"
    rzHandles="se"
    [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm}"
    [ngStyle]="resizableStyle"
    [ngResizable]="canSettingForm"
    [rzAspectRatio]="true"
    [rzContainment]="containment"
    (rzStop)="onResizeStop($event)"
    (rzResizing)="onResizing($event)">

    <input type="text"
      class="textline"
      #inputTextLine
      [style.color]="markerIdentity.color"
      [style.font-family]="fontFamily"
      [style.font-size.px]="fontSize"
      [style.line-height.px]="fontSize"
      [style.width]="_width !== null ? _width + 'px' : 'auto'"
      [(ngModel)]="markerIdentity.data"
      [placeholder]="canSettingForm ? CHECK_TEXT : ''"
      [disabled]="true">

    <div class="toggle-checkbox"
      [style.z-index]="canFillForm ? '10' : '-1'"
      (click)="toggleCheck()"></div>

  </div>
</div>
