import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InputTextlineComponent } from '../input-textline/input-textline.component';
import { TranslateService } from '@ngx-translate/core';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: [
    './input-number.component.scss',
    '../input-textline/input-textline.component.scss',
    '../base-input/base-input.component.scss',
  ],
})
export class InputNumberComponent
  extends InputTextlineComponent
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Input() markerIdentity: FormInputMarkerIdentity<'number'>;
  @Output() textChange = new EventEmitter();

  @Input() allowEdit = false;

  error: string;
  errorValue;

  constructor(public translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (this.allowEdit) {
      this.validateMinMax(this.markerIdentity.data);
    }
  }

  onTextChange(event: string) {
    const inputBox = document.getElementById(this.markerIdentity.id);

    const invalidChars = ['-', '+', 'e'];

    inputBox.addEventListener('keydown', function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    this.validateMinMax(event);
    if (this.autoDecreaseFontSize) {
      const isAddText =
        event.length > (this.markerIdentity.data || '').length;
      this.markerIdentity.data = event;
      // if (isAddText) {
      //   this.decreaseFontSizeIfExceedWidth();
      // } else {
      //   this.increaseFontSizeBackToFixedSize();
      // }
    } else {
      this.markerIdentity.data = event;
    }

    this.textChange.emit(this.markerIdentity);
  }

  validateMinMax(event: string) {
    if (!event) {
      this.error = null;
      this.errorValue = null;
      return;
    }

    const num = Number(event.replace(/,/g, ''));

    const minValue = this.markerIdentity.options?.minValue;
    if ((minValue || minValue === 0) && num < minValue) {
      this.error = 'UPLOAD.Not less than';
      this.errorValue = minValue;
      return;
    }

    const maxValue = this.markerIdentity.options?.maxValue;
    if ((maxValue || minValue === 0) && num > maxValue) {
      this.error = 'UPLOAD.Not more than';
      this.errorValue = maxValue;
      return;
    }

    this.error = null;
    this.errorValue = null;
  }

  onResizeStop(event: IResizeEvent, markerIdentify?): void {
    if (
      markerIdentify?.formInputType === 'radio_checkbox' ||
      markerIdentify?.formInputType === 'radio'
    ) {
      super.onResizeStop(event);
    }
    this.decreaseFontSizeIfExceedWidth();
  }

  decreaseFontSizeIfExceedWidth() {
    // ลด fontsize จนกว่าจะไม่เกิน width ที่กำหนดใน template
    const oldFontSize = this.fontSize;
    const limitWidth = this.size[this.bindWidth];
    let textWidth = this.calculateTextWidthPx(this.fontSize);
    let newFontSize = oldFontSize;
    while (textWidth > limitWidth) {
      newFontSize -= 0.01;
      textWidth = this.calculateTextWidthPx(newFontSize);
    }
    if (oldFontSize !== newFontSize) {
      this.fontSizeChange.emit(newFontSize);
    }
  }

  increaseFontSizeBackToFixedSize() {
    // เพิ่ม fontsize จนว่าจะพอดีกับ width ที่กำหนด แต่ต้องไม่เกิน fontsize ที่เซ็ตไว้จาก template
    const oldFontSize = this.fontSize;
    const limitWidth = this.size[this.bindWidth];
    let textWidth = this.calculateTextWidthPx(this.fontSize);
    let newFontSize = oldFontSize;
    while (
      textWidth < limitWidth &&
      newFontSize < this.fixedFontSize
    ) {
      newFontSize += 0.01;
      textWidth = this.calculateTextWidthPx(newFontSize);
    }
    if (oldFontSize !== newFontSize) {
      this.fontSizeChange.emit(newFontSize);
    }
  }

  calculateTextWidthPx(fontSize: number) {
    if (!this.canvasCtx) {
      return 0;
    }
    this.canvasCtx.font = `${fontSize}px ${this.fontFamily}`;
    return (
      this.canvasCtx.measureText(this.markerIdentity.data).width /
      this.scale
    );
  }

  onKeyPressNonNegative(event: KeyboardEvent | any): boolean {
    const char = event.charCode;
    const isNumeric = char >= 48 && char <= 57;
    const isDecimalPoint = char === 46;
    const isBackspace = char === 8;
    const isComma = char === 44;

    return isBackspace || isNumeric || isDecimalPoint || isComma;
  }

  getWidth() {
    const hideIconSetting = 24;
    if (!this.canSettingForm && !this.canFillForm) {
      return this._width - 3 + hideIconSetting;
    } else if (this.canFillForm) {
      return this._width + hideIconSetting;
    } else {
      return this._width;
    }
  }
}
