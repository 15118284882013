import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/core/http/api.service';
import { ThemeService } from 'src/app/shared/service/theme.service';
import { MemoService } from '../../../service/memo.service';
import { ReferencesDetail } from '../../../model/memo.model';
import { AlertService } from '../../../../../core/services/alert.service';
import { ApproveService } from '../../../../approval/approve.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-memo-real-reference',
  templateUrl: './memo-real-reference.component.html',
  styleUrls: ['./memo-real-reference.component.scss'],
})
export class MemoRealReferenceComponent implements OnInit {
  memoRefs: ReferencesDetail[] = [];
  showPreview = false;
  userList = [];
  documentData: any;
  showResults = false;
  isLoading = false;
  count = 0;
  documentName: string;
  currentPage = 1;
  userListCurrentPage = 1;
  searchUser: string;

  isMobile: boolean;

  debouncer = new Subject<string>();
  @Input() memo: any;
  @Input() memoID: any;
  @Output() countReference = new EventEmitter<number>();

  constructor(
    private alert: AlertService,
    private projectService: MemoService,
    private memoService: MemoService,
    private approveService: ApproveService,
    private themeService: ThemeService,
    private httpClientService: ApiService,
    private store: Store,
    private apiService: ApiService,
  ) {
    this.debouncer
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((event) => {
        this.userListCurrentPage = 1;
        this.getUserList(event);
      });
  }

  ngOnInit(): void {
    this.loadMemoReferences();
    this.isMobile = this.themeService.isMobile();
  }

  loadMemoReferences(): void {
    this.showResults = false;
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const id = this.memo.id;
    // Check condition is non-user
    (this.memoID
      ? this.approveService.getReferenceList(header, id)
      : this.memoService.getMemoReferenceById(id)
    ).subscribe(
      (res: any) => {
        this.memoRefs = res;
        this.count = this.memoRefs.length;
        this.showResults = true;
        this.countReference.emit(this.count);
      },
      (error) => {
        this.store.dispatch(new ErrorNotification(error));
        this.showResults = true;
      },
    );
  }

  getUserList(filterUsername?): void {
    let params = new HttpParams().set(
      'page',
      this.userListCurrentPage.toString(),
    );
    if (filterUsername) {
      params = params.set('full_name', filterUsername);
    }
  }

  getExtension(filepath): string {
    if (!filepath) {
      return '';
    }
    const filePath = filepath.split('?')?.[0];
    return filePath.substr(filePath.lastIndexOf('.') + 1);
  }

  closePreview(): void {
    this.showPreview = false;
  }

  preview(memoRef: ReferencesDetail): void {
    if (!memoRef.signed_document) {
      return;
    }
    this.documentData = memoRef.signed_document;
    this.documentName = memoRef.memo_number + '.pdf';
    this.showPreview = true;
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  deleteMemoReference(targetMemoNumber: string): void {
    const data = {
      reference_memo_numbers: this.memoRefs
        .filter((ref) => ref.memo_number !== targetMemoNumber)
        .map((ref) => ref.memo_number),
    };
    this.memoService
      .updateMemoReferenceById(this.memo.id, data)
      .subscribe(
        () => this.loadMemoReferences(),
        (error) => this.alert.error(error.status),
      );
  }

  downloadDocument(memoRef: ReferencesDetail): void {
    this.documentName = memoRef.memo_number + '.pdf';
    this.httpClientService
      .download(memoRef.signed_document)
      .subscribe(
        (res: any) => {
          const blob: Blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.documentName;
          link.click();
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
  }
}
