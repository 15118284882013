import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from 'src/app/shared/service/api.constant';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable()
export class ApproveService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private httpService: ApiService,
  ) {}

  requestHeader(headers: HttpHeaders): HttpHeaders {
    const lang =
      this.translate.currentLang || localStorage.getItem('lang');
    headers = headers.append('Accept-Language', lang || 'en');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  requestHeaderForm(headers: HttpHeaders): HttpHeaders {
    const lang =
      this.translate.currentLang || localStorage.getItem('lang');
    headers = headers.append('Accept-Language', lang || 'en');
    headers = headers.append('Accept', 'application/json');
    headers.set('Content-Type', 'multipart/form-data');
    return headers;
  }

  getMemoDetail(id, header, params?) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + id + '/',
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getMemoIsNeedEKycToAccess(id, header) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + id + '/e-kyc-check/',
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getMemoVersion(params, header) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + 'version/',
      {
        params,
        headers: this.requestHeader(header),
      },
    );
  }

  approveMemo(id, header, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.memos + id + '/approve/',
      data,
      {
        headers: this.requestHeaderForm(header),
      },
    );
  }

  rejectMemo(id, header, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.memos + id + '/reject/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  terminateMemo(id, header, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.memos + id + '/terminate/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getUserDetail(header) {
    return this.http.get(environment.baseUrl + API_URL.me, {
      headers: this.requestHeader(header),
    });
  }

  uploadPersonBlob(id, data, header) {
    return this.http.patch(
      environment.baseUrl + API_URL.upload_person_blob + id + '/',
      data,
      { headers: header },
    );
  }

  getAttachmentList(header, params, isInOut = false) {
    const url = isInOut
      ? API_URL.memo_inout_attachment
      : API_URL.memo_attachment;
    return this.http.get(environment.baseUrl + url, {
      params,
      headers: this.requestHeader(header),
    });
  }

  private getCommentUrl(isInOut = false): string {
    return isInOut
      ? API_URL.memo_inout_comment
      : API_URL.memo_comment;
  }

  getCommentList(header, params, isInOut = false) {
    const url = this.getCommentUrl();
    return this.http.get(environment.baseUrl + url, {
      params,
      headers: this.requestHeader(header),
    });
  }

  getReferenceList(header, id) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + id + '/references/',
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getMemoHistory(header, params) {
    return this.http.get(
      environment.baseUrl + API_URL.memos_history,
      {
        params,
        headers: this.requestHeader(header),
      },
    );
  }

  createNewComment(header, data, isInOut = false) {
    return this.http.post(
      environment.baseUrl + this.getCommentUrl(isInOut),
      data,
      {
        headers: this.requestHeaderForm(header),
      },
    );
  }

  deleteComment(header, id, isInOut = false) {
    return this.http.delete(
      environment.baseUrl + this.getCommentUrl(isInOut) + id + '/',
      {
        headers: this.requestHeader(header),
      },
    );
  }

  // Announcement
  getAnnouncementList(header, params?) {
    return this.http.get(environment.baseUrl + '/api/announcement/', {
      params,
      headers: this.requestHeader(header),
    });
  }

  ack(id, header, data?) {
    return this.http.post(
      environment.baseUrl + '/api/acknowledges/' + id + '/ack/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  downloadMemo(id, header?, data?) {
    return this.http.post(
      environment.baseUrl + '/api/memos/' + id + '/download-pdf/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  logDownloadFile(
    id,
    log_type: 'rejected' | 'attachment' | 'comment',
    header?,
  ) {
    return this.http.post(
      environment.baseUrl +
        '/api/memos/' +
        id +
        '/log-download-file/',
      { log_type },
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getAcknowledgeViaEmail(params, header) {
    return this.http.get(environment.baseUrl + '/api/acknowledges/', {
      params,
      headers: this.requestHeader(header),
    });
  }

  getRetrieveContract(id, header) {
    return this.http.get(environment.baseUrl + `/api/memos/${id}/`, {
      headers: this.requestHeader(header),
    });
  }

  getContractAvail(id, params, header) {
    return this.http.get(
      environment.baseUrl + `/api/memos/contract/${id}/` + 'avail/',
      {
        params,
        headers: this.requestHeader(header),
      },
    );
  }

  checkCredit(id, header, data) {
    return this.http.post(
      environment.baseUrl +
        '/api/memos/contract/' +
        id +
        '/check-credit/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }
}
