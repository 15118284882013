<div class="row">
  <div class="col-12">
    <ngb-progressbar [type]="currentStateProgressType"
        [striped]="currentStateProgressType === 'info'"
        [animated]="currentStateProgressType === 'info'"
        [value]="sendingTaskDetail.percent"
        height="2rem">
      <div class="font-weight-bold">
        <h4 class="mb-0"
            *ngIf="sendingTaskDetail._state === celeryImportState.Progress">
          {{ sendingTaskDetail.percent }}/100
        </h4>
        <h5 class="mb-0 text-white"
            *ngIf="sendingTaskDetail._state !== celeryImportState.Progress">
          {{ 'EXPORT.' + sendingTaskDetail._state | translate }}</h5>
      </div>

    </ngb-progressbar>
  </div>

  <div class="col-12 mt-4"
      *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
    <ngb-alert type="danger"
        [dismissible]="false">
      <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
      <div class="frame error p-3"
          [innerHTML]="sendingTaskDetail.detail"></div>
    </ngb-alert>
  </div>
</div>
