<div class="container-input position-relative">
  <div #resizeContainer="ngResizable"
      class="resizable-widget position-relative"
      rzHandles="se"
      [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm}"
      [ngStyle]="resizableStyle"
      [ngResizable]="canSettingForm"
      [rzAspectRatio]="lockRatio"
      [rzContainment]="containment"
      (rzStop)="onResizeStop($event)"
      (rzResizing)="onResizing($event)">
    <input class="textLine px-0"
        [id]="markerIdentity.id"
        #inputTextLine
        type="text"
        (keypress)="onKeyPressNonNegative($event)"
        pattern="[\d,]+"
        [ngClass]="{
          'line-textarea': isUploadTemplate,
          'bg-none': !isUploadTemplate,
          'event-none': !canFillForm
        }"
        [style.color]="markerIdentity.color"
        [style.font-family]="fontFamily"
        [style.font-size.px]="fontSize"
        [style.line-height.px]="fontSize * 1.1"
        [style.--line]="fontSize+'px'"
        [style.width.px]="getWidth()"
        [ngModel]="markerIdentity.data"
        (ngModelChange)="onTextChange($event)"
        (keydown.enter)="fillTextEnter.emit($event.target.value)"
        [disabled]="!canFillForm"
        [placeholder]="translateService.currentLang === 'th' ? markerIdentity.options.placeholderTH : markerIdentity.options.placeholderEN"
        [maxLength]="markerIdentity.options?.maxValue ? markerIdentity.options?.maxValue : 9999999" />
  </div>
</div>
<div *ngIf="error"
  class="text-danger display-error">
  {{error | translate}} {{errorValue}}
</div>
