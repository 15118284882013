import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularResizableDirective } from 'angular2-draggable';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';

@Component({
  selector: 'app-resizable-draggable',
  templateUrl: './resizable-draggable.component.html',
  styleUrls: ['./resizable-draggable.component.scss'],
})
export class ResizableDraggableComponent {
  state = '';
  /** The property name in `size` for binding height */
  @Input() bindHeight = 'height';
  /** The property name in `size` for binding width */
  @Input() bindWidth = 'width';
  @Input() containment: HTMLElement;
  @Input() resizableStyle: { [styleName: string]: string | number };
  @Input() img: string;
  /** An opacity of image */
  @Input() imgOpacity = 1;
  /** A source of the default image */
  @Input() defaultImage =
    // eslint-disable-next-line max-len
    'https://previews.123rf.com/images/nalinn/nalinn1508/nalinn150800018/44272522-red-grunge-approved-rubber-stamp-isolated-on-white-background.jpg';
  @Input() lockRatio = false;
  /** Scale has purpose for responsive display */
  @Input() scale = 1;
  /** The size is executed with pixel unit */
  @Input() size: Partial<Size> = { height: null, width: 100 };
  @Input() sizeBoundary: SizeBoundary;
  @Input() resetButtonSize = 16;
  @Input() resetButtonStyle: { [styleName: string]: string };
  @Input() allowEdit = false;
  @Input() allowReset = true; // false if `allowEdit` is false
  @Output() sizeChange = new EventEmitter<Size>();
  @ViewChild('containerImage')
  containerImage: AngularResizableDirective;
  @ViewChild('resizedImage')
  resizedImage: ElementRef<HTMLImageElement>;

  @Input() isSignature = false;
  @Input() isApprovalRequest;

  constructor(public translate: TranslateService) {}

  onImageLoad(): void {
    this.setInitialSizeImg();
    /**
     * The aspect ratio calculation had been executed
     * only `ngAfterViewInit` in `ng-resizable` directive
     * where is called before image loaded completely.
     * So, when the img has done. It need to re-calculate.
     */
    this.containerImage.ngAfterViewInit();
  }

  onResizeStart(event: IResizeEvent): void {
    this.state = 'Resize Started';
    this._setSize(event.size);
  }

  onResizing(event: IResizeEvent): void {
    this.state = 'Resizing';
    this._setSize(event.size);
  }

  onResizeStop(event: IResizeEvent): void {
    this.state = 'Resize Stopped';
    this._setSize(event.size);
    this.sizeChange.emit({
      height: this.size[this.bindHeight],
      width: this.size[this.bindWidth],
    });
  }

  onReset(block: AngularResizableDirective): void {
    block.resetSize();
    const status = block.getStatus();
    this._setSize(status.size);
  }

  setInitialSizeImg(): void {
    if (this.size == null) {
      return;
    }
    const size: Size = {
      height: this._height || this.resizedImage.nativeElement.height,
      width: this._width || this.resizedImage.nativeElement.width,
    };
    this._setSize(size);
  }

  private _setSize(size: Size) {
    this._height = size.height;
    this._width = size.width;
  }

  get _height(): number {
    return this.size[this.bindHeight] * this.scale;
  }

  set _height(val: number) {
    const height = val / this.scale;
    this.size[this.bindHeight] = height;
  }

  get _width(): number {
    return this.size[this.bindWidth] * this.scale;
  }

  set _width(val: number) {
    const width = val / this.scale;
    this.size[this.bindWidth] = width;
  }

  get imageSource(): string {
    return this.img || this.defaultImage;
  }
}

export interface Size {
  height: number;
  width: number;
}

export interface SizeBoundary {
  maxHeight: number;
  maxWidth: number;
  minHeight: number;
  minWidth: number;
}
