import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { MemoService } from '../../../service/memo.service';
import { ThemeService } from 'src/app/shared/service/theme.service';
import { CommentList } from '../../../model/memo.model';
import { AlertService } from '../../../../../core/services/alert.service';
import { Store } from '@ngxs/store';
import { UserDetail } from 'src/app/store/auth/auth.model';
import { HttpHeaders } from '@angular/common/http';
import { ApproveService } from '../../../../approval/approve.service';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-memo-comment',
  templateUrl: './memo-comment.component.html',
  styleUrls: ['./memo-comment.component.scss'],
})
export class MemoCommentComponent implements OnInit, OnDestroy {
  newComment: string;
  commentList = [];
  owner = localStorage.full_name;
  documentData: string;
  documentName: string;
  documentDownloadLogType: 'comment' | 'rejected' = 'comment';
  showResult = false;
  showPreview = false;
  currentPage = 1;
  finalPage = false;
  chatSpin = false;
  subscription: Subscription[] = [];
  photo = localStorage.getItem('photoUrl');
  isMobile: boolean;
  profile: UserDetail;
  checkLanguageEn = false;
  @Input() data: any;
  @Input() normalHeight = false;
  @Input() person: number;
  @Input() memoID: any;
  @Input() actionType: string;
  @Input() isInOut = false;
  @Output() submitted = new EventEmitter();

  constructor(
    private memoService: MemoService,
    private approveService: ApproveService,
    public translate: TranslateService,
    private themeService: ThemeService,
    private alert: AlertService,
    private store: Store,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this.getCommentList();
    this.isMobile = this.themeService.isMobile();
    this.subscription.push(
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.translateComment();
        },
      ),
    );
    this.subscription.push(
      this.store.subscribe((state) => {
        this.profile = state.auth;
      }),
    );
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  addThaiComment(comments?: CommentList[]): CommentList[] {
    return comments.map((comment) => ({
      ...comment,
      // tslint:disable-next-line:max-line-length
      commentTh:
        comment.message != null
          ? comment.message
              .replace('Terminated reason', 'เหตุผลการไม่อนุมัติ')
              .replace('Rejected reason', 'เหตุผลที่ต้องแก้ไข')
          : comment.message,
    }));
  }

  translateComment(): void {
    this.checkLanguageEn = this.translate.currentLang === 'en';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.forEach((item) => {
        try {
          item.unsubscribe();
        } catch (_) {}
      });
    }
  }

  startCommentListRunner(): void {
    const source = interval(60000);
    this.subscription.push(
      source.subscribe((val) => this.getRefreshCommentList()),
    );
  }

  getCommentList(): void {
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const params = { memo: this.data, none_pagination: 'True' };

    const subscription = (
      this.memoID
        ? this.approveService.getCommentList(
            header,
            params,
            this.isInOut,
          )
        : this.memoService.getCommentList(params, this.isInOut)
    ).subscribe((comments: any) => {
      this.commentList = this.addThaiComment(comments);
      this.translateComment();
      this.showResult = true;
      this.startCommentListRunner();
    });
    this.subscription.push(subscription);
  }

  getRefreshCommentList(): void {
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const params = { memo: this.data, none_pagination: 'True' };

    const subscription = (
      this.memoID
        ? this.approveService.getCommentList(
            header,
            params,
            this.isInOut,
          )
        : this.memoService.getCommentList(params, this.isInOut)
    ).subscribe((comments: any) => {
      this.commentList = this.addThaiComment(comments);
      this.showResult = true;
    });
    this.subscription.push(subscription);
  }

  addComment(newComment: Comment): void {
    if (
      newComment[0].newComment == null ||
      newComment[0].newComment.trim().length === 0
    ) {
      return;
    }
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const fd = {
      mentioned_people: newComment[0].mentionedPerson,
      memo: this.data,
      message: newComment[0].newComment || '',
    };

    const subscription = (
      this.memoID
        ? this.approveService.createNewComment(
            header,
            fd,
            this.isInOut,
          )
        : this.memoService.createNewComment(fd, this.isInOut)
    ).subscribe((comment) => {
      this.commentList.push(comment);
      this.commentList = this.addThaiComment(this.commentList);
    });
    this.subscription.push(subscription);
  }

  deleteComment(commentId: number): void {
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const subscription = (
      this.memoID
        ? this.approveService.deleteComment(
            header,
            commentId,
            this.isInOut,
          )
        : this.memoService.deleteComment(commentId, this.isInOut)
    ).subscribe(
      () => {
        this.commentList = this.commentList.filter(
          (stream) => stream.id !== commentId,
        );
        this.alert.success(
          this.translate.instant('MEMOS.SAVE-CHANGES'),
        );
      },
      (error) => this.alert.error(error.status),
    );
    this.subscription.push(subscription);
  }

  isOwner(comment: any): boolean {
    return (
      comment.message === comment.commentTh &&
      this.person === comment.created_person
    );
  }

  uploadFile(event): void {
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    const fd = new FormData();
    fd.append('file', event.target.files[0]);
    fd.append('memo', this.data);

    const subscription = (
      this.memoID
        ? this.approveService.createNewComment(
            header,
            fd,
            this.isInOut,
          )
        : this.memoService.createNewComment(fd, this.isInOut)
    ).subscribe((res) => {
      this.getCommentList();
    });
    this.subscription.push(subscription);
  }

  getExtension({ file_upload }): string {
    if (!file_upload) {
      return '';
    }
    const filePath = file_upload.split('?')?.[0];
    return filePath.substr(filePath.lastIndexOf('.') + 1);
  }

  checkImageURL(url: string): boolean {
    return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  }

  preview(file, fileName, comment?: CommentList): void {
    if (this.isMobile) {
      this.showPreview = false;
      this.apiService.openMediaUrlInNewWindow(file);
    } else {
      if (location.protocol === 'https:') {
        file = file.replace(/^http:/, 'https:');
      }
      this.documentData = file;
      this.documentName = fileName;
      this.showPreview = true;

      if (!comment) return;
      this.documentDownloadLogType = this.isRejectedMemoComment(
        comment,
      )
        ? 'rejected'
        : 'comment';
    }
  }

  isRejectedMemoComment(comment: CommentList): boolean {
    return (
      comment.message?.toLowerCase().startsWith('rejected reason') &&
      comment.file &&
      comment.file_name.includes('version')
    );
  }

  closePreview(): void {
    this.showPreview = false;
  }

  canDeleteComment(comment: { [type: string]: string }): boolean {
    return (
      comment.message === comment.commentTh &&
      this.profile.person_id === +comment.created_person
    );
  }
}
