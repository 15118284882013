<div class="reference-container">

  <ng-container *ngIf="!showResults else showReference">
    <app-loading-spinner *ngIf="!showResults"></app-loading-spinner>
  </ng-container>

  <ng-template #showReference>

    <div class="reference-list">
      <div *ngIf="(memoRefs.length===0 && showResults) else hasReference" class="no-reference-file text-center">
        <svg-icon src="assets/images/icons/no-ref.svg" [svgStyle]="{'width.px': 180, 'height.px': 180}"></svg-icon>
        <div class="text-muted mt-4">{{"DASHBOARD.NO-REF"|translate}}</div>
      </div>

      <ng-template #hasReference>
        <div class="reference-item" *ngFor="let memoRef of memoRefs; let index = index">
          <div class="reference-item-info" (click)="preview(memoRef)">
            <div class="file-type" *ngIf="memoRef.signed_document" [ngClass]="getExtension(memoRef.signed_document)"
                 (click)="preview(memoRef)">
              {{getExtension(memoRef.signed_document)}}
            </div>
            <a class="text-muted text-bold">{{ index + 1 }}. </a>
            <a href="javascript:void(0)" (click)="preview(memoRef)" class="text-muted text-bold">
              {{ memoRef.memo_number }}
            </a>
          </div>
          <div class="reference-item-btn">
            <svg-icon *ngIf="memoRef.memo_type" class="mr-2 pointer" src="assets/images/icon_download2.svg"
                  [svgStyle]="{'width.px': 16, 'height.px': 16,'fill': '#2e5b8f'}"
                  (click)="downloadDocument(memoRef)"></svg-icon>
            <app-delete-confirmation-modal [data]="memoRef.memo_number" [useNewDesign]="true"
                                           *ngIf="memo.status === 'draft' || memo.status === 'rejected'"
                                           (submitted)="deleteMemoReference($event.data)">
            </app-delete-confirmation-modal>
          </div>
        </div>
      </ng-template>

    </div>

  </ng-template>

</div>

<div *ngIf="showPreview">
  <app-memo-preview [document]="documentData" [fileName]="documentName" [typeFile]="getExtension(documentData)"
                    (closed)="closePreview()">
  </app-memo-preview>
</div>
