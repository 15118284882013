import { Component } from '@angular/core';
import { InputRadioComponent } from '../input-radio/input-radio.component';

@Component({
  selector: 'app-input-radio-checkbox',
  templateUrl: './input-radio-checkbox.component.html',
  styleUrls: [
    '../input-radio/input-radio.component.scss',
    '../input-checkbox/input-checkbox.component.scss',
    '../input-textline/input-textline.component.scss',
    '../base-input/base-input.component.scss',
    './input-radio-checkbox.component.scss',
  ],
})
export class InputRadioCheckboxComponent extends InputRadioComponent {
  CHECK_TEXT = '✓';
}
