import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../core/http/api.service';
import { API_URL } from './api.constant';
import { MemoService } from '../../modules/memos/service/memo.service';
import { RESPONSE$ } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  memoNotificationCount = 0;
  overDueCount = 0;
  todayDueCount = 0;
  nextDueCount = 0;
  memoNotificationReviewCount = 0;
  assignAssigneeOverDueCount = 0;
  assignAssigneeTodayDueCount = 0;
  assignAssigneeNextDueCount = 0;
  actionRequireCount = 0;
  outgoingApprovalOverdueCount = 0;
  outgoingApprovalTodayDueCount = 0;
  outgoingApprovalNextDueCount = 0;

  constructor(
    private router: Router,
    private http: ApiService,
    private memoService: MemoService,
  ) {}

  updateNotificationCount() {
    this.memoService.getMyTaskBadge().subscribe((memos: any) => {
      this.memoNotificationCount = memos?.count ?? 0;
      this.overDueCount = memos?.count_overdue ?? 0;
      this.todayDueCount = memos?.count_today ?? 0;
      this.nextDueCount = memos?.count_nextdue ?? 0;
      this.memoNotificationReviewCount = memos.count_review;
    });

    this.memoService
      .getAssignAssigneeBadge()
      .subscribe((res: any) => {
        this.assignAssigneeOverDueCount = res?.count_overdue ?? 0;
        this.assignAssigneeTodayDueCount = res?.count_today ?? 0;
        this.assignAssigneeNextDueCount = res?.count_nextdue ?? 0;
      });

    this.memoService
      .getOutgoingApprovalBadge()
      .subscribe((res: any) => {
        this.outgoingApprovalOverdueCount = res?.count_overdue ?? 0;
        this.outgoingApprovalTodayDueCount = res?.count_today ?? 0;
        this.outgoingApprovalNextDueCount = res?.count_nextdue ?? 0;
      });

    this.memoService.getActionRequireBadge().subscribe((res: any) => {
      this.actionRequireCount = res?.count ?? 0;
    });
  }

  // Feature Notifications

  getAllNotification(params?): RESPONSE$ {
    return this.http.get(API_URL.notification, params);
  }

  markAllAsRead() {
    return this.http.post(API_URL.notification + 'read-all/', null);
  }

  updateIsRead(id) {
    return this.http.post(
      API_URL.notification + id + '/' + 'read' + '/',
      id,
    );
  }
}

export interface MutedNotification {
  id?: number;
  disabled?: boolean;
  channel: string;
  action: string;
}

export interface NotificationList {
  group: string;
  actions: Actions[];
}

export interface Actions {
  label: string;
  value: string;
}

export interface NotificationChannel {
  name: string;
  label: string;
}
