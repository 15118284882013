import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgbDate,
  NgbDropdownConfig,
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { get } from 'lodash';

import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { API_URL } from 'src/app/shared/service/api.constant';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { MemoService } from '../../../memos/service/memo.service';
import {
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
  debounceTime,
} from 'rxjs/operators';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { AlertService } from '../../../../core/services/alert.service';
import {
  DropdownResponse,
  DropdownService,
} from '../../../../shared/service/dropdown.service';
import { Search } from '../../executive.model';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-executive-list',
  templateUrl: './executive-list.component.html',
  styleUrls: ['./executive-list.component.scss'],
})
export class ExecutiveListComponent implements OnInit, OnDestroy {
  memoList = [];
  selectedMemo: null;
  memoId: string = null;
  pdfFile: string;

  count = 0;
  currentPage = 1;
  setPageSize = 10;

  showResults = false;
  parentViewType = 0;

  isDesktop = !this.themeService.isTabletOrMobile();
  themeList: ThemeList;
  toggleSideMenu = true;
  showToggle = false;
  isSearch = false;

  refreshDropdown = false;

  departmentList;
  memoTypeList;
  memoUploadTypeList: { label: any; value: any; context?: any }[];
  expireItemList;
  search: Search = {
    search: '',
    department: null,
    memo_type: null,
    date_at_before: '',
    date_at_after: '',
    expireItem: null,
    upload_memo_type: null,
  };

  isClickSearch: any = {
    department: false,
    memo_type: false,
    dateRange: false,
    expireItem: false,
    upload_memo_type: false,
  };
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  createdAtAfter = '';
  createdAtBefore = '';
  hoveredDate: NgbDate | null = null;
  rangeDate: string = null;
  expireDate = null;
  expireItem = [
    {
      label: 'หมดอายุในอีก 1 วัน',
      label_en: 'Expire in 1 day',
      value: 1,
    },
    {
      label: 'หมดอายุในอีก 3 วัน',
      label_en: 'Expire in 3 days',
      value: 3,
    },
    {
      label: 'หมดอายุในอีก 5วัน',
      label_en: 'Expire in 5 days',
      value: 5,
    },
    {
      label: 'หมดอายุในอีก 7 วัน',
      label_en: 'Expire in 7 days',
      value: 7,
    },
    {
      label: 'ไม่มีวันหมดอายุ',
      label_en: 'No expiration date',
      value: 'none',
    },
  ];

  heightSearch: number;
  displayWidthPage: number;

  searchInput$ = new Subject<string>();

  @ViewChild('executiveDetail', { static: false })
  executiveDetail: any;
  @ViewChild('widthPage', { static: false })
  widthPage: ElementRef;
  @ViewChild('searchList', { static: false })
  searchList: ElementRef;

  @ViewChild('containerRef', { static: false })
  containerRef: ElementRef;

  destroy$ = new Subject<boolean>();
  memoList$ = new BehaviorSubject<any[]>([]);

  dueDateType$: Observable<DateFilterType | null> =
    this.activatedRoute.queryParams.pipe(
      map(({ type, due_date }) => {
        if (type) {
          return type;
        }
        if (due_date) {
          return this.determinedDateType(due_date);
        }
        return null;
      }),
    );

  listFilterByDateType$: Observable<any[]> = combineLatest([
    this.dueDateType$,
    this.memoList$,
  ]).pipe(
    map(([type, memos]) => {
      if (!type) {
        return memos;
      }
      return this.filterMemosByDueDate(memos, type);
    }),
  );

  listFilterByDateTypeCount$ = this.listFilterByDateType$.pipe(
    map((memos) => memos?.length ?? 0),
  );

  subscription: Subscription[] = [];
  constructor(
    private memoService: MemoService,
    private notification: NotificationService,
    private alert: AlertService,
    private dropdownConfig: NgbDropdownConfig,
    private store: Store,
    private themeService: ThemeService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private dropdownService: DropdownService,
  ) {
    dropdownConfig.placement = 'bottom-right';
    dropdownConfig.autoClose = false;
    this.subscription.push(
      this.searchInput$
        .pipe(distinctUntilChanged())
        .subscribe((searchText: string) => {
          this.currentPage = 1;
          this.search.search = searchText;
          this.onFilterChange();
        }),
    );
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
    this.subscription.push(
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.translateSelect();
        },
      ),
    );

    // when sidebar tab change
    this.dueDateType$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => {
        // this.onFilterChange();
        this.memoId = null;
        this.selectedMemo = null;
      });

    // when filter memos change
    combineLatest(this.dueDateType$, this.listFilterByDateTypeCount$)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(([type, count]) => {
        if (type === DateFilterType.TODAY) {
          this.notification.todayDueCount = count;
        } else if (type === DateFilterType.OVER_DUE) {
          this.notification.overDueCount = count;
        } else if (type === DateFilterType.NEXT_DUE) {
          this.notification.nextDueCount = count;
        }
      });
  }

  ngOnInit() {
    this.getDepartmentMemoTypeList();
    this.loadMemos();
    this.checkParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  getDepartmentMemoTypeList(): void {
    this.expireItemList = this.expireItem;
    const type = 'department';
    const params = {
      type,
      all_auth: 'true',
    };
    const dropdownSubscription = this.dropdownService
      .getDropdown(params)
      .subscribe(
        (dropdown: DropdownResponse) => {
          this.departmentList = dropdown.department;
          this.memoTypeList = this.dropdownService.memoTypeItem();
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
    this.subscription.push(dropdownSubscription);
  }

  translateSelect(): void {
    this.refreshDropdown = true;
    setTimeout(() => {
      this.refreshDropdown = false;
    }, 1);
  }

  checkParams() {
    this.activatedRoute.params.subscribe((params) => {
      // handle routing by id
      this.memoId = params.memo;
    });
  }

  get parameter(): { [type: string]: string } {
    return {
      ...this.search,
      department: this.search?.department || '',
      memo_type: this.search?.memo_type || '',
      created_at_before: this.createdAtBefore,
      created_at_after: this.createdAtAfter,
      expire_in: this.search?.expireItem || '',
      upload_memo_type: this.search?.upload_memo_type || '',
    };
  }

  loadMemos(nextMemo?) {
    this.showResults = false;
    this.spinner.show();
    this.memoService
      .getMyTaskList(this.parameter)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        (memos: any[]) => {
          this.count = memos?.length ?? 0;
          this.notification.memoNotificationCount = this.count;
          this.memoList = memos;
          this.memoList$.next(this.memoList);
          this.showResults = true;
          if (this.memoId || (memos[0] && nextMemo === 'success')) {
            const findMemo = _.find(memos, {
              id: parseInt(this.memoId, 10),
            });
            if (findMemo || nextMemo) {
              const checkMemo =
                nextMemo === 'success' ? memos[0] : findMemo;
              this.clickMemo(checkMemo);
            } else {
              return this.navigateToPage(
                '/memos/preview/',
                this.memoId,
              );
            }
          }
          this.displayWidthPage =
            this.widthPage.nativeElement.scrollWidth;
          if (this.isSearch) {
            this.heightSearch =
              this.searchList.nativeElement.scrollHeight;
          }
          if (
            !this.search.search &&
            !this.search.department &&
            !this.search.memo_type &&
            !this.search.upload_memo_type &&
            !this.fromDate &&
            !this.search.expireItem
          ) {
            this.isSearch = false;
          }
        },
        () => this.alert.error('Unable to load Memo'),
      );
  }

  getMemoUploadType() {
    const type = 'memo_upload_type';
    const params = {
      type,
      departments: this.departmentList[0].value
        ? this.departmentList[0].value.toString()
        : '',
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.memoUploadTypeList = dropdown.memo_upload_type;
      },
      (error) => {
        this.store.dispatch(new ErrorNotification(error));
      },
    );
  }

  navigateToPage(url: string, id: string) {
    return this.router.navigate((url + id).split('/'));
  }

  clickMemo(memo) {
    const isTabletOrMobilePortrait =
      this.themeService.isTabletOrMobilePortrait();
    if (isTabletOrMobilePortrait) {
      this.toggleSideMenu = false;
    }
    this.selectedMemo = null;
    this.pdfFile = null;
    setTimeout(() => {
      this.selectedMemo = memo;
      this.loadPreviewData(memo);
      this.parentViewType = 0;
    }, 1);
  }

  loadPreviewData(data) {
    setTimeout(() => {
      switch (data.upload_type) {
        case 'offline_with_memo_number':
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile = null;
          }
          break;
        case 'offline':
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile = null;
          }
          break;
        default:
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile =
              API_URL.memo_template +
              '?type=' +
              data.type +
              '&id=' +
              data.id;
          }
          break;
      }
    }, 1);
  }

  checkIfSelected(memo) {
    return get(this.selectedMemo, 'id', '') === memo.id;
  }

  actionChange(toggleSide?, data?) {
    this.toggleSideMenu =
      toggleSide === 'close' || 'success' ? true : false;
    this.loadMemos(toggleSide);
    this.selectedMemo = null;
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  onFilterChange(clear = false): void {
    this.isSearch = !clear;
    this.loadMemos();
    this.closeFilter();
    this.showToggle = false;
  }

  clearSearch(): void {
    this.search = {
      search: '',
      department: null,
      memo_type: null,
      expireItem: null,
      date_at_before: '',
      date_at_after: '',
      upload_memo_type: null,
    };
    (this.createdAtAfter = ''),
      (this.createdAtBefore = ''),
      (this.rangeDate = null);
    this.fromDate = null;
    this.toDate = null;
    this.hoveredDate = null;
    this.onFilterChange(true);
  }

  onClickSearch() {
    this.isClickSearch.department = this.search.department
      ? true
      : false;
    this.isClickSearch.memo_type = this.search.memo_type
      ? true
      : false;

    this.isClickSearch.expireItem = this.search.expireItem
      ? true
      : false;

    this.isClickSearch.upload_memo_type = this.search.upload_memo_type
      ? true
      : false;

    this.isClickSearch.dateRange =
      this.search.date_at_after || this.search.date_at_before
        ? true
        : false;

    this.onFilterChange();
  }

  removeSearch(choice: number): void {
    switch (choice) {
      case 1:
        this.search.search = '';
        break;
      case 2:
        this.search.department = null;
        this.isClickSearch.department = false;
        break;
      case 3:
        this.search.memo_type = null;
        this.isClickSearch.memo_type = false;
        break;
      case 4:
        this.search.date_at_after = '';
        this.search.date_at_before = '';
        (this.createdAtAfter = ''),
          (this.createdAtBefore = ''),
          (this.rangeDate = null);
        this.fromDate = null;
        this.toDate = null;
        this.hoveredDate = null;
        this.isClickSearch.dateRange = false;
        break;
      case 5:
        this.search.expireItem = null;
        this.isClickSearch.expireItem = false;
        break;
      case 6:
        this.search.upload_memo_type = null;
        this.isClickSearch.upload_memo_type = false;
        break;
    }

    this.onFilterChange(
      !this.search.search &&
        !this.search.department &&
        !this.search.memo_type &&
        !this.search.upload_memo_type &&
        !this.fromDate &&
        !this.search.expireItem,
    );
  }

  setSearchLabel(item, type): void {
    this.search[type] = item;
    if (this.search.memo_type === 'upload') {
      this.getMemoUploadType();
    } else {
      this.search.upload_memo_type = null;
    }
  }

  closeFilter(): void {
    this.showToggle = false;
  }

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.createdAtAfter = this.setFormatdate(this.fromDate, '-');
      this.rangeDate = this.setFormatdate(this.fromDate, '/', true);
    } else if (
      this.fromDate &&
      !this.toDate &&
      date.equals(this.fromDate)
    ) {
      this.toDate = this.fromDate;
      this.createdAtBefore = this.setFormatdate(this.toDate, '-');
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.createdAtBefore = this.setFormatdate(this.toDate, '-');
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.createdAtAfter = this.setFormatdate(this.fromDate, '-');
      this.rangeDate = this.setFormatdate(this.fromDate, '/', true);
    }
    if (this.toDate) {
      this.rangeDate =
        this.setFormatdate(this.fromDate, '/', true) +
        '  -  ' +
        this.setFormatdate(this.toDate, '/', true);
    }
    this.search.date_at_before = this.createdAtBefore;
    this.search.date_at_after = this.createdAtAfter;
  }

  setFormatdate(date: NgbDate, str: string, reverse = false): string {
    return reverse
      ? date.day + str + date.month + str + date.year
      : date.year + str + date.month + str + date.day;
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return (
      this.toDate &&
      date.after(this.fromDate) &&
      date.before(this.toDate)
    );
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onResize(event): void {
    this.displayWidthPage =
      this.widthPage?.nativeElement?.scrollWidth;
    if (event.srcElement.screen.availWidth >= 1920) {
      this.toggleSideMenu = true;
    }
  }

  calculateHeightSearch(height): number {
    if (!this.isSearch) {
      return 240;
    } else {
      return 250 + height;
    }
  }

  closeToggle(event?): void {
    if (event) {
      const target = event.target as HTMLElement;
      const classList = target.classList;
      // classList mean target name when click
      if (
        classList.contains('ng-option') ||
        classList.contains('ng-option-label') ||
        classList.contains('ng-option-selected') ||
        classList.contains('btn-remove-item') ||
        classList.contains('fa-times') ||
        target.tagName === 'BODY' ||
        classList.contains('custom-day') ||
        classList.contains('custom-select') ||
        classList.contains('btn-link') ||
        classList.contains('ngb-dp-arrow-btn') ||
        classList.contains('ngb-dp-navigation-chevron') ||
        classList.contains('ngb-dp-weekday')
      ) {
        return;
      }
      this.showToggle = false;
    }
  }

  filterMemosByDueDate(memos: any[], type: DateFilterType): any[] {
    return memos.filter((memo) => {
      const dueDateType = this.determinedDateType(memo?.due_date);
      return dueDateType === type;
    });
  }

  determinedDateType(dueDateStr: string): DateFilterType {
    if (!dueDateStr) {
      return DateFilterType.NEXT_DUE;
    }
    const dueDate = new Date(dueDateStr);
    const today = new Date();
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const dueDateTime = dueDate.getTime();
    const todayTime = today.getTime();
    if (dueDateTime === todayTime) {
      return DateFilterType.TODAY;
    } else if (dueDateTime > todayTime) {
      return DateFilterType.NEXT_DUE;
    } else if (dueDateTime < todayTime) {
      return DateFilterType.OVER_DUE;
    }
  }
}

export enum DateFilterType {
  TODAY = 'today',
  NEXT_DUE = 'nextdue',
  OVER_DUE = 'overdue',
}
