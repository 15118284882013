<div class="option-form custom-box">

    <div class="mb-2">
        <label class="mt-2">{{ "UPLOAD.Field Name" | translate }}<span class="text-red">*</span>
        </label>
        <input class="form-control text-charcoal"
            type="text"
            [ngModel]="fieldName"
            [ngClass]="duplicateName || !fieldName ? 'is-invalid' : ''"
            (ngModelChange)="setValidate()"
            (keyup)="fieldNameChange.emit($event.target.value)"
            (keydown.enter)="fieldNameChange.emit($event.target.value)">
        <label class="text-red"
            *ngIf="duplicateName && fieldName">
            {{"UPLOAD.Field Name has already been used"|translate}}
        </label>
        <label class="text-red"
            *ngIf="!fieldName">
            <span>
                {{"UPLOAD.Field Name is required"|translate}}
            </span>
        </label>
    </div>


    <div class="form-check my-2">
        <input type="checkbox"
            class="form-check-input big-checkbox"
            [ngModel]="require"
            (ngModelChange)="requireChange.emit($event)">
        <label class="form-check-label ml-2 font-weight-normal f-14">
            {{ "UPLOAD.Required" | translate }}
        </label>
    </div>

    <!--  min value  -->
    <div class="mb-2">
        <label class="mt-2"
            for="minValue">{{'UPLOAD.Minimum Value' | translate }}
        </label>
        <input id="minValue"
            type="text"
            (keypress)="onKeyPressNonNegative($event)"
            class="form-control"
            placeholder="{{'UPLOAD.number'|translate}}"
            [ngModel]="minValue"
            (ngModelChange)="checkMinValue($event)" />

        <div *ngIf="error.min"
            class="text-danger display-error">
            {{error.min | translate}} {{maxValue}}
        </div>
    </div>


    <!--  max value  -->
    <div class="mb-2">
        <label class="mt-2"
            for="maxValue">{{'UPLOAD.Maximum Value' | translate }}
        </label>
        <input id="maxValue"
            (keypress)="onKeyPressNonNegative($event)"
            type="text"
            class="form-control text-charcoal"
            placeholder="{{'UPLOAD.number'|translate}}"
            [ngModel]="maxValue"
            (ngModelChange)="checkMaxValue($event)" />
        <div *ngIf="error.max"
            class="text-danger display-error">
            {{error.max | translate}} {{minValue}}
        </div>
    </div>


</div>