import { Component, Input } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { DropdownService } from '../../service/dropdown.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-person-dropdown',
  templateUrl: './person-dropdown.component.html',
  styleUrls: ['./person-dropdown.component.scss'],
})
export class PersonDropdownComponent {
  peopleLoading = false;
  people$: Observable<any[]>;
  peopleInput$ = new Subject<string>();

  @Input() control: FormControl;
  @Input() excludeIds: number[] = [];

  constructor(private dropdownService: DropdownService) {
    const type = 'people';
    const group = 'General,Administrator,Official Letter Admin';
    this.people$ = merge(
      of([]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params = {
            type,
            group,
            query,
          };
          return this.dropdownService
            .getDropdown(params)
            .pipe(map((obj: any) => obj.people));
        }),
        map((items) => {
          return (items ?? []).filter((item) => {
            return !this.excludeIds.includes(item?.value);
          });
        }),
        catchError(() => of([])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }
}
