import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as _ from 'lodash';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { MemoService } from '../../../service/memo.service';
import { AuthenticationService } from '../../../../../core/authentication/authentication.service';
import { ThemeService } from '../../../../../shared/service/theme.service';
import { ApiService } from 'src/app/core/http/api.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { HttpHeaders } from '@angular/common/http';
import { ApproveService } from 'src/app/modules/approval/approve.service';
import { featureFlag } from '../../../../../../environments/environment';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-memo-preview',
  templateUrl: './memo-preview.component.html',
  styleUrls: ['./memo-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MemoPreviewComponent implements OnInit {
  _featureFlag = featureFlag;
  pdfFile: any;
  rendering = false;
  modal: NgbModalRef;

  ackModal: NgbModalRef;
  openFingerPrint = false;
  acknowledged: boolean;
  ackComment: string;
  fullSidebar = false;
  httpHeaders;
  isMobile: boolean;
  @Input() idMemo;

  @Input() document: any;
  @Input() fileName = 'FA_file';
  @Input() data: any;
  @Input() isNeedToken = false;
  @Input() typeFile: 'image' | 'pdf';
  @Input() enableAck = false; // for preview memo announcement
  @Input() downloadUrl: string;
  @Output() closed = new EventEmitter();
  @Output() ack = new EventEmitter();
  @Input() header;
  @Input() downloadLogType: 'rejected' | 'attachment' | 'comment';

  @ViewChild('content', { static: true }) content;
  documentName: string;
  @Input() downloadEnable = true;
  constructor(
    // private projectService: ProjectService,
    private apiService: ApiService,
    private notification: NotificationService,
    private memoService: MemoService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private store: Store,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService,
    private alert: AlertService,
    private approveService: ApproveService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.isMobile = this.themeService.isMobile();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.closePreview();
  }
  getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.header);
  }

  ngOnInit(): void {
    this.httpHeaders = this.authenticationService.httpHeader;
    if (!this.isNeedToken) {
      _.isString(this.document)
        ? (this.pdfFile = this.document)
        : this.previewData();
    } else {
      if (this.typeFile === 'image') {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.document = event.target.result;
        };
        reader.readAsDataURL(this.document);
      } else if (this.typeFile === 'pdf') {
        this.document = URL.createObjectURL(this.document);
        _.isString(this.document)
          ? (this.pdfFile = this.document)
          : this.previewData();
      }
    }
    setTimeout(() => {
      this.modal = this.modalService.open(this.content, {
        size: 'lg',
        centered: true,
        windowClass: 'transparent-modal',
        backdrop: 'static',
      });
    }, 100);
  }

  sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  pageRendering(): void {
    this.rendering = true;
  }

  isPDF(): boolean {
    if (this.typeFile === 'pdf') {
      return true;
    } else {
      return !_.isString(this.document)
        ? true
        : _.endsWith(
            this.document.toLowerCase().split('?')[0],
            '.pdf',
          );
    }
  }

  isImage(): boolean {
    if (this.typeFile === 'image') {
      return true;
    } else {
      return !_.isString(this.document) && this.typeFile !== 'pdf'
        ? false
        : _.endsWith(this.document.toLowerCase(), '.jpg') ||
            _.endsWith(this.document.toLowerCase(), '.png') ||
            _.endsWith(this.document.toLowerCase(), '.gif') ||
            _.endsWith(this.document.toLowerCase(), '.jpeg');
    }
  }

  isBadFile(): boolean {
    if (this.isNeedToken) {
      return !(this.typeFile === 'image' || this.typeFile === 'pdf');
    } else {
      return !(this.isPDF() || this.isImage());
    }
  }

  checkIfDocumentIsString(): boolean {
    return _.isString(this.document);
  }

  previewData(): void {
    this.memoService
      .previewMemoPDF(this.document)
      .subscribe((preview: any) => {
        const blob = new Blob([preview], {
          type: 'application/pdf;base64',
        });
        this.pdfFile = URL.createObjectURL(blob);
        this.httpHeaders = this.authenticationService.httpHeader;
      });
  }

  closePreview(): void {
    if (this.modal) {
      this.modal.close();
    }
    this.modal = null;
    this.closed.emit({});
  }

  printDocument(): void {
    this.documentName = this.fileName;
    this.apiService.openMediaUrlInNewWindow(
      this.downloadUrl ?? this.document,
    );
  }

  downloadDocument(): void {
    const header = this.getHeader();
    if (this.idMemo) {
      if (this.header) {
        if (this.downloadLogType) {
          this.approveService
            .logDownloadFile(
              this.idMemo,
              this.downloadLogType,
              header,
            )
            .subscribe();
        } else {
          this.approveService
            .downloadMemo(this.idMemo, header)
            .subscribe();
        }
      } else {
        if (this.downloadLogType) {
          this.memoService
            .logDownloadFile(this.idMemo, this.downloadLogType)
            .subscribe();
        } else {
          this.memoService.downloadMemo(this.idMemo).subscribe();
        }
      }
    }
    this.documentName = this.fileName;
    this.apiService
      .download(this.downloadUrl ?? this.document)
      .subscribe(
        (res: any) => {
          let blob: Blob;
          if (res.type) {
            blob = new Blob([res], { type: res.type });
          } else {
            blob = new Blob([res]);
          }
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.documentName;
          link.click();
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
  }

  openComment(content, acknowledge): void {
    this.ackComment = '';
    this.acknowledged = acknowledge;
    this.ackModal = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
    });
  }

  acknowledge(): void {
    this.ack.emit({
      acknowledged: this.acknowledged,
      comment: this.ackComment,
    });
    this.ackModal.dismiss();
    this.closePreview();
  }
}
