<div class="option-popup-window text-center"
    (clickOutside)="closeAllSettings($event)"
    [excludeBeforeClick]="true"
    [ngStyle]="getOptionPopupWindowPosition(dragdropDiv)">

  <!----------- copy ---------->
  <div class="box-tools p-0">
    <app-copy-setting-panel-item [markerIdentity]="markerIdentity"
        [marker]="marker"
        [(markers)]="markers"
        (refreshPdf)="sendRefresh()"
        (addRadio)="onAddRadio($event)"></app-copy-setting-panel-item>
  </div>
  <!----------- list ---------->
  <div class="box-tools">
    <app-config-setting-panel-item [markerIdentity]="markerIdentity"
        [marker]="marker"
        [(markers)]="markers"
        (refreshPdf)="sendRefresh()"
        [dragdropDiv]="dragdropDiv"></app-config-setting-panel-item>
  </div>

  <!----------- color ---------->
  <div class="bg-color-skate">
    <div *ngIf="!(markerIdentity.formInputType === 'radio_checkbox' ||
                markerIdentity.formInputType === 'radio')">
      <div class="box-tools">
        <app-color-setting-panel-item [markerIdentity]="markerIdentity"
            [marker]="marker"
            [(markers)]="markers"
            [dragdropDiv]="dragdropDiv"></app-color-setting-panel-item>
      </div>
    </div>
  </div>

  <!----------- delete -------------->
  <div class="box-tools border-bottom-0">
    <app-remove-widget-setting-panel-item [markerIdentity]="markerIdentity"
        [marker]="marker"
        [(markers)]="markers"></app-remove-widget-setting-panel-item>
  </div>
</div>
