<ng-template #createReviewer let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 text-center font-18 font-weight-bold">
      {{ "APPROVAL.ASK-REVIEWER" | translate }}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg-icon src="assets/images/icons/closeX.svg" class="pointer mt-1"
        [svgStyle]="{ 'width.px': 22, 'height.px': 22 }"
        style="margin-top: 5px;"
        (click)="modal.dismiss('Cross click')"></svg-icon>
    </button>
  </div>
  <div class="modal-body pt-0">
    <div class="mt-3">
      {{ "APPROVAL.ASK-REVIEW-DOCUMENT" | translate }}
      </div>
    <div>
      <div class="mt-4">
      <label>{{ "APPROVAL.REVIEWER" | translate }}</label>
        <ng-select [items]="people$ | async"
              class="custom-dropdown"
              bindLabel="label"
              bindValue="value"
              placeholder="{{'MEMOS.SELECT' | translate}}"
              [clearable]="false"
              [(ngModel)]="reviewer"
              [loading]="peopleLoading"
              [typeahead]="peopleInput$"
              [notFoundText]="'SELECT.No items found'|translate"
              [minTermLength]="2"
              [typeToSearchText]="'user.type-search' | translate">
          </ng-select>
      </div>
    </div>

    <div class="mt-5 text-center">
      <button class="btn" style="width: 260px" appSetTheme
        type="button"
        (click)="submit()">
        {{"APPROVAL.CONFIRM-REVIEW" | translate}}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #review let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 text-center font-18 font-weight-bold">
      {{ "APPROVAL.REVIEWER-DOCUMENT" | translate }}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg-icon src="assets/images/icons/closeX.svg" class="pointer"
        [svgStyle]="{ 'width.px': 22, 'height.px': 22 }" (click)="modal.dismiss('Cross click')"
      style="margin-top: 5px;"></svg-icon>
    </button>
  </div>
  <div class="modal-body">
      <label>{{ "APPROVAL.REVIEWER-COMMENT" | translate }}
        <span class="text-red">*</span>
      </label>
      <div class="form-group mt-2">
        <textarea
          class="form-control"
          fz-elastic
          [(ngModel)]="reason">
        </textarea>
      </div>
    <div class="mt-5 text-right">
      <button class="btn mr-2 btn-outline"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="close()">
        {{"APPROVAL.CANCEL" | translate}}
      </button>
      <button class="btn" appSetTheme
        type="button"
        (click)="submitReview()">
        {{"APPROVAL.CONFIRM" | translate}}
      </button>
    </div>
    </div>
</ng-template>
