<div class="loa-table-container">
  <div class="loa-list">
    <div *ngIf="!data"
        class="no-loa text-center">
      <svg-icon src="assets/images/icons/no-loa.svg"
          [svgStyle]="{'width.px': 180, 'height.px': 180}"></svg-icon>
      <div class="text-muted">{{"LOA-TABLE.NO-LOA"|translate}}</div>
    </div>

    <table class="loa-table"
        *ngIf="data">
      <thead>
      <tr class="header">
        <th id="level">LOA</th>
        <th id="name"
            class="text-left">{{"LOA-TABLE.NAME"|translate}}</th>
        <th id="date">{{"LOA-TABLE.DATE-OF-APPROVE"|translate}}</th>
        <th class="text-center">{{"inout.Resend"|translate}}</th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let level of data">
        <ng-container *ngFor="let member of level.members; let i = index;">
          <tr class="body"
              [style.border-bottom]="i === level.members.length-1 ? '2px solid #FAFAFA' : 'none'"
              [style.background-color]="level.statusColor ? background : 'white'">
            <td *ngIf="i === 0"
                style="vertical-align : middle;text-align:center;"
                [rowSpan]="level.members.length">
              {{ level.level }}
            </td>
            <td>
              <div class="f-16">{{ member.full_name }} {{ member.is_delegate ? '(Delegate)' : '' }}</div>
              <div class="f-12 font-weight-light" *ngIf="member.is_custom">({{ member.email }})</div>
              <div class="font-weight-light text-muted f-12">{{ member.position }}</div>
              <ng-container *appFeatureFlag="'ddoc'">
                <div *ngIf="member?.section"
                  class="section">
                  <span>{{member?.section}}</span>
                </div>
              </ng-container>
            </td>
            <td class="text-center align-middle">
              <span *ngIf="!member.date_of_approve && isDrafting(level.level)"
                  style="color: #767676">
                <svg-icon src="assets/images/icons/draft.svg"></svg-icon>
                {{'MEMOS.DRAFT'| translate}}
              </span>
              <div *ngIf="member.date_of_approve">
                <p class="p-0 m-0 text-black">{{ member.date_of_approve | date: 'dd/MM/yyyy' }}</p>
                <p class="text-muted font-weight-light p-0 m-0"
                   style="font-size: 10px">{{ member.date_of_approve | date: 'HH:mm' }}</p>
              </div>
              <span *ngIf="!member.date_of_approve && isPending(level.level)"
                  style="color: #29ADCE">
                <svg-icon src="assets/images/icons/pending-approve.svg"></svg-icon>
                {{'APPROVAL.PENDING'| translate}}
              </span>
            </td>
            <td class="v-middle">
              <div class="d-flex w-100 justify-content-center align-items-center pointer">
                <button *ngIf="!member?.date_of_approve && document?.current_level === level?.level"
                  class="btn-default-none btn-resend"
                  (click)="openResendModal(ResendModalTemplate, member?.id)">
                  <svg-icon [src]="'/assets/images/icons/resend.svg'"></svg-icon>
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #ResendModalTemplate>
  <div class="modal-header">
    <h5 class="m-0 p-0">{{ 'inout.Resend' | translate }}</h5>
    <button type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="row">
      <div class="col-12 text-center pb">
        {{'inout.This function will resend the notification to the selected user.'| translate}}
      </div>
    </div>
    <div class="row justify-content-center px-2 mt-4">
      <button class="btn p-btn mx-2 p-3"
        type="button"
        appSetTheme
        [ladda]="resending"
        (click)="onResend()">
        {{ 'inout.Confirm to Notify' | translate }}
      </button>
    </div>
  </div>
</ng-template>
