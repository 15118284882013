<div class="option-form custom-box">

  <div class="mb-2">
    <label class="mt-2">{{ "UPLOAD.Field Name" | translate }}<span class="text-red">*</span>
    </label>

    <input class="form-control text-charcoal"
        type="text"
        [ngModel]="placeholder"
        [ngClass]="dropdownOptions.duplicateName || !dropdownOptions.fieldName ?'is-invalid':''"
        (keyup)="placeholderChange.emit($event.target.value)"
        (ngModalChange)="setValidate()"
        (keydown.enter)="placeholderChange.emit($event.target.value)"
        (keyup)="onChange($event.target.value, 'fieldName', dropdownOptions)"
        (keydown.enter)="onChange($event.target.value, 'fieldName')">
    <label class="text-red"
        *ngIf="dropdownOptions.duplicateName && dropdownOptions.fieldName">
      {{"UPLOAD.Field Name has already been used"|translate}}
    </label>
    <label class="text-red"
        *ngIf="!dropdownOptions.fieldName">
      {{"UPLOAD.Field Name is required"|translate}}
    </label>
  </div>


  <div class="form-check my-2">
    <input type="checkbox"
        class="form-check-input big-checkbox"
        [ngModel]="markerIdentity.require"
        (ngModelChange)="onChange($event, 'require')">
    <label class="form-check-label ml-2 font-weight-normal f-14">
      {{ "UPLOAD.Required" | translate }}
    </label>
  </div>

  <label class="mt-2">{{ "UPLOAD.Options" | translate }}
    <span class="text-red">*</span>
  </label>
  <div cdkDropList
      [ngClass]="dropdownOptions.items.length === 0 ? 'mt-c-3':''"
      class="options-container"
      #optionsContainer
      (cdkDropListDropped)="movedItem($event)">
    <div cdkDrag
        *ngFor="let item of dropdownOptions.items; let index = index">
      <div [ngClass]="{'mb-2': dropdownOptions.items.length}"
          class="item bg-white">
        <div cdkDragHandle
            class="pointer mr-2">
          <i class="fas fa-bars"
              style="color: #aaa"></i>
        </div>

        <div class="flex-grow-1">
          <input type="text"
              class="w-100 space-input"
              maxlength="100"
              [value]="dropdownOptions.items[index]"
              (change)="updateItem(index, $event)">
        </div>
        <div class="bg-close">
          <div class="btn-remove-item"
              (click)="removeItem(index)">
            <span class="far fa-times"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input class="mb-2 form-control text-charcoal"
      maxlength="100"
      #addChoice
      [placeholder]="'UPLOAD.(Press enter)' | translate"
      type="text"
      (change)="onEnter($event.target.value, addChoice)"
      (keydown.enter)="onEnter($event.target.value, addChoice)">

  <label class="mt-2">{{ "UPLOAD.Default option" | translate }}</label>
  <ng-select class="my-2"
      id="pageStyleSelection"
      [placeholder]="'MEMOS.SELECT' | translate"
      [ngModel]="dropdownOptions.default"
      [items]="dropdownOptions.items"
      [multiple]="false"
      [closeOnSelect]="true"
      (ngModelChange)="onSelectedDefault($event)"></ng-select>
</div>