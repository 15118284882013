import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as _ from 'lodash';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ApiService } from 'src/app/core/http/api.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoService } from '../../../modules/memos/service/memo.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-preview-upload-file',
  templateUrl: './preview-upload-file.component.html',
  styleUrls: ['./preview-upload-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewUploadFileComponent implements OnInit {
  rendering = false;
  modal: NgbModalRef;

  acknowledged: boolean;
  httpHeaders;
  isMobile: boolean;

  _file: File;
  _fileBuffer: Uint8Array;
  @Input() set uploadFile(file: File) {
    this._file = file;
    file.arrayBuffer().then((buff) => {
      this._fileBuffer = new Uint8Array(buff);
    });
  }
  @Input() fileName = 'preview_file';
  @Output() closed = new EventEmitter();

  @ViewChild('content', { static: true }) content;
  documentName: string;

  constructor(
    // private projectService: ProjectService,
    private apiService: ApiService,
    private notification: NotificationService,
    private memoService: MemoService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService,
    private alert: AlertService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.isMobile = this.themeService.isMobile();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.closePreview();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.modal = this.modalService.open(this.content, {
        size: 'lg',
        centered: true,
        windowClass: 'transparent-modal',
        backdrop: 'static',
      });
    }, 100);
  }

  pageRendering(): void {
    this.rendering = true;
  }

  closePreview(): void {
    if (this.modal) {
      this.modal.close();
    }
    this.modal = null;
    this.closed.emit({});
  }

  downloadDocument(): void {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(this._file);
    link.download = this.fileName || this._file.name;
    link.click();
  }
}
