import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { BaseInputComponent } from '../base-input/base-input.component';
import { FormInputMarkerIdentity } from '../form-inputs.model';

@Component({
  selector: 'app-input-textline',
  templateUrl: './input-textline.component.html',
  styleUrls: [
    './input-textline.component.scss',
    '../base-input/base-input.component.scss',
  ],
})
export class InputTextlineComponent
  extends BaseInputComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() markerIdentity: FormInputMarkerIdentity<'text_line'>;
  @Input() fontSize: number; // unit pixel
  @Input() fixedFontSize: number; // unit pixel
  @Input() autoDecreaseFontSize = true;
  @Input() fontFamily: string;
  @Output() fontSizeChange = new EventEmitter<number>();
  @Output() fillTextEnter = new EventEmitter<string>();
  @Input() pdfElementHeight;
  @Input() rowTextarea;
  @Output() rowTextareaChange = new EventEmitter();
  @Input() isUploadTemplate = false;

  @ViewChild('inputTextLine', { static: false })
  inputTextLine: ElementRef;

  @ViewChild('formInputTextareaHidden', { static: false })
  formInputTextareaHidden: ElementRef;

  @ViewChild('formInput')
  formInput: ElementRef | any;

  textOverflow: boolean;
  fontSizeChanges = false;
  showErrorColor = false;
  @Output() sizeTextChange = new EventEmitter<any>();

  canvas;
  canvasCtx: CanvasRenderingContext2D;
  calculateHeight: number;
  heightInputTextLine = null;
  colsTextArea: number;
  textWithoutLineBreak = 0;
  currentLineNumber = 0;
  isFocus = false;
  isRowTextareaChanged = false;

  ngOnInit() {
    this.canvasCtx = document
      .createElement('canvas')
      .getContext('2d');
    if (this._height > 1) {
      this.calculateHeight = this._height;
    } else {
      this.calculateHeight =
        this._height * this.pdfElementHeight?.height;
    }
    if (this._height !== this.calculateHeight && this.rowTextarea) {
      this._height = this.calculateHeight;
    }
    this._height = Math.max(this._height, this.fontSize * 1.1);
    this.calculateHeight = Math.max(
      this.calculateHeight,
      this.fontSize * 1.1,
    );
  }

  ngAfterViewInit() {
    if (this.inputTextLine) {
      this.inputTextLine.nativeElement.value = this.markerIdentity
        ?.data
        ? this.markerIdentity?.data
        : '';
    }
    if (this.formInputTextareaHidden) {
      this.formInputTextareaHidden.nativeElement.value =
        this.inputTextLine.nativeElement.value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowTextarea) {
      this.isRowTextareaChanged = true;
    }
  }

  onResizeStart(): void {
    if (this.isRowTextareaChanged) {
      this.isRowTextareaChanged = false;
    }
  }

  onCalculateHeight(): number {
    if (this.isRowTextareaChanged && this.rowTextarea) {
      return this.rowTextarea * this.fontSize + 6;
    } else if (this._height !== this.calculateHeight) {
      return this._height;
    } else {
      return this.calculateHeight;
    }
  }

  onResizeStop(event: IResizeEvent, markerIdentify?): void {
    // const minHeight = this.calculateMinHeight();
    // if (event.size.height < minHeight) {
    //   event.size.height = minHeight;
    // }
    this.rowTextareaChange.emit(null);
    if (
      markerIdentify?.formInputType === 'radio_checkbox' ||
      markerIdentify?.formInputType === 'radio'
    ) {
      super.onResizeStop(event);
    }
    if (this._height > 1) {
      this.calculateHeight = this._height;
    } else {
      this.calculateHeight =
        this._height * this.pdfElementHeight?.height;
    }
    if (this._height !== this.calculateHeight && this.rowTextarea) {
      this._height = this.calculateHeight;
    }
    // this.calculateRow();
    // this.autoSetMaximumRow();
    // this.setLimitTextArea();
    this.sizeTextChange.emit(event.size);
    super.onResizeStop(event);
  }

  getFontSizePx(size: number): number {
    return Math.round((size / 100) * this.pdfElementHeight.height);
  }

  onTextChange(event: string) {
    // this.setLimitTextArea();
    this.textOverflow = false;
    if (this.formInputTextareaHidden) {
      this.formInputTextareaHidden.nativeElement.value =
        this.inputTextLine.nativeElement.value;
    }

    this.processFormValue();
  }

  processFormValue() {
    if (this.formInput) {
      const formData: any = new FormData(
        this.formInput.nativeElement,
      );
      const search = new URLSearchParams(formData);
      const textFormatted = search.get('textHidden');
      this.limitTextLength(textFormatted);
    }
  }

  limitTextLength(textFormatted) {
    const inputBox = this.formInputTextareaHidden.nativeElement;
    const numberOfLines = countCharInStr(textFormatted, '\n') + 1;
    let reRender = true;
    if (
      numberOfLines > 1 &&
      inputBox.scrollHeight >
        inputBox.clientHeight + 0.6 * this.fontSize &&
      this.autoDecreaseFontSize
    ) {
      inputBox.value = deleteLastChar(inputBox.value);
      this.blinkErrorColor();
      textFormatted = inputBox.value;
      reRender = false;
      setTimeout(() => {
        this.processFormValue();
      }, 10);
    }

    if (reRender) {
      this.markerIdentity.data = textFormatted;
      this.inputTextLine.nativeElement.value = textFormatted;
      this.fillTextEnter.emit(textFormatted);
    }
  }

  getWidth() {
    const hideIconSetting = 24;
    if (!this.canSettingForm && !this.canFillForm) {
      return this._width - 3 + hideIconSetting;
    } else if (this.canFillForm) {
      return this._width + hideIconSetting;
    } else {
      return this._width;
    }
  }

  getFontColor() {
    if (this.showErrorColor) {
      return 'red';
    } else {
      return this.markerIdentity.color;
    }
  }

  blinkErrorColor() {
    this.showErrorColor = true;
    setTimeout(() => {
      this.showErrorColor = false;
    }, 300);
  }
}

function countCharInStr(str, c) {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] == c) {
      result++;
    }
  }
  return result;
}

function deleteLastChar(s: string): string {
  if (!s) {
    return s;
  }
  if (s.length === 0) {
    return s;
  } else {
    return s.slice(0, -1);
  }
}
