<app-base-popup-setting-panel-item
    [markerIdentity]="markerIdentity"
    [dragdropDiv]="dragdropDiv"
    [isOpenPopup]="markerIdentity.isShowToolsSetting"
    (openConfigList)="openColorSketch()"
    xmlns="http://www.w3.org/1999/html">
  <div icon
      class="color-custom"
      [ngStyle]="{'background':markerIdentity.color ?  markerIdentity.color : '#000'}">
  </div>
  <div pop-up
      class="width-color-sketch">
    <color-sketch
        disableAlpha="true"
        [color]="markerIdentity.color ?  markerIdentity.color : '#000'"
        [(ngModel)]="markerIdentity.color"
        (onChangeComplete)="onChange($event.color.hex)">
    </color-sketch>
  </div>
</app-base-popup-setting-panel-item>
