import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionType } from '../confirm-modal/confirm-modal.component';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';
import { AlertService } from '../../../core/services/alert.service';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-reviewer-modal',
  templateUrl: './reviewer-modal.component.html',
  styleUrls: ['./reviewer-modal.component.scss'],
})
export class ReviewerModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() memo;
  @Output() reviewerChange = new EventEmitter();
  @Output() confirm = new EventEmitter();

  @ViewChild('createReviewer', { static: true })
  createReviewer: ElementRef;
  @ViewChild('review', { static: true })
  review: ElementRef;
  @ViewChild('confirmReview', { static: true })
  confirmReview: ElementRef;
  themeList: ThemeList;
  actionType: ActionType;
  textMessage = '';
  userRole: string;
  reviewer: string;
  reason: string;

  // People Dropdown
  people$: Observable<any>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  peopleList = [];

  constructor(
    public modalService: NgbModal,
    private dropdownService: DropdownService,
    private alert: AlertService,
    private translate: TranslateService,
    public themeService: ThemeService,
    private store: Store,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.getPeopleList();
    this.userRole = this.store.selectSnapshot(
      (state) => state.auth.role,
    );
  }
  open(): void {
    this.reviewer = null;
    this.modalService.open(this.createReviewer, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });
  }
  reviewReason(): void {
    this.reason = '';
    this.modalService.open(this.review, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });
  }

  close() {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  getPeopleList(): void {
    this.people$ = concat(
      of(this.peopleList),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params = {
            type: 'people',
            query,
          };
          return this.dropdownService
            .getDropdown(params)
            .pipe(map((obj: any) => obj.people));
        }),
        catchError(() => of([])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  submit(): void {
    if (!this.reviewer) {
      this.alert.error(
        this.translate.instant('MEMOS.PLEASE-SELECT-REVIEWER'),
      );
      return;
    }
    this.modalService.dismissAll();
    this.confirm.emit(this.reviewer);
  }

  submitReview(): void {
    if (!this.reason) {
      this.alert.error(this.translate.instant('MEMOS.PLEASE-REVIEW'));
      return;
    }
    this.modalService.dismissAll();
    this.reviewerChange.emit(this.reason);
  }
}
