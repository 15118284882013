<div [style.font-family]="fontFamily"
    [style.font-size.px]="fontSize"
    [style.line-height.px]="fontSize * 1.1"
    [style.position]="'absolute'"
    [style.opacity]="0"
    [style.pointer-events]="'none'"
    #widthForDate>
    {{previewDate}}
</div>
<div class="container-input position-relative">
    <div #resizeContainer="ngResizable"
        class="resizable-widget position-relative px-0"
        rzHandles="e"
        [style.padding]="'0.1em'"
        [style.font-size.px]="fontSize"
        [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm,
        'margin-left-date-box':checkPositionX, 'custom-mark-require':checkUrlPreview === 'preview' }"
        [ngStyle]="resizableStyle"
        [ngResizable]="canSettingForm"
        [rzAspectRatio]="lockRatio"
        [rzContainment]="containment"
        [style.height]="(_height > 1? _height: _height * this.pdfElementHeight?.height) + 'px'"
        [rzMinHeight]="convertWidth? newSizeBoundary:sizeBoundary?.minHeight"
        [rzMinWidth]="calculateWidthCols"
        [style.width.px]="getWidth()"
        (rzStop)="onResizeStop($event)"
        #sizeBoxDate
        (rzResizing)="onResizing($event)">
        <input type="text"
            class="textline form-control"
            (click)="toggleDatePicker($event)"
            [style.color]="markerIdentity.color"
            [style.font-family]="fontFamily"
            [style.font-size.px]="fontSize"
            [style.line-height.px]="fontSize * 1.1"
            [style.width]="_width !== null ? _width + 'px' : 'auto'"
            [style.height]="_height + 'px'"
            [ngModel]="markerIdentity.data"
            [placeholder]="previewDate?previewDate :translateService.currentLang === 'th' ? 
            markerIdentity.options.placeholderTH : markerIdentity.options.placeholderEN"
            (ngModelChange)="onTextChange($event)"
            readonly="true"
            [ngClass]="{'cursor-default': canFillForm, 'event-none': !canFillForm}">

        <!-- real input calendar -->
        <input type="text"
            class="mydatepicker position-absolute w-100 event-none form-control"
            [style.opacity]="'0'"
            #invf="ngbDatepicker"
            name="datepicker"
            ngbDatepicker
            [style.top]="0"
            autocomplete="off"
            [placement]="checkPlacement()"
            [footerTemplate]="datePopupFooter"
            [disabled]="!canFillForm"
            [ngModel]="markerIdentity.options.rawDate"
            (ngModelChange)="onTextChange($event)">

    </div>
    <div *ngIf="canSettingForm || canFillForm"
        class="">
        <div class="pr-3"
            style="position: relative;"
            [style.top.px]="sizeBoundary?.minHeight >21 ? '5': 'auto'"
            [style.width.px]="30"
            (click)="toggleDatePicker($event)">
            <svg-icon src="assets/svg/calendar.svg"
                [svgStyle]="{'height.px': 20, 'width.px': 20
                     ,'max-height.px': this.checkUrl === 'memos'? heightDate: '', 
                     'max-width.px': this.checkUrl === 'memos'? heightDate: ''}"
                class="tool-icon pointer"></svg-icon>
        </div>
    </div>
</div>

<ng-template #datePopupFooter>
    <div class="w-100 d-flex justify-content-end pt-1 pb-2 px-2">
        <span class="pointer"
            [style.color]="'red'"
            style="line-height: inherit;text-decoration: underline"
            (click)="onClear()">
            {{'UPLOAD.Clear' | translate }}
        </span>
    </div>
</ng-template>