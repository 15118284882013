import { CdkDragStart } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResizedEvent } from 'angular-resize-event';
import * as _ from 'lodash';
import {
  PDFDocumentProxy,
  PDFSource,
  PdfViewerComponent,
} from 'ng2-pdf-viewer';
import { DateFormatService } from 'src/app/shared/service/date-format.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { DropdownItem } from '../../../../../shared/models/common.model';
import {
  DropdownResponse,
  DropdownService,
} from '../../../../../shared/service/dropdown.service';
import { generateUUID } from '../../../../../shared/utils/common.util';
import { NgbThaiDateParserFormatter } from '../../../service/NgDateParser';
import {
  DATE_TYPE_LIST,
  FONT_LIST,
  SIGNATURE_COLOR_LIST,
} from '../../../service/upload-memo.constants';
import {
  Marker,
  MarkerIdentity,
  SymbolType,
} from './pdf-signature-customizer.model';
import { AuthenticationService } from '../../../../../core/authentication/authentication.service';
import {
  FormInputMarkerIdentity,
  FormInputPermission,
  FormInputType,
} from '../form-inputs/form-inputs.model';
import { Router } from '@angular/router';
import {
  ThemeList,
  ThemeService,
} from '../../../../../shared/service/theme.service';
import { featureFlag } from '../../../../../../environments/environment';
import { PdfCustomizeService } from '../../../service/pdf-customize.service';
import { Level } from 'src/app/modules/loa/shared/loa.model';
import { Signer } from '../upload-memo/upload-memo.component';
import { Subscription } from 'rxjs';

export type ChangeType =
  | 'position'
  | 'date_type'
  | 'date_custom'
  | 'date'
  | 'size'
  | 'font'
  | 'color'
  | 'watermark'
  | 'watermark_size'
  | 'form_input_resize'
  | 'form_input_textline_resize'
  | 'form_input_require'
  | 'form_input_placeholder'
  | 'form_input_limitLength'
  | 'form_input_rowTextarea'
  | 'form_input_dropdownOptions'
  | 'form_input_reset'
  | 'form_input_fillTextEnter'
  | 'form_input_selectedDropdown'
  | 'form_input_toggleCheckbox'
  | 'form_input_toggleRadio'
  | 'form_input_requireRadio'
  | 'form_input_radioGroup'
  | 'watermark_opacity'
  | 'removeDragDrop'
  | 'text'
  | 'form_input_number_minValue'
  | 'form_input_number_maxValue';

@Component({
  selector: 'app-pdf-signature-customizer',
  templateUrl: './pdf-signature-customizer.component.html',
  styleUrls: ['./pdf-signature-customizer.component.scss'],
})
export class PdfSignatureCustomizerComponent
  implements OnInit, OnDestroy, OnChanges
{
  /** The absolute size in FHD screen */
  readonly fhdMarkerDefaultHeight = 52.5;
  readonly fhdMarkerDefaultWidth = 216;
  /** The relative size by referring in FHD screen */
  readonly DRAGDROP_DEFAULT_HEIGHT =
    this.fhdMarkerDefaultHeight / 1450;
  readonly DRAGDROP_DEFAULT_WIDTH = this.fhdMarkerDefaultWidth / 1025;
  readonly DRAGDROP_DEFAULT_FONTSIZE = 1.8796992481203008;

  readonly FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT = 30 / 1450;
  readonly FORM_INPUT_CHECKBOX_DEFAULT_WIDTH = 30 / 1025;

  fontList = FONT_LIST;
  dateTypeList = DATE_TYPE_LIST;
  signatureColorList = SIGNATURE_COLOR_LIST;

  watermarkList = [];

  currentPage = 1;
  totalPage: number;
  totalPageDropdown: DropdownItem[] = [{ label: '1', value: 1 }];
  itemPage = [];
  preDragPosition: CoordinatePosition;
  pdf: PDFDocumentProxy;
  pdfError: any = null;
  isPageListSelection = false;
  loadingPage = true;
  originalPageView;
  showLoaMemberList = false;
  textMarkerContentPadding = '0.5em';
  currentMarker: Marker;

  mouseoverSettingBtn = false;
  draggingOverSettingBtn = false;
  mouseoverInputDropdown = false;
  draggingOverInputDropdown = false;
  modal: NgbModalRef;
  canvas: HTMLCanvasElement;
  contextCanvas2d: CanvasRenderingContext2D;

  selectedDragdrop: MarkerIdentity;
  selectedEvent: CdkDragStart;
  selectedName: string;
  selectedChangeType: ChangeType;
  httpHeaders;
  countOpenOption = 0;
  canAddAnnotationInPdf = true;
  formPermission: FormInputPermission = {
    canSetting: false,
    canFillIn: false,
  };
  themeList: ThemeList;
  textDateType: string;

  height;
  width;

  SCALE = 1;

  isDragging: boolean;
  subscription: Subscription[] = [];
  isPdfLoading = true;
  // fix static space for scrollbar because default style it makes pdf viewer width,height calculation problem
  // https://gitlab.c0d1um.io/products/e-memo/memo-bls-new/-/issues/246
  @Input() scrollbarGutterStable = false;
  @Input() allowEdit = true;
  @Input() scaledown = false;
  @Input() pdfPath: PDFSource;
  @Input() memoNumber: string;
  @Input() markers: Marker[];
  @Input() markerMenuDisplay: 'full' | 'short' = 'full';
  @Input() positionKeys = [
    'positions',
    'approved_date_positions',
    'comment_positions',
  ];
  @Input() controlPageFromOutside = false;
  @Input() selectPageFromOutside: number;
  @Input() isInOut = false;
  @Input() isSealDoc = false;
  @Input() loaLevels: Partial<Level<Signer>>[] = [];
  @Output() selectPageFromOutsideChange = new EventEmitter<number>();
  @Output() sendTotalPage = new EventEmitter<number>();
  @Output() sendLoadingPage = new EventEmitter<boolean>();

  @Output() markersChange: EventEmitter<Marker[]> = new EventEmitter<
    Marker[]
  >();

  @Input() checkChangeFile: false;
  @ViewChild('pdfPageListBtn', { static: false })
  pdfPageListBtn: ElementRef;
  @ViewChild('pdfPageList', { static: false })
  pdfPageList: ElementRef;
  @ViewChild('applyChangeConfirmationModal', { static: true })
  applyChangeConfirmationModal: ElementRef;
  @ViewChild('clearAllDataConfirmationModal', { static: true })
  clearAllDataConfirmationModal: ElementRef;
  render = true;

  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent: PdfViewerComponent;
  @ViewChild('pdfView', { static: false })
  private pdfView: ElementRef;

  @Input() isApprovalRequest = false;
  @Input() isUploadTemplate = false;
  @Input() checkPage: string;
  @ViewChild('fontSizeInput') fontSizeInput: ElementRef;
  @Input() isDuplicateName: any;
  @Output() positionPDF = new EventEmitter();
  constructor(
    private renderer: Renderer2,
    private dateFormatService: DateFormatService,
    private dateFormat: NgbThaiDateParserFormatter,
    public translate: TranslateService,
    private modalService: NgbModal,
    private dropdownService: DropdownService,
    private alert: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private themeService: ThemeService,
    private pdfCustomizeService: PdfCustomizeService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.renderer.listen('window', 'click', (e) => {
      if (this.pdfPageList && this.pdfPageListBtn) {
        if (
          e.target !== this.pdfPageListBtn.nativeElement &&
          e.target !== this.pdfPageList.nativeElement
        ) {
          this.isPageListSelection = false;
        }
      }
      if (e.target?.id !== 'btn-select-loa') {
        this.showLoaMemberList = false;
      }
    });
    this.setFormInputPermission();

    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.getWatermarkList();
    if (this.hasLoaMember()) {
      this.currentMarker = this.markers[3];
    }
    this.canvas = document.createElement('canvas');
    this.contextCanvas2d = this.canvas.getContext('2d');
    this.refreshTotalPageDropdown();
    this.sendLoadingPage.emit(this.loadingPage);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.markers) {
      if (this.hasLoaMember()) {
        this.currentMarker = this.markers[3];
      } else {
        this.currentMarker = null;
      }
      this.positionPDF.emit(
        this.pdfView?.nativeElement?.offsetHeight,
      );
      this.refreshScreenPosition();
    }

    if (
      this.controlPageFromOutside &&
      changes.selectPageFromOutside
    ) {
      if (changes.selectPageFromOutside.isFirstChange()) {
        this.pageChangeDelay();
        this.currentPage = changes.selectPageFromOutside.currentValue;
        this.selectPageFromOutsideChange.emit(this.currentPage);
      } else if (
        changes.selectPageFromOutside.currentValue -
          changes.selectPageFromOutside.previousValue ===
        1
      ) {
        this.nextPage();
      } else if (
        changes.selectPageFromOutside.currentValue -
          changes.selectPageFromOutside.previousValue ===
        -1
      ) {
        this.prevPage();
      } else {
        this.changePage(changes.selectPageFromOutside.currentValue);
      }
    }

    this.checkCanAddAnnotationInPdfInAllLevels();
  }

  applyPosition(
    coordinate: CoordinatePosition,
    markerIdentity: MarkerIdentity,
    isRelativeCoordinate = false,
  ): void {
    let relativePosition = coordinate;
    if (!isRelativeCoordinate) {
      relativePosition = this.convertTransformToRelativePos(
        coordinate.x,
        coordinate.y,
      );
    }
    markerIdentity.initialPos = coordinate;
    markerIdentity.X =
      relativePosition.x < 0 ? 0 : relativePosition.x;
    markerIdentity.Y =
      relativePosition.y < 0 ? 0 : relativePosition.y;
    markerIdentity.screenCoordinate = coordinate;
    this.refreshScreenPosition();
  }

  getWatermarkList(): void {
    const type = 'watermark';
    const params = { type };
    const subscription = this.dropdownService
      .getDropdown(params)
      .subscribe(
        (dropdown: DropdownResponse) => {
          this.watermarkList = dropdown.watermark;
        },
        (error: { error: string }) => {
          if (this.translate.currentLang === 'th') {
            this.alert.error('ไม่สามารถโหลดข้อมูลที่ร้องขอได้');
          } else {
            this.alert.error(`Can't get requested data`);
          }
        },
      );
    this.subscription.push(subscription);
  }

  async onLoadComplete(pdf: PDFDocumentProxy): Promise<void> {
    this.pdf = pdf;
    this.currentPage = 1;
    this.selectPageFromOutsideChange.emit(this.currentPage);
    this.totalPage = this.pdf.numPages;
    this.itemPage = [];
    for (let i = 0; i < this.totalPage; i++) {
      this.itemPage.push(i + 1);
    }
    this.sendTotalPage.emit(this.totalPage);
    this.refreshTotalPageDropdown();
    this.loadingPage = false;
    this.sendLoadingPage.emit(this.loadingPage);
    await this.onPageChange();
  }

  async onPdfViewResized(event: ResizedEvent): Promise<void> {
    this.pdfCustomizeService.onPdfViewResized(event);
    await this.pageRendered();
    this.refreshScreenPosition();
  }

  async pageRendered(): Promise<void> {
    if (!this.pdf) {
      this.isPdfLoading = true;
      return;
    }
    const page = await this.pdf.getPage(this.currentPage);
    const originalPageView = page.getViewport({ scale: this.SCALE });
    const scale =
      this.pdfView.nativeElement.clientWidth / originalPageView.width;
    const viewport = page.getViewport({ scale });
    this.height = viewport.height;
    this.width = viewport.width;
    this.isPdfLoading = false;
    this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  onLoaSelect(e: Event): void {
    e.stopImmediatePropagation();
    this.showLoaMemberList = !this.showLoaMemberList;
  }

  pageChangeDelay(): void {
    this.loadingPage = true;
    this.sendLoadingPage.emit(this.loadingPage);
    setTimeout(() => {
      this.loadingPage = false;
      this.sendLoadingPage.emit(this.loadingPage);
    }, 200);
  }

  async prevPage(): Promise<void> {
    this.pageChangeDelay();
    this.currentPage--;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.selectPageFromOutsideChange.emit(this.currentPage);
    await this.onPageChange();
  }

  async nextPage(): Promise<void> {
    this.pageChangeDelay();
    this.currentPage++;
    if (this.currentPage > this.totalPage) {
      this.currentPage = this.totalPage;
    }
    this.selectPageFromOutsideChange.emit(this.currentPage);
    await this.onPageChange();
  }

  async onPageChange() {
    /** Get and store the original page view port */
    const page = await this.pdf.getPage(this.currentPage);
    this.originalPageView = page.getViewport({ scale: 1 });
  }

  calcTextareaHeight(
    text: string,
    markerIdentity: MarkerIdentity,
  ): string {
    const rows = text?.split('\n')?.length || 1;
    const clientHeight =
      rows * this.getLineHeightPx(markerIdentity.fontSize) + 1;
    return `calc(${clientHeight}px + ${this.textMarkerContentPadding} * 2)`;
  }

  async changePage(page: number): Promise<void> {
    this.pageChangeDelay();
    this.currentPage = page;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    } else if (this.currentPage > this.totalPage) {
      this.currentPage = this.totalPage;
    }
    await this.onPageChange();
    this.selectPageFromOutsideChange.emit(this.currentPage);
  }

  showPageList(): void {
    this.isPageListSelection = !this.isPageListSelection;
  }

  onSelectedPage(pageNumber: DropdownItem): void {
    this.pageChangeDelay();
    this.currentPage = pageNumber.value;
    this.selectPageFromOutsideChange.emit(this.currentPage);
    setTimeout(() => (this.isPageListSelection = false), 400);
  }

  onPdfError(error: any): void {
    this.pdfError = error;
  }

  onSelectedLoaMember(selectedMarker: Marker): void {
    this.currentMarker = selectedMarker;
    this.canAddAnnotationInPdf = this.checkCanAddAnnotationInPdf(
      this.currentMarker,
      true,
    );
    setTimeout(() => (this.showLoaMemberList = false), 400);
  }

  onClickSignature(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      W: 150,
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontStyle: this.fontList[0],
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      initialPos: { x: 0, y: 0 },
      enableSetting: false,
      optionalType: 'positions',
      pageList: [this.currentPage],
    };
    const idx = _.findIndex(this.markers, this.currentMarker);
    this.addPosition(identity, idx);
  }

  onClickApprovedDate(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.fhdMarkerDefaultHeight,
      W: this.fhdMarkerDefaultWidth,
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      initialPos: { x: 0, y: 0 },
      enableSetting: false,
      pageList: [this.currentPage],
      dateFormat: this.dateFormatService.defaultFormat,
      dateFormatList:
        this.dateFormatService.constructFormatListFromDate(),
      fontStyle: this.fontList[0],
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      color: this.signatureColorList[0],
      optionalType: 'approved_date_positions',
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
    };
    const idx = _.findIndex(this.markers, this.currentMarker);
    this.addPosition(identity, idx);
  }

  onClickDate(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.fhdMarkerDefaultHeight,
      W: this.fhdMarkerDefaultWidth,
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      dateFormat: this.dateFormatService.defaultFormat,
      dateFormatList:
        this.dateFormatService.constructFormatListFromDate(),
      dateType: this.dateTypeList[0].value,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
    };
    this.addPosition(identity, 1);
  }

  onClickMemoNumber(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.fhdMarkerDefaultHeight,
      W: this.fhdMarkerDefaultWidth,
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
    };
    this.addPosition(identity, 2);
  }

  onClickWatermark(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      W: this.fhdMarkerDefaultWidth,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontStyle: this.fontList[0],
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      initialPos: { x: 0, y: 0 },
      onEdit: false,
      watermarkPic: this.watermarkList.length
        ? this.watermarkList[0].value
        : null,
      watermarkOpacity: 0.2,
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
    };
    this.addPosition(identity, 0);
  }

  onClickSymbol(symbol: SymbolType): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(
        this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
        true,
      ),
      W: this.convertToOriginalWidth(
        this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
        true,
      ),
      height: this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
      width: this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      onEdit: false,
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      symbol,
    };
    this.addPosition(identity);
  }

  onClickCustomWrite(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(this.DRAGDROP_DEFAULT_WIDTH),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      string: '',
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
    };
    this.addPosition(identity);
  }

  onClickFormInputTextLine(): void {
    const fieldNameIndex = this.getFieldName('text_line', 'textbox');
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;
    const identity: FormInputMarkerIdentity<'text_line'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(
        this.DRAGDROP_DEFAULT_WIDTH,
        true,
      ),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'text_line',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        parentId: countParent,
        fieldName: fieldName,
        limitLength: null,
        rowTextarea: null,
        fixedFontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
        duplicateName: false,
      },
    };
    this.addPosition(identity);
  }

  resetTextLine(textLine: FormInputMarkerIdentity<'text_line'>) {
    textLine.options.fieldName = null;
    textLine.options.limitLength = null;
  }

  onClickFormInputTextDropdown(): void {
    const fieldNameIndex = this.getFieldName(
      'text_dropdown',
      'dropdown',
    );
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;
    const identity: FormInputMarkerIdentity<'text_dropdown'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(
        this.DRAGDROP_DEFAULT_WIDTH,
        true,
      ),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'text_dropdown',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        items: [],
        default: null,
        parentId: countParent,
        fieldName: fieldName,
        fixedFontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
        duplicateName: false,
      },
    };
    this.addPosition(identity);
  }

  onClickFormInputDate(): void {
    const fieldNameIndex = this.getFieldName('date', 'datebox');
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;
    const identity: FormInputMarkerIdentity<'date'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(0.15052732502396932, true),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'date',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        parentId: countParent,
        fieldName: fieldName,
        limitLength: null,
        fixedFontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
        duplicateName: false,
        placeholderEN: 'Select Date',
        placeholderTH: 'เลือกวันที่',
        dateFormat: 'EN:%-d/%-m/%Y',
        rawDate: null,
      },
    };
    this.addPosition(identity);
  }

  onClickFormInputNumber(): void {
    const fieldNameIndex = this.getFieldName('number', 'numberbox');
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;
    const identity: FormInputMarkerIdentity<'number'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(
        this.DRAGDROP_DEFAULT_WIDTH,
        true,
      ),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'number',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        parentId: countParent,
        fieldName: fieldName,
        limitLength: null,
        rowTextarea: 1,
        fixedFontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
        duplicateName: false,
        placeholderTH: 'ตัวเลข',
        placeholderEN: 'Number',
        minValue: null,
        maxValue: null,
        validateMinMax: false,
        validateMinMaxSetting: false,
        minError: false,
        maxError: false,
      },
    };
    this.addPosition(identity);
  }

  resetTextDropdown(
    textDropdown: FormInputMarkerIdentity<'text_dropdown'>,
  ) {
    textDropdown.options.items = [];
    textDropdown.options.default = null;
    textDropdown.options.fieldName = null;
  }

  getFieldName(
    formInputType: FormInputType,
    prefix: string,
  ): { fieldName: string; index: number } {
    let count = 0;
    this.markers.forEach((marker) => {
      if (marker.name === 'custom') {
        marker.positions.forEach(
          (position: FormInputMarkerIdentity<'radio'>) => {
            if (position.formInputType === formInputType) {
              if ((position.options?.parentId || 0) > count) {
                count = position.options?.parentId || 0;
              }
            }
          },
        );
      }
    });
    count = count + 1;
    return {
      fieldName: prefix + '-' + count + this.makeSuffix(3),
      index: count,
    };
  }

  getCurrentValue(fieldName) {
    let countValue = 0;
    this.markers.forEach((marker) => {
      if (marker.name === 'custom') {
        marker.positions.forEach(
          (position: FormInputMarkerIdentity<'radio'>) => {
            if (position.options?.fieldName === fieldName) {
              if (position.options?.fieldValueId > countValue) {
                countValue = position.options?.fieldValueId;
              }
            }
          },
        );
      }
    });
    return countValue;
  }

  makeSuffix(length: number): string {
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    const randomIndexes = new Uint32Array(length);
    crypto.getRandomValues(randomIndexes);

    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        randomIndexes[i] % charactersLength,
      );
    }
    return result;
  }

  onClickFormInputCheckbox(): void {
    const fieldNameIndex = this.getFieldName(
      'radio_checkbox',
      'checkbox',
    );
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;

    const countValue = this.getCurrentValue(fieldName) + 1;
    const fieldValue =
      fieldName + '-' + countValue + this.makeSuffix(3);

    const identity: FormInputMarkerIdentity<'radio_checkbox'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(
        this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
        true,
      ),
      W: this.convertToOriginalWidth(
        this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
        true,
      ),
      height: this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
      width: this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'radio_checkbox',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        parentId: countParent,
        fieldValueId: countValue,
        fieldName: fieldName,
        fieldValue: fieldValue,
        duplicateValue: false,
      },
    };
    this.addPosition(identity);
  }

  onClickFormInputRadio(): void {
    const fieldNameIndex = this.getFieldName('radio', 'radio');
    const countParent = fieldNameIndex.index;
    const fieldName = fieldNameIndex.fieldName;

    const countValue = this.getCurrentValue(fieldName) + 1;
    const fieldValue =
      fieldName + '-' + countValue + this.makeSuffix(3);

    const identity: FormInputMarkerIdentity<'radio'> = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(
        this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
        true,
      ),
      W: this.convertToOriginalWidth(
        this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
        true,
      ),
      height: this.FORM_INPUT_CHECKBOX_DEFAULT_HEIGHT,
      width: this.FORM_INPUT_CHECKBOX_DEFAULT_WIDTH,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'positions',
      formInputType: 'radio',
      formInputId: generateUUID(),
      data: null,
      require: false,
      disable: true,
      options: {
        parentId: countParent,
        fieldValueId: countValue,
        fieldName: fieldName,
        fieldValue: fieldValue,
        duplicateValue: false,
      },
    };
    this.addPosition(identity);
  }

  resetRadio(radio: FormInputMarkerIdentity<'radio'>) {
    radio.options.fieldName = '';
  }

  onClickClear(): void {
    this.modal = this.modalService.open(
      this.clearAllDataConfirmationModal,
      {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      },
    );
    this.modal.result
      .then((res) => {
        if (res === 'true') {
          this.clearSignaturePositionsData();
          return;
        }
        return;
      })
      .catch(() => {
        return;
      });
  }

  onCommentOptionClick(): void {
    const identity: MarkerIdentity = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      H: this.convertToOriginalHeight(this.DRAGDROP_DEFAULT_HEIGHT),
      W: this.convertToOriginalWidth(this.DRAGDROP_DEFAULT_WIDTH),
      height: this.DRAGDROP_DEFAULT_HEIGHT,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      string: null,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: { x: 0, y: 0 },
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(
        this.signatureColorList[0],
      ),
      enableSetting: false,
      pageList: [this.currentPage],
      optionalType: 'comment_positions',
    };
    const idx = _.findIndex(this.markers, this.currentMarker);
    this.addPosition(identity, idx);
  }

  clearSignaturePositionsData(): void {
    if (!this.markers) {
      return;
    }
    this.markers = this.markers
      .filter((item) => item.sequence !== -1)
      .map((item) => {
        return {
          ..._.cloneDeep(item),
          positions: null,
          approved_date_positions: null,
          comment_positions: null,
        } as Marker;
      });
    this.markersChange.emit(this.markers);
  }

  getDragDropClass(item: Marker, dragdrop: MarkerIdentity): string {
    let dragdropClass = null;
    switch (item.type) {
      case 'date':
        dragdropClass = 'dragdrop-date';
        break;
      case 'memo_number':
        dragdropClass = 'dragdrop-memonumber';
        break;
      case 'custom':
        dragdropClass = 'dragdrop-custom';
        break;
      case 'watermark':
        dragdropClass = 'dragdrop-watermark';
        break;
      case 'signature':
        dragdropClass = 'dragdrop-signature';
        break;
      default:
        if (dragdrop.optionalType === 'approved_date_positions') {
          dragdropClass = 'dragdrop-approved-date';
        } else if (dragdrop.optionalType === 'comment_positions') {
          dragdropClass = 'dragdrop-comment';
        } else {
          dragdropClass = 'dragdrop-signature';
        }
        break;
    }
    return dragdropClass;
  }

  hasLoaMember(): boolean {
    if (!this.markers) {
      return false;
    }
    if (this.markers.length < 4) {
      // signature position start at index 2
      return false;
    }
    // no word undefined in name and not custom
    return (
      this.markers[3].name.indexOf('undefined') === -1 &&
      this.markers[3].type !== 'custom'
    );
  }

  filterOnlySignatures(): Marker[] {
    return this.markers.filter((item) => {
      return (
        item.type !== 'date' &&
        item.type !== 'memo_number' &&
        item.type !== 'watermark' &&
        item.type !== 'custom'
      );
    });
  }

  addPosition(markerIdentity: MarkerIdentity, index?: number): void {
    if (
      index >= 3 &&
      this.markers[index]?.ddoc_enable &&
      this.markers[index]?.positions?.length > 0 &&
      !['comment_positions', 'approved_date_positions'].includes(
        markerIdentity.optionalType,
      )
    ) {
      this.alert.error(
        this.translate.instant(
          'CERTIFICATE.PUT-SIGNATURE-1-POSITION-ONLY',
        ),
      );
      return;
    }
    const id = generateUUID();
    const page = markerIdentity.page;
    if (index === null || index === undefined) {
      // for custom text
      if (
        this.markers.findIndex(
          (marker) => marker.name === 'custom',
        ) === -1
      ) {
        this.markers.push({
          sequence: -1,
          name: 'custom',
          positions: [],
          type: 'custom',
        });
      }
      index = this.markers.length - 1;
    }
    if (!markerIdentity.optionalType) {
      markerIdentity.optionalType = 'positions';
    }
    const markerIdentities =
      this.markers[index][markerIdentity.optionalType] || [];
    // Must be replaced with new array to make the reference change at `positionFilterByPage` pipe
    this.markers[index][markerIdentity.optionalType] = [
      ...markerIdentities,
      { ...markerIdentity, id, pageIdList: [{ page, id }] },
    ];
    this.markersChange.emit(this.markers);
  }

  removeDragDrop(name: string, targetIdentity: MarkerIdentity): void {
    if (!targetIdentity.optionalType) {
      targetIdentity.optionalType = 'positions';
    }
    let targetMarkers: Marker[];
    if (name === 'watermark') {
      targetMarkers = [this.markers[0]];
    } else if (name === 'date') {
      targetMarkers = [this.markers[1]];
    } else if (name === 'memo number') {
      targetMarkers = [this.markers[2]];
    } else if (name === 'custom') {
      targetMarkers = this.markers.filter(
        (marker) => (marker.type || marker.name) === 'custom',
      );
    } else {
      // for signature type
      targetMarkers = this.markers.filter(
        (marker, index) =>
          index >= 3 && (marker.type || marker.name) !== 'custom',
      );
    }
    // Execute deletion
    let matchedMarker: Marker;
    targetMarkers.forEach((targetMarker) => {
      const identityIndex = targetMarker[
        targetIdentity.optionalType
      ]?.findIndex((identity) => identity === targetIdentity);
      if (identityIndex == null || identityIndex === -1) {
        return;
      }
      matchedMarker = targetMarker;
      targetMarker[targetIdentity.optionalType].splice(
        identityIndex,
        1,
      );
    });
    if (
      matchedMarker &&
      matchedMarker[targetIdentity.optionalType].length > 0
    ) {
      // update `pageList` and `pageIdList` of the position in `matchedMarker`
      targetIdentity.pageList = targetIdentity.pageList.filter(
        (p) => p !== targetIdentity.page,
      );
      targetIdentity.pageIdList = targetIdentity.pageIdList.filter(
        (p) => p.id !== targetIdentity.id,
      );
      const positions = matchedMarker[targetIdentity.optionalType];
      this.updateRelatePage(targetIdentity, positions);
    }
    this.markersChange.emit(this.markers);
  }

  openMultiApplyModal(): void {
    const changeTypeNotAllow = ['watermark_opacity'];
    if (changeTypeNotAllow.includes(this.selectedChangeType)) return;

    this.modal = this.modalService.open(
      this.applyChangeConfirmationModal,
      {
        backdrop: 'static',
        centered: true,
      },
    );

    this.modal.result
      .then((res) => {
        if (res === 'multi') {
          this.changeMultiplePage();
          return;
        }
        this.applyChange(
          this.selectedEvent,
          this.selectedDragdrop,
          this.selectedChangeType,
        );
      })
      .catch(() => {});
  }

  onDragStart(dragdrop: MarkerIdentity): void {
    this.preDragPosition = this.getMarkerPosition(dragdrop);
    this.isDragging = true;
  }

  onDragEnded(
    event: CdkDragStart,
    targetIdentity: MarkerIdentity,
  ): void {
    const transform = event.source.getFreeDragPosition();
    this.applyPosition(transform, targetIdentity);
    if (this.modal) {
      this.modal.close();
    }
  }

  onChange(
    targetIdentity: MarkerIdentity,
    event: any,
    name: string,
    changeType: ChangeType,
    action?: string,
  ): void {
    if (!targetIdentity.dateCustomBE) {
      this.currentDate(targetIdentity);
    }
    this.selectedChangeType = changeType;
    this.selectedDragdrop = targetIdentity;
    this.selectedEvent = event;
    this.selectedName = name;
    if (
      targetIdentity.pageList.length == 1 ||
      changeType !== 'removeDragDrop'
    ) {
      this.applyChange(event, targetIdentity, changeType, action);
    }
    if (targetIdentity.pageList.length > 1) {
      this.openMultiApplyModal();
    }
  }

  changeMultiplePage(): void {
    const changeTypeNotAllow = ['watermark_opacity'];
    if (changeTypeNotAllow.includes(this.selectedChangeType)) return;

    this.applyChange(
      this.selectedEvent,
      this.selectedDragdrop,
      this.selectedChangeType,
    );
    if (!this.selectedDragdrop.optionalType) {
      this.selectedDragdrop.optionalType = 'positions';
    }

    let targetPosition;
    if (this.isFormInputType(this.selectedDragdrop)) {
      targetPosition = this.filterSameFormInputIdFromMarkerList(
        this.selectedName,
        this.selectedDragdrop as FormInputMarkerIdentity,
      );
    } else {
      targetPosition = this.markers.find(
        (x) => x.name === this.selectedName,
      )[this.selectedDragdrop.optionalType];
    }

    this.selectedDragdrop.pageIdList.forEach((id) => {
      const targetChangeDropdown = targetPosition.find(
        (x) => x.id === id.id,
      );
      this.applyChange(
        this.selectedEvent,
        targetChangeDropdown,
        this.selectedChangeType,
      );
    });
    if (this.modal) {
      this.modal.close();
    }
  }

  applyChange(
    event: any,
    targetIdentity: MarkerIdentity,
    changeType: ChangeType,
    action?: string,
  ): void {
    switch (changeType) {
      case 'position':
        this.onDragEnded(event, targetIdentity);
        break;
      case 'date':
        this.onSelectedDateFormat(targetIdentity, event);
        break;
      case 'date_type':
        this.onSelectedDateType(targetIdentity, event);
        break;
      case 'date_custom':
        this.onSelectedDateCustom(targetIdentity, event);
        break;
      case 'size':
        this.updateFontSize(targetIdentity, event);
        break;
      case 'font':
        this.onChangeFontStyle(targetIdentity, event);
        break;
      case 'color':
        this.onSelectedColor(targetIdentity, event);
        break;
      case 'text':
        this.onChangeText(targetIdentity, event);
        break;
      case 'watermark':
        this.onChangeWatermark(targetIdentity, event);
        break;
      case 'watermark_size':
        this.onChangeWatermarkSize(targetIdentity, event);
        break;
      case 'watermark_opacity':
        this.onChangeWatermarkOpacity(targetIdentity, event);

        const targetPosition = this.markers.find(
          (x) => x.name === this.selectedName,
        )[targetIdentity.optionalType];
        targetIdentity.pageIdList.forEach((id) => {
          const target = targetPosition.find((x) => x.id === id.id);
          this.onChangeWatermarkOpacity(target, event);
        });
        break;
      case 'form_input_resize':
        this.onChangeFormInputResize(targetIdentity, event);
        break;
      case 'form_input_textline_resize':
        this.onChangeFormInputResize(targetIdentity, event, false);
        break;
      case 'form_input_require':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'require',
        );
        break;
      case 'form_input_placeholder':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'fieldName',
          true,
        );
        this.validateFieldName(
          targetIdentity as FormInputMarkerIdentity,
          'fieldName',
          action,
        );
        break;
      case 'form_input_limitLength':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'limitLength',
          true,
        );
        break;
      case 'form_input_rowTextarea':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'rowTextarea',
          true,
        );
        break;
      case 'form_input_dropdownOptions':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'options',
          false,
        );
        break;
      case 'form_input_reset':
        this.onResetFormInputSetting(
          targetIdentity as FormInputMarkerIdentity,
        );
        break;
      case 'form_input_fillTextEnter':
      case 'form_input_selectedDropdown':
      case 'form_input_toggleCheckbox':
        this.onChangeFormInputField(
          targetIdentity as FormInputMarkerIdentity,
          event as never,
          'data',
          false,
        );
        break;
      case 'form_input_toggleRadio':
        this.onRadioToggle(
          event.fieldName,
          event.data,
          targetIdentity as FormInputMarkerIdentity,
        );
        break;
      case 'form_input_requireRadio':
        this.onRadioRequireChange(
          event,
          targetIdentity as FormInputMarkerIdentity,
        );
        break;
      case 'form_input_radioGroup':
        if (!action) {
          this.onChangeFormInputField(
            targetIdentity as FormInputMarkerIdentity,
            event as never,
            'fieldName',
            true,
          );
        } else {
          this.onChangeFormInputField(
            targetIdentity as FormInputMarkerIdentity,
            event as never,
            'fieldValue',
            true,
          );
        }
        this.validateFieldName(
          targetIdentity as FormInputMarkerIdentity,
          'fieldName',
          action,
        );
        this.updateGroupName(
          targetIdentity as FormInputMarkerIdentity,
          'fieldValue',
          'fieldName',
          action,
        );
        break;
      case 'removeDragDrop':
        this.removeDragDrop(this.selectedName, targetIdentity);
        break;
    }
    if (this.modal) {
      this.modal.close();
    }
  }

  onChangeText(position: MarkerIdentity, event: any): void {
    const regexp = new RegExp('(\n\u200b$)');
    const isNewLineReg = new RegExp('(\n$)');
    if (
      regexp.test(position.string) &&
      isNewLineReg.test(event) &&
      position.string.length > event.length
    ) {
      event = event.replace(/\n$/g, '');
    }
    if (isNewLineReg.test(event)) {
      event = event + '\u200b';
    }
    position.string = event;
  }

  onChangeWatermark(position: MarkerIdentity, event: any): void {
    const identity: MarkerIdentity = {
      page: position.page,
      X: position.X,
      Y: position.Y,
      W: this.fhdMarkerDefaultWidth,
      width: this.DRAGDROP_DEFAULT_WIDTH,
      fontStyle: this.fontList[0],
      initialPos: position.initialPos,
      screenCoordinate: position.screenCoordinate,
      onEdit: true,
      watermarkPic: event,
      enableSetting: true,
      pageList: position.pageList,
      optionalType: 'positions',
      watermarkOpacity: position.watermarkOpacity,
      zIndex: position.zIndex,
    };
    // remove - add to resize watermark to initial every time watermarkPic changes
    this.removeDragDrop('watermark', position);
    this.addPosition(identity, 0);
  }

  onChangeWatermarkSize(position: MarkerIdentity, event: any): void {
    const height = event.height;
    const width = event.width;
    position.height = this.convertToRelativeHeight(height);
    position.width = this.convertToRelativeWidth(width);
    position.H = height;
    position.W = width;
  }

  round(n: number): number {
    return Math.round(n);
  }

  onChangeWatermarkOpacity(
    position: MarkerIdentity,
    event: any,
  ): void {
    if (!event) return;
    if (typeof event === 'number') {
      position.watermarkOpacity = event;
      return;
    }
    position.watermarkOpacity = event.color.rgb.a;
  }

  onChangeFormInputResize(
    position: MarkerIdentity,
    event: any,
    adjustFont = true,
  ): void {
    const height = event.height;
    const width = event.width;
    position.height = this.convertToRelativeHeight(height);
    position.width = this.convertToRelativeWidth(width);
    position.H = height;
    position.W = width;

    if (adjustFont) {
      // auto adjust font size to fit with height
      const fontSizePx = Math.round(
        (height - 6.5) * this.currentWidthScale,
      );
      this.updateFontSize(position, fontSizePx);
    }
  }

  onChangeFormInputField(
    position: FormInputMarkerIdentity,
    event: never,
    field: string,
    isOption = false,
  ) {
    if (isOption) {
      position.options[field] = event;
    } else {
      position[field] = event;
    }
  }

  onChangeFontStyle(
    markerIdentity: MarkerIdentity,
    event: any,
  ): void {
    markerIdentity.fontStyle = event;
  }

  convertTransformToRelativePos(
    transformX: number,
    transformY: number,
  ): { x: number; y: number } {
    return {
      x: (transformX / this.pdfElementWidth) * 100,
      y: (transformY / this.pdfElementHeight) * 100,
    };
  }

  /** Convert a sample to the percentage of original PDF height */
  convertToRelativeHeight(sampleHeight: number): number {
    return sampleHeight / this.pdfElementHeight;
  }

  /** Convert a sample to the percentage of original PDF width */
  convertToRelativeWidth(sampleWidth: number): number {
    return sampleWidth / this.pdfElementWidth;
  }

  /** Convert coordinate in the percentile to absolute coordinate on screen dimension. */
  convertToScreenPos(
    position: CoordinatePosition,
  ): CoordinatePosition {
    return {
      x: position.x * this.pdfElementWidth * 0.01 || 0,
      y: position.y * this.pdfElementHeight * 0.01 || 0,
    };
  }

  convertToScreenMarkerHeight(
    markerIdentity: MarkerIdentity,
  ): number {
    return (
      (markerIdentity.height ||
        markerIdentity.H / this.originalPageView.height ||
        this.DRAGDROP_DEFAULT_HEIGHT) * this.pdfElementHeight
    );
  }

  convertToScreenMarkerWidth(markerIdentity: MarkerIdentity): number {
    return (
      (markerIdentity.width ||
        markerIdentity.W / this.originalPageView.width ||
        this.DRAGDROP_DEFAULT_WIDTH) * this.pdfElementWidth
    );
  }

  /** Convert a element height with original PDF scale */
  convertToOriginalHeight(
    elementHeight: number,
    isRelative = false,
  ): number {
    if (isRelative) {
      return (
        elementHeight * (this.originalPageView.height / this.SCALE)
      );
    }
    return elementHeight / this.currentHeightScale;
  }

  /** Convert a element width with original PDF scale */
  convertToOriginalWidth(
    elementWidth: number,
    isRelative = false,
  ): number {
    if (isRelative) {
      return (
        elementWidth * (this.originalPageView.width / this.SCALE)
      );
    }
    return elementWidth / this.currentWidthScale;
  }

  getDragDropInfo(
    marker: Marker,
    markerIdentity?: MarkerIdentity,
  ): string {
    let info = '';
    switch (marker.type) {
      case 'date':
        info = this.dateFormatService.getDateLabelWithDate(
          markerIdentity.dateCustom,
          markerIdentity.dateFormat,
        );
        if (markerIdentity.dateType === 'published_at') {
          this.textDateType = this.translate.instant(
            'PDF.PUBLISH_DATE',
          );
        } else if (markerIdentity.dateType === 'finished_at') {
          this.textDateType =
            this.translate.instant('PDF.FINISH_DATE');
        } else if (markerIdentity.dateType === 'custom_date') {
          this.textDateType =
            this.translate.instant('PDF.CUSTOM_DATE');
        }
        break;
      case 'memo_number':
        if (this.memoNumber) {
          info = this.memoNumber.toString();
        } else {
          info = marker.name;
        }
        break;
      case 'custom':
        break;
      case 'signature':
        break;
      default:
        if (
          markerIdentity.optionalType === 'approved_date_positions'
        ) {
          info = this.dateFormatService.getDateLabelWithDate(
            markerIdentity.dateCustom,
            markerIdentity.dateFormat,
          );
        } else if (
          markerIdentity.optionalType === 'comment_positions'
        ) {
          break;
        } else {
          info = marker.name;
        }
        break;
    }
    return info;
  }

  getMarkerPosition(
    markerIdentity: MarkerIdentity,
  ): CoordinatePosition {
    return { x: markerIdentity.X, y: markerIdentity.Y };
  }

  getOptionPopupWindowPosition(
    markerIdentity: MarkerIdentity,
    dragdropDiv: HTMLDivElement,
  ): {
    top: string;
    left: string;
    bottom: string;
    right: string;
  } {
    const Y_THRESHOLD = this.pdfElementHeight * 0.7; //  70% of pdf height used for toolbox fix above pdf document
    const x_init = markerIdentity.initialPos.x;
    const y_init = markerIdentity.initialPos.y;
    const w_dragdrop = dragdropDiv.offsetWidth;
    const h_dragdrop = dragdropDiv.offsetHeight;

    const position = {
      left: undefined,
      right: undefined,
      bottom: undefined,
      top: undefined,
    };
    const percent_w = w_dragdrop / this.pdfElementWidth; //find percent size of watermark size for pdf
    //watermark 60% for pdf
    if (percent_w > 0.6 || x_init <= 0) {
      position.left = `${w_dragdrop + 5}px`;
    } else if (y_init + h_dragdrop > Y_THRESHOLD) {
      position.bottom = `${h_dragdrop + 5}px`;
    } else {
      position.top = `${h_dragdrop + 5}px`;
    }

    return position;
  }

  getFontSizePx(size: number): number {
    return Math.round((size / 100) * this.pdfElementHeight);
  }

  getLineHeightPx(fontSize: number): number {
    return this.getFontSizePx(fontSize) * 1.2;
  }

  getFontStyle(style: { name: string; value: string }): string {
    if (!style) {
      return this.fontList[0].value;
    }
    return style.value;
  }

  getTextNoUndefined(text: string): string {
    if (!text) {
      return '';
    }
    return text;
  }

  refreshTotalPageDropdown(): void {
    this.totalPageDropdown = new Array(this.totalPage)
      .fill(1)
      // tslint:disable-next-line:variable-name
      .map((_val, i) => ({
        label: (i + 1).toString(),
        value: i + 1,
      }));
  }

  refreshScreenPosition(): void {
    if (!this.originalPageView) {
      return;
    }
    const setSelfScreenCoordinate = (
      markerIdentities: MarkerIdentity[],
    ): MarkerIdentity[] => {
      return markerIdentities.map((markerIdentity) => {
        markerIdentity.screenCoordinate = this.convertToScreenPos({
          x: markerIdentity.X,
          y: markerIdentity.Y,
        });
        return markerIdentity;
      });
    };
    this.markers.forEach((marker: any) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          marker[key] = setSelfScreenCoordinate(marker[key]);
        }
      });
      marker.zIndex = 0;
    });
  }

  updateTextareaWidthPx(markerIdentity: MarkerIdentity): number {
    const DEFAULT = 80;

    if (
      !markerIdentity.fontSize ||
      !markerIdentity.fontStyle ||
      !markerIdentity.string
    ) {
      return DEFAULT;
    }

    const fontSizePx = this.getFontSizePx(markerIdentity.fontSize);
    const fontStyle = markerIdentity.fontStyle.value;
    const textSplitNewLine = markerIdentity.string.split('\n');
    const textMaxLength = textSplitNewLine.reduce((text, max) => {
      return text.length > max.length ? text : max;
    });

    this.contextCanvas2d.font = `${fontSizePx}px ${fontStyle}`;
    const metrics = this.contextCanvas2d.measureText(textMaxLength);
    return Math.max(metrics.width + fontSizePx, DEFAULT);
  }

  updateTextareaHeightPx(dragdrop: MarkerIdentity): number {
    if (!dragdrop.string) {
      return this.getLineHeightPx(dragdrop.fontSize);
    }

    const textSplitNewLine = dragdrop.string.split('\n');
    return (
      textSplitNewLine.length *
      this.getLineHeightPx(dragdrop.fontSize)
    );
  }

  dragdropGroupTrackByFunc(_index: number, item: Marker): Marker {
    return item;
  }

  dragdropTrackByFunc(_index: number, item: MarkerIdentity): string {
    return item.id;
  }

  onSelectedColor(
    markerIdentity: MarkerIdentity,
    color: string,
  ): void {
    markerIdentity.color = color;
    markerIdentity.colorForRender =
      this.convertColorToRenderFormat(color);
  }

  onClickDragDropSetting(
    markerIdentity: MarkerIdentity,
    marker: Marker,
  ): void {
    if (this.draggingOverSettingBtn) {
      this.draggingOverSettingBtn = false;
      return;
    }
    if (
      marker?.comment_positions !== undefined &&
      marker?.comment_positions?.length > 0
    ) {
      marker.comment_positions.forEach((position) => {
        position.isShowToolsMenuList = false;
        position.isShowToolsSetting = false;
      });
    }
    if (
      marker?.approved_date_positions !== undefined &&
      marker?.approved_date_positions?.length > 0
    ) {
      marker.approved_date_positions.forEach((position) => {
        position.isShowToolsMenuList = false;
        position.isShowToolsSetting = false;
      });
    } else {
      marker.positions?.forEach((position) => {
        position.isShowToolsMenuList = false;
        position.isShowToolsSetting = false;
      });
    }

    const enableSetting = !markerIdentity.enableSetting;
    this.closeAllSettings(markerIdentity);
    markerIdentity.enableSetting = enableSetting;
    if (markerIdentity.enableSetting) {
      this.countOpenOption += 1;
      marker.zIndex = 99 + this.countOpenOption;
      markerIdentity.zIndex = 99 + this.countOpenOption;
    } else {
      marker.zIndex = 0;
      markerIdentity.zIndex = 0;
    }
  }

  onMouseEnterSettingBtn() {
    this.mouseoverSettingBtn = true;
  }

  onMouseLeaveSettingBtn() {
    this.mouseoverSettingBtn = false;
    this.draggingOverSettingBtn = false;
  }

  onMouseEnterInputDropdown() {
    this.mouseoverInputDropdown = true;
  }

  onMouseLeaveInputDropdown() {
    this.mouseoverInputDropdown = false;
    this.draggingOverInputDropdown = false;
  }

  checkDraggingOverOnDragEnded() {
    if (this.isDragging && this.mouseoverSettingBtn) {
      this.draggingOverSettingBtn = true;
    }
    if (this.isDragging && this.mouseoverInputDropdown) {
      this.draggingOverInputDropdown = true;
    }
    this.isDragging = false;
  }

  confirmSetting(
    markerIdentity: MarkerIdentity,
    marker: Marker,
  ): void {
    markerIdentity.enableSetting = false;
    marker.zIndex = 0;
    markerIdentity.zIndex = 0;
    this.countOpenOption += -1;
  }

  convertColorToRenderFormat(color: string): {
    r: number;
    g: number;
    b: number;
  } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      color,
    );
    return result
      ? {
          r: parseInt(result[1], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          b: parseInt(result[3], 16) / 255,
        }
      : null;
  }

  updateFontSize(
    markerIdentity: MarkerIdentity,
    sizePx: number,
  ): void {
    markerIdentity.fontSize = (sizePx / this.pdfElementHeight) * 100;
    if (
      this.isFormInputType(markerIdentity) &&
      this.formPermission.canSetting
    ) {
      // fontSize สามารถ เพิ่มลดลงได้ตามความยาวของข้อความ ตอนที่ fill form
      // fixedFontSize จะเก็บค่าเริ่มต้นที่ set จาก template
      const formInputMarker = markerIdentity as any;
      if (
        formInputMarker.options &&
        'fixedFontSize' in formInputMarker.options
      ) {
        formInputMarker.options.fixedFontSize =
          formInputMarker.fontSize;
      }
    }
  }

  isSelectedAllPage(dragdrop: MarkerIdentity): boolean {
    return dragdrop.pageList.length === this.itemPage.length;
  }

  handleSelectedAllPage(
    markerIdentity: MarkerIdentity | any,
    event: any,
    name: string,
  ): void {
    let targetPosition;
    if (this.isFormInputType(markerIdentity)) {
      targetPosition = this.filterSameFormInputIdFromMarkerList(
        name,
        markerIdentity,
      );
    } else {
      targetPosition = this.markers.find((x: any) => x.name === name)[
        markerIdentity.optionalType
      ];
    }
    if (event) {
      const beforeChange = _.cloneDeep(markerIdentity.pageList);
      markerIdentity.pageList = this.itemPage;
      for (const page of this.itemPage.filter(
        (p: any) => !beforeChange.includes(p),
      )) {
        this.addPage(page, markerIdentity, targetPosition);
      }
    } else {
      markerIdentity.pageList = [];
      for (const page of this.itemPage) {
        this.deletePage(page, markerIdentity, targetPosition, name);
      }
    }
  }

  updatePages(
    markerIdentity: MarkerIdentity | FormInputMarkerIdentity | any,
    event: any,
    name: string | any,
    type: 'add' | 'remove',
  ): void {
    if (!markerIdentity.optionalType) {
      markerIdentity.optionalType = 'positions';
    }
    let targetPosition: any = [];
    if (this.isFormInputType(markerIdentity)) {
      targetPosition = this.filterSameFormInputIdFromMarkerList(
        name,
        markerIdentity,
      );
    } else {
      targetPosition = this.markers.find((x: any) => x.name === name)[
        markerIdentity.optionalType
      ];
    }
    return type === 'add'
      ? this.addPage(event, markerIdentity, targetPosition)
      : this.deletePage(
          event.value,
          markerIdentity,
          targetPosition,
          name,
        );
  }

  addPage(
    page,
    markerIdentity: MarkerIdentity | FormInputMarkerIdentity,
    sourceIdentities: MarkerIdentity[] | FormInputMarkerIdentity[],
  ): void {
    const id = generateUUID();
    if (page !== this.currentPage) {
      const newDragdrop = _.cloneDeep(markerIdentity);
      newDragdrop.page = page;
      newDragdrop.id = id;
      newDragdrop.enableSetting = false;
      sourceIdentities.push(newDragdrop);
      markerIdentity.pageIdList.push({ page, id });
      this.updateRelatePage(markerIdentity, sourceIdentities);
    }
  }

  deletePage(
    page: any,
    markerIdentity: MarkerIdentity | any,
    sourceIdentities: MarkerIdentity[] | any,
    name: string,
  ): void {
    const target = markerIdentity.pageIdList.find(
      (x: any) => x.page === page,
    );
    if (target) {
      const targetId = target.id;
      const targetDeletedDropdown = sourceIdentities.find(
        (x: any) => x.id === targetId,
      );
      this.removeDragDrop(name, targetDeletedDropdown);
      markerIdentity.pageIdList = markerIdentity.pageIdList.filter(
        (p: any) => p !== target,
      );
      this.updateRelatePage(markerIdentity, sourceIdentities);
    }
  }

  /**
   * Copy the `pageList` and `pageIdList` in `prototypeIdentity`
   * to the destination identity which is in the list.
   * @param prototypeIdentity a prototype identity will be consumed.
   * @param sourceIdentities a group of identities which is searched and assigned value from prototype identity.
   */
  updateRelatePage(
    prototypeIdentity: MarkerIdentity | FormInputMarkerIdentity | any,
    sourceIdentities: MarkerIdentity[] | FormInputMarkerIdentity[],
  ): void {
    prototypeIdentity.pageIdList.forEach((pageIdObj: any) => {
      // Find a identity referred in the prototype identity
      const referredPosition: any = sourceIdentities.find(
        (position) => position.id === pageIdObj.id,
      );
      referredPosition.pageList = prototypeIdentity.pageList;
      referredPosition.pageIdList = prototypeIdentity.pageIdList;
    });
  }

  onSelectedDateFormat(
    markerIdentity: MarkerIdentity,
    data: any,
  ): void {
    markerIdentity.dateFormat = data;
  }

  onSelectedDateType(
    markerIdentity: MarkerIdentity | any,
    data: any,
  ): void {
    markerIdentity.dateType = data;
    if (data !== 'custom_date') {
      markerIdentity.dateCustom = null;
      markerIdentity.dateCustomBE = null;
      markerIdentity.dateFormatList =
        this.dateFormatService.constructFormatListFromDate();
    }
  }

  onSelectedDateCustom(
    markerIdentity: MarkerIdentity,
    data: any,
  ): void {
    markerIdentity.dateCustom = this.dateFormat.parse(data);
    markerIdentity.dateFormatList =
      this.dateFormatService.constructFormatListFromDate(
        markerIdentity.dateCustom,
      );
  }

  getPositionFilterByPage(
    markerPositions: MarkerIdentity[],
  ): MarkerIdentity[] {
    const pageFiltered = markerPositions?.filter(
      (position) => position?.page === this.currentPage,
    );
    if (featureFlag.self_service_form) {
      return pageFiltered;
    } else {
      return pageFiltered?.filter(
        (position) => !position?.formInputType,
      );
    }
  }

  checkIfDocumentIsString(): boolean {
    return _.isString(this.pdfPath?.url);
  }

  closeAllSettings(markerIdentities: any, event?: any): void {
    if (event) {
      // because [exclude] is not working properly, we need to add this custom logic:
      const target = event.target as HTMLElement;
      const classList = target.classList;
      if (
        // ng-select option clicked
        classList.contains('ng-option') ||
        classList.contains('ng-option-label') ||
        // or dropped option (in SSF dropdown)
        target.tagName === 'BODY' ||
        // or delete option (in SSF dropdown)
        classList.contains('btn-remove-item') ||
        classList.contains('fa-times')
      ) {
        return;
      }
    }

    this.countOpenOption = 0;
    if (markerIdentities) {
      markerIdentities.zIndex = 0;
    }
    this.markers.forEach((marker: any) => {
      marker.zIndex = 0;
      if (marker.positions) {
        marker.positions.forEach((position: any) => {
          position.enableSetting = false;
          position.zIndex = 0;
        });
      }
      if (marker.approved_date_positions) {
        marker.approved_date_positions.forEach((position: any) => {
          position.enableSetting = false;
        });
      }
      if (marker.comment_positions) {
        marker.comment_positions.forEach((position: any) => {
          position.enableSetting = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.currentPage = 1;
    this.selectPageFromOutsideChange.emit(this.currentPage);
    if (this.allowEdit && !this.checkChangeFile) {
      this.clearSignaturePositionsData();
    }
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  /** @returns the percentage of the width element to the width of original PDF. */
  get currentHeightScale(): number | any {
    try {
      return (
        this.pdfElementWidth /
        (this.originalPageView.width / this.SCALE)
      );
    } catch (error) {
      console.error(error);
    }
  }

  /** @returns the percentage of the width element to the width of original PDF. */
  get currentWidthScale(): number | any {
    try {
      return (
        this.pdfElementWidth /
        (this.originalPageView.width / this.SCALE)
      );
    } catch (error) {
      console.error(error);
    }
  }

  isFormInputType(
    markerIdentity: MarkerIdentity | FormInputMarkerIdentity,
  ) {
    return 'formInputType' in markerIdentity;
  }

  toggleCustomColor(marker: MarkerIdentity) {
    marker.showCustomColor = !marker.showCustomColor;
  }

  setFormInputPermission() {
    const url = this.router.url;
    if (url.includes('template/create')) {
      this.formPermission.canFillIn = false;
      this.formPermission.canSetting = true;
    } else if (url.includes('memos/upload')) {
      this.formPermission.canFillIn = true;
      this.formPermission.canSetting = false;
    } else {
      this.formPermission.canFillIn = false;
      this.formPermission.canSetting = false;
    }
  }

  showSettingButton(marker: MarkerIdentity) {
    return this.isFormInputType(marker)
      ? this.formPermission.canSetting
      : this.allowEdit;
  }

  disableDragDrop(marker: MarkerIdentity) {
    return this.isFormInputType(marker)
      ? !this.formPermission.canSetting
      : !this.allowEdit;
  }

  onAddRadioToGroup(
    radioGroupId: string,
    marker:
      | FormInputMarkerIdentity<'radio'>
      | FormInputMarkerIdentity<'radio_checkbox'>,
  ) {
    const clonedWidget = _.cloneDeep(marker);
    const shiftPositionWidget = (originalPosition: any) =>
      originalPosition + 0.5;
    clonedWidget.initialPos = {
      x: shiftPositionWidget(marker.initialPos?.x),
      y: shiftPositionWidget(marker.initialPos?.y),
    };
    const countValue = this.getCurrentValue(radioGroupId) + 1;
    const fieldValue =
      radioGroupId + '-' + countValue + this.makeSuffix(3);
    const newMarker = {
      ...marker,
      X: shiftPositionWidget(marker.X),
      Y: shiftPositionWidget(marker.Y),
      initialPos: {
        x: clonedWidget.initialPos.x,
        y: clonedWidget.initialPos.y,
      },
      pageList: [this.currentPage],
      formInputType: marker.formInputType,
      formInputId: generateUUID(),
      enableSetting: false,
      data: null,
      options: {
        parentId: marker.options.parentId,
        fieldValueId: countValue,
        fieldName: radioGroupId,
        fieldValue: fieldValue,
      },
    };
    delete newMarker.id;
    delete newMarker.pageIdList;
    delete newMarker.screenCoordinate;
    this.addPosition(newMarker);
    this.refreshScreenPosition();
  }

  processRadioGroup(
    groupId: string,
    page: number,
    type: 'radio' | 'radio_checkbox',
    processFnc: (
      radioInput:
        | FormInputMarkerIdentity<'radio'>
        | FormInputMarkerIdentity<'radio_checkbox'>,
    ) => void,
  ) {
    this.markers
      .filter((marker: any) => marker.name === 'custom')
      .forEach((marker: any) => {
        marker.positions.forEach((identity: any) => {
          if (!this.isFormInputType(identity)) {
            return;
          }
          if (identity.formInputType !== type) {
            return;
          }
          if (identity.page !== page) {
            return;
          }
          const radioInput =
            identity as FormInputMarkerIdentity<'radio'>;
          if (radioInput.options.fieldName !== groupId) {
            return;
          }
          processFnc(radioInput);
        });
      });
  }

  onRadioToggle(
    groupId: string,
    data: string,
    targetRadio:
      | FormInputMarkerIdentity<'radio'>
      | FormInputMarkerIdentity<'radio_checkbox'>,
  ) {
    targetRadio.data = data;
    this.processRadioGroup(
      groupId,
      targetRadio.page,
      targetRadio.formInputType,
      (radioInput) => {
        const clearRadioCondition =
          targetRadio.data &&
          radioInput.options.fieldName === groupId &&
          radioInput.page === targetRadio.page &&
          radioInput.formInputType === 'radio' &&
          radioInput.formInputId !== targetRadio.formInputId;
        // debugger
        if (clearRadioCondition) {
          radioInput.data = null;
        }
      },
    );
  }

  onRadioRequireChange(
    require: boolean,
    targetRadio:
      | FormInputMarkerIdentity<'radio'>
      | FormInputMarkerIdentity<'radio_checkbox'>,
  ) {
    targetRadio.require = require;
    this.processRadioGroup(
      targetRadio.options.fieldName,
      targetRadio.page,
      targetRadio.formInputType,
      (radioInput) => {
        radioInput.require = targetRadio.require;
      },
    );
  }

  onResetFormInputSetting(formInputMarker: FormInputMarkerIdentity) {
    this.resetGeneralSetting(formInputMarker);
    switch (formInputMarker.formInputType) {
      case 'text_line':
        this.resetTextLine(formInputMarker);
        break;
      case 'text_dropdown':
        this.resetTextDropdown(formInputMarker);
        break;
      case 'radio':
        this.resetRadio(formInputMarker);
        break;
      default:
        break;
    }
  }

  resetGeneralSetting(formInput: FormInputMarkerIdentity) {
    formInput.fontStyle = this.fontList[0];
    formInput.color = this.signatureColorList[0];
    formInput.colorForRender = this.convertColorToRenderFormat(
      this.signatureColorList[0],
    );
    formInput.require = false;
  }

  filterSameFormInputIdFromMarkerList(name: any, targetInput: any) {
    let formInputs;
    this.markers.forEach((marker: any) => {
      if (marker.name !== name) {
        return;
      }
      const isSameFormId = (
        marker[targetInput.optionalType] || []
      ).find((formInput: FormInputMarkerIdentity) => {
        return formInput.formInputId === targetInput.formInputId;
      });
      if (isSameFormId) {
        formInputs = marker[targetInput.optionalType];
      }
    });
    return formInputs;
  }

  updateGroupName(
    targetIdentity?: any,
    field?: string | any,
    group?: string | any,
    action?: string,
  ): void {
    this.markers.forEach((marker: any) => {
      if (marker.name === 'custom') {
        let duplicate: any = [];
        marker.positions.forEach((position: any) => {
          if (
            position.formInputType === targetIdentity.formInputType &&
            position.options[group] === targetIdentity.options[group]
          ) {
            duplicate.push(position.options[field]);
          }
        });
        duplicate = this.findDuplicateName(duplicate);
        marker.positions = marker.positions.map((position: any) => {
          if (
            position.formInputType === targetIdentity.formInputType &&
            position.options[group] === targetIdentity.options[group]
          ) {
            position.options.duplicateValue = duplicate.includes(
              position.options[field],
            );
            return position;
          } else {
            return position;
          }
        });
      }
    });
  }

  validateFieldName(
    targetIdentity?,
    field?: string,
    action?: string,
  ): void {
    this.markers.forEach((marker: any) => {
      if (marker.name === 'custom') {
        let duplicate: any = [];
        const checkDuplicateRadio: any = [];
        const checkDuplicateCheckbox: any = [];
        marker.positions.forEach((position: any) => {
          if (
            position.formInputType === 'radio' &&
            position.options
          ) {
            checkDuplicateRadio.push(position.options[field]);
          } else if (
            position.formInputType === 'radio_checkbox' &&
            position.options
          ) {
            checkDuplicateCheckbox.push(position.options[field]);
          } else {
            duplicate.push(position.options?.fieldName);
          }
        });
        const radio = this.filterDuplicate(checkDuplicateRadio);
        const checkbox = this.filterDuplicate(checkDuplicateCheckbox);
        const sumFieldName = [...radio, ...checkbox, ...duplicate];
        duplicate = this.findDuplicateName(sumFieldName);
        marker.positions = marker.positions.map((position: any) => {
          if (position.options) {
            position.options.duplicateName = duplicate.includes(
              position.options[field],
            );
            return position;
          } else {
            return position;
          }
        });
      }
    });
  }

  filterDuplicate(value: any): string[] {
    let i;
    const len = value.length;
    const out = [];
    const obj: any = {};
    for (i = 0; i < len; i++) {
      obj[value[i]] = 0;
    }
    for (i in obj) {
      out.push(i);
    }
    return out;
  }

  findDuplicateName(fieldName: any): string[] {
    if (fieldName.length > 1) {
      const uniq = fieldName
        .map((name: any) => {
          return {
            count: 1,
            name: name,
          };
        })
        .reduce((result: any, b: any) => {
          result[b.name] = (result[b.name] || 0) + b.count;

          return result;
        }, {});
      const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
      return duplicates;
    }
    return [];
  }

  widgetTextErrorMsg(markerIdentity: any) {
    const msg = [];
    if (this.isDuplicateName) {
      if (markerIdentity.options.fieldName === '') {
        msg.push(
          this.translate.instant('UPLOAD.Field Name is required'),
        );
      }
      if (
        markerIdentity.options?.duplicateName &&
        markerIdentity.options.fieldName !== ''
      ) {
        msg.push(
          this.translate.instant('UPLOAD.Duplicate Field Names'),
        );
      }
      if (
        markerIdentity.formInputType === 'text_dropdown' &&
        markerIdentity.options?.items?.length === 0
      ) {
        msg.push(
          this.translate.instant(
            'MEMOS.SSF.DROPDOWN.ITEMS-IS-REQUIRED',
          ),
        );
      }
      if (
        (markerIdentity.formInputType === 'radio' ||
          markerIdentity.formInputType === 'radio_checkbox') &&
        markerIdentity.options.fieldValue === ''
      ) {
        msg.push(
          this.translate.instant('UPLOAD.Field Value is required'),
        );
      }
    }
    return msg.join(', ');
  }

  bgForInputFormRadioCheckbox(markerIdentify: any): string {
    const formInputType = markerIdentify.formInputType;
    if (
      formInputType === 'radio' ||
      formInputType === 'radio_checkbox'
    ) {
      if (this.isDuplicateName) {
        switch (formInputType) {
          case 'radio':
          case 'radio_checkbox':
            if (
              markerIdentify.options.fieldValue === '' ||
              markerIdentify.options.fieldName === ''
            ) {
              return 'bg-transparent-radio-error';
            }
            break;
        }
      }
      return 'bg-transparent-radio';
    }
    return '';
  }

  bgForInputFormInput(markerIdentify: any): string {
    const formInputType = markerIdentify.formInputType;
    const options = markerIdentify.options;
    const duplicateNameAndOptions =
      this.isDuplicateName && markerIdentify.options?.duplicateName;

    if (options) {
      if (this.isDuplicateName) {
        switch (formInputType) {
          case 'text_line':
          case 'date':
            if (
              markerIdentify.options.fieldName === '' ||
              duplicateNameAndOptions
            ) {
              return 'dragdrop-self-service-dropdown';
            }
            break;
          case 'text_dropdown':
            if (
              markerIdentify.options.fieldName === '' ||
              duplicateNameAndOptions
            ) {
              return 'dragdrop-self-service-dropdown';
            }
            if (markerIdentify.options?.items?.length === 0) {
              return 'dragdrop-self-service-dropdown';
            }
            if (markerIdentify.options?.duplicateName) {
              return 'dragdrop-self-service-dropdown';
            }
            break;
          case 'number':
            if (
              markerIdentify.options.fieldName === '' ||
              markerIdentify.options.maxError ||
              duplicateNameAndOptions
            ) {
              return 'dragdrop-self-service-number';
            }
            break;
        }
      }
      return 'dragdrop-self-service';
    }
    return '';
  }

  openMultiDeleteModal(): void {
    this.modal = this.modalService.open(
      this.applyChangeConfirmationModal,
      {
        backdrop: 'static',
      },
    );

    this.modal.result
      .then((res) => {
        if (res === 'multi') {
          this.changeMultiplePage();
          return;
        }
        this.applyChange(
          this.selectedEvent,
          this.selectedDragdrop,
          this.selectedChangeType,
        );
      })
      .catch(() => {});
  }

  deleteMultiplePage(): void {}

  get isUsingDdoc(): boolean {
    return (
      this.markers.filter((obj: any) => obj.ddoc_enable).length !== 0
    );
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  currentDate(markerIdentity: MarkerIdentity): void {
    const date = new Date();
    this.calculateDate(date, markerIdentity);
  }

  calculateDate(newDate: any, markerIdentity: MarkerIdentity): void {
    const today =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate();
    markerIdentity.dateCustomBE = today;
  }

  // this function will indicate that we can add annotation in pdf or not.
  checkCanAddAnnotationInPdf(currentMarker: any, emitChange: any) {
    let canAddAnnotation = true;

    // this.currentMarker = null means that user does not select loa.
    if (currentMarker == null) {
      canAddAnnotation = false;
    }

    // if seal doc is enabled and current level use sign by or certify by.
    // user cannot add annotations in pdf because sealing pdf cannot have annotations in pdf.
    if (this.isSealDoc && currentMarker?.ddoc_enable) {
      canAddAnnotation = false;
    }

    // if previous level in loa use ddoc certify mode, then current level cannot have annotation in pdf.
    if (currentMarker != null) {
      const currentLevelIndex = currentMarker.level - 1;
      for (let index = 0; index < this.loaLevels.length; index++) {
        const level = this.loaLevels[index];
        if (level.ddoc_enable && index < currentLevelIndex) {
          for (const member of level.members) {
            if (member.ddoc_certified_mode) {
              canAddAnnotation = false;
            }
          }
        }
      }
    }

    // if found that user cannot add annotation in pdf, clear approve date and comment box.
    if (currentMarker != null && !canAddAnnotation) {
      currentMarker.approved_date_positions = null;
      currentMarker.comment_positions = null;

      if (emitChange) {
        this.markersChange.emit(this.markers);
      }
    }

    return canAddAnnotation;
  }

  checkCanAddAnnotationInPdfInAllLevels() {
    // this function will make sure that if user change from [sign, sign] to
    //    [certify, sign], system have to remove annottion in second loa level.
    const markerList = this.filterOnlySignatures();
    let needEmitChanges = false;
    for (const marker of markerList) {
      const canAddAnnotation = this.checkCanAddAnnotationInPdf(
        marker,
        false,
      );
      if (!canAddAnnotation) {
        needEmitChanges = true;
      }
    }

    this.canAddAnnotationInPdf = this.checkCanAddAnnotationInPdf(
      this.currentMarker,
      false,
    );
    if (!this.canAddAnnotationInPdf) {
      needEmitChanges = true;
    }

    if (needEmitChanges) {
      this.markersChange.emit(this.markers);
    }
  }

  setDragDropOnTop(marker: any) {
    // clear all zIndex
    this.markers.forEach((markerType: any) => {
      if (markerType?.positions?.length > 0) {
        markerType.positions.forEach((m: any) => {
          m.zIndex = 0;
        });
      }
      if (markerType?.approved_date_positions?.length > 0) {
        markerType?.approved_date_positions.forEach((m: any) => {
          m.zIndex = 0;
        });
      }

      if (markerType?.comment_positions?.length > 0) {
        markerType?.comment_positions.forEach((m: any) => {
          m.zIndex = 0;
        });
      }
    });
    marker.zIndex = 99;
  }

  disabledOnWheel(event: WheelEvent) {
    event.preventDefault();
    this.fontSizeInput?.nativeElement.blur();
  }

  restrictToThreeDigits(event: any) {
    const inputValue = event.target.value;
    if (inputValue > 100) {
      event.target.value = 100;
    }
  }

  validateMinMax(event: any) {
    if (!event.data) {
      event.options.validateMinMax = false;
      return;
    }

    const num = Number(event.data.replace(/,/g, ''));

    const minValue = event.options?.minValue;
    if ((minValue || minValue === 0) && num < minValue) {
      event.options.validateMinMax = true;
      return;
    }

    const maxValue = event.options?.maxValue;
    if ((maxValue || minValue === 0) && num > maxValue) {
      event.options.validateMinMax = true;
      return;
    }

    event.options.validateMinMax = false;
  }

  onKeyPressNonNegative(event: KeyboardEvent | any): boolean {
    const char = event.charCode;
    const isNumeric = char >= 48 && char <= 57;
    const isDecimalPoint = char === 46;
    const isBackspace = char === 8;

    return isBackspace || isNumeric || isDecimalPoint;
  }
  get pdfElementHeight(): number {
    return this.pdfCustomizeService.pdfElementHeight;
  }

  get pdfElementWidth(): number {
    return this.pdfCustomizeService.pdfElementWidth;
  }
}

export interface CoordinatePosition {
  x: number;
  y: number;
}
