import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ThemeList } from '../../../../../../../shared/service/theme.service';

@Component({
  selector: 'app-input-radio-setting',
  templateUrl: './input-radio-setting.component.html',
  styleUrls: ['./input-radio-setting.component.scss'],
})
export class InputRadioSettingComponent {
  @Input() require: boolean;
  @Output() requireChange = new EventEmitter<boolean>();

  @Input() placeholder: string;
  @Output() placeholderChange = new EventEmitter<boolean>();

  @Input() groupId: string;
  @Output() groupIdChange = new EventEmitter<number>();

  @Output() addRadio = new EventEmitter<string>();

  @Input() markerIdentity;

  @Input() markers;
  @Input() themeList: ThemeList;

  @Input() fieldValue: string;
  @Output() fieldValueChange = new EventEmitter();

  @Input() options;
  @Output() optionsChange = new EventEmitter();

  get numberInGroup() {
    let customMarker = this.markers.filter(
      (marker) => marker.name == 'custom',
    );
    if (customMarker) {
      customMarker = customMarker[0];
      return (
        customMarker?.positions.filter(
          (position) =>
            position.formInputType ===
              this.markerIdentity.formInputType &&
            position.options?.fieldName === this.groupId,
        ).length || 0
      );
    }
    return 0;
  }

  onChange(value, field: string) {
    if (field === 'require') {
      let customMarker = this.markers.filter(
        (marker) => marker.name == 'custom',
      );
      if (customMarker) {
        customMarker = customMarker[0];
        return customMarker?.positions.map((position) => {
          if (
            position.options?.fieldName ===
            this.markerIdentity.options.fieldName
          ) {
            position.require = value;
          }
        });
      }
      return;
    }
    this.markerIdentity.options[field] = value;
  }

  setValidate() {
    this.options.duplicateName = false;
  }
}
