import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from '../../../service/memo.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

export interface LoaTableDataRow {
  level: number;
  members: LoaTableDataMember[];
  statusColor?: string;
}

export interface LoaTableDataMember {
  full_name: string;
  position: string;
  date_of_approve: string;
  is_delegate: string;
  email: string;
  is_custom: boolean;
  section?: any;
  evidence?: any;
}

@Component({
  selector: 'app-loa-table',
  templateUrl: './loa-table.component.html',
  styleUrls: ['./loa-table.component.scss'],
})
export class LoaTableComponent implements OnChanges {
  resending = false;
  resendMemberId = null;

  @Input() document: any;
  @Input() loaGroup: [];
  @Input() data: LoaTableDataRow[];
  @Input() background = '#f5f5f5';
  @Input() isContract = false;
  loading = true;

  constructor(
    private modalService: NgbModal,
    private memoService: MemoService,
    private alertService: AlertService,
  ) {}

  ngOnChanges(event) {
    if (event.loaGroup) {
      this.loaGroup = event.loaGroup.currentValue;
    }
  }

  isPending(level: number): boolean {
    return (
      this.document.status === 'pending' &&
      level >= this.document.current_level
    );
  }

  isDrafting(level: number): boolean {
    return (
      this.document.status === 'draft' &&
      level >= this.document.current_level
    );
  }

  openResendModal(ref, memberId) {
    this.modalService.open(ref, {
      centered: true,
      keyboard: false,
    });
    this.resendMemberId = memberId;
  }

  closeModal() {
    this.resendMemberId = null;
    this.modalService.dismissAll();
  }

  onResend() {
    this.resending = true;
    this.memoService
      .assigneeResendNotify(this.document.id, this.resendMemberId)
      .subscribe({
        next: () => {
          this.resending = false;
          this.resendMemberId = null;
          this.alertService.success();
          this.closeModal();
        },
        error: () => {
          this.resending = false;
          this.resendMemberId = null;
          this.alertService.error();
        },
      });
  }

  isM9(section: any) {
    return section === 9;
  }

  isCompanyCert(section: any) {
    if (typeof section !== 'string') {
      return false;
    }
    return section.includes('Company');
  }

  checkEvidence(member): boolean {
    if (member?.otp_required) {
      return true;
    }
    if (member?.selfie_video_required) {
      return true;
    }
    if (member?.has_request_attachments) {
      return true;
    }
  }
}
