import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  Marker,
  MarkerIdentity,
  MarkerType,
} from '../../pdf-signature-customizer.model';
import * as _ from 'lodash';
import { generateUUID } from 'src/app/shared/utils/common.util';

@Component({
  selector: 'app-copy-setting-panel-item',
  templateUrl: './copy-setting-panel-item.component.html',
  styleUrls: ['./copy-setting-panel-item.component.scss'],
})
export class CopySettingPanelItemComponent {
  @Input() markerIdentity;
  @Input() marker: Marker;

  @Input() markers: Marker[];
  @Output() markersChange = new EventEmitter();

  @Output() addRadio = new EventEmitter<string>();
  @Output() refreshPdf = new EventEmitter();
  constructor() {}

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.markerIdentity.enableSetting = false;
    this.markerIdentity.isShowToolsMenuList = false;
    if (
      this.markerIdentity.formInputType === 'radio' ||
      this.markerIdentity.formInputType === 'radio_checkbox'
    ) {
      this.addRadio.emit(this.markerIdentity.options.fieldName);
      return;
    }
    const clonedWidget = _.cloneDeep(this.markerIdentity);
    const shiftPositionWidget = (originalPosition) => {
      return originalPosition + 0.5;
    };
    clonedWidget.initialPos = {
      x: shiftPositionWidget(this.markerIdentity.initialPos?.x),
      y: shiftPositionWidget(this.markerIdentity.initialPos?.y),
    };
    clonedWidget.screenCoordinate = {
      x: shiftPositionWidget(this.markerIdentity.screenCoordinate?.x),
      y: shiftPositionWidget(this.markerIdentity.screenCoordinate?.y),
    };
    clonedWidget.X = shiftPositionWidget(this.markerIdentity.X);
    clonedWidget.Y = shiftPositionWidget(this.markerIdentity.Y);
    const words = clonedWidget.options.fieldName.split('-');
    clonedWidget.options.fieldName =
      words[0] + '-' + this.makeSuffix(3);
    this.addPosition(clonedWidget, this.marker.type);
  }

  makeSuffix(length: number): string {
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    const randomIndexes = new Uint32Array(length);
    crypto.getRandomValues(randomIndexes);

    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        randomIndexes[i] % charactersLength,
      );
    }
    return result;
  }

  addPosition(
    markerIdentity: MarkerIdentity,
    type: MarkerType,
  ): void {
    const id = generateUUID();
    let index = this.markers.findIndex(
      (marker) => marker.type === type,
    );
    if (index === -1) {
      this.markers.push({
        positions: [],
        type,
      });
      index = this.markers.length - 1;
    }
    const markerIdentities = this.markers[index].positions || [];
    // Must be replaced with new array to make the reference change at `positionFilterByPage` pipe
    this.markers[index].positions = [
      ...markerIdentities,
      { ...markerIdentity, id },
    ];
    this.refreshPdf.emit();
    this.markersChange.emit(this.markers);
  }
}
