<div class="row no-gutters flex-md-nowrap panel-container">
  <div *ngIf="allowEdit"
      class="panel-options"
      [ngClass]="{
        'col col-md-3': markerMenuDisplay !== 'short',
        'col-auto': markerMenuDisplay === 'short'
      }">
    <div class="d-flex align-items-center px-3 pt-4 pb-3">
      <div class="topic-list"
          [class.short]="markerMenuDisplay === 'short'">
        <label *ngIf="markerMenuDisplay !== 'short'">
          {{ 'UPLOAD.LOA-MEMBERS' | translate }}
        </label>
        <button *ngIf="markerMenuDisplay !== 'short'; else btnSelectLoaIcon"
            class="topic-selection px-0"
            id="btn-select-loa"
            type="button"
            [disabled]="!hasLoaMember()"
            (click)="onLoaSelect($event)">
          <label class="tools-list-topic mb-0">
            <ng-container *ngIf="!currentMarker; else selectedLoa">
              <span>{{ 'UPLOAD.LOA-MEMBERS' | translate }}</span>
            </ng-container>
            <ng-template #selectedLoa>
              <span>
                {{ currentMarker.name | loaNameThai : (translate.currentLang !== 'th') }}
              </span>
            </ng-template>
          </label>
          <span class="arrow-dropdown">&#9660;</span>
        </button>
        <ng-template #btnSelectLoaIcon>
          <button class="btn btn-white btn-circle-option topic-selection-icon"
              id="btn-select-loa-icon"
              type="button"
              [disabled]="!hasLoaMember()"
              (click)="onLoaSelect($event)">
            <span>LOA</span>
          </button>
        </ng-template>
        <div *ngIf="showLoaMemberList && hasLoaMember()"
            class="topic-dropdown-list">
          <p *ngFor="let item of filterOnlySignatures(); let i = index"
              [ngClass]="{ 'active': item === currentMarker }"
              (click)="onSelectedLoaMember(item)">
            {{ item.name | loaNameThai : (translate.currentLang !== 'th') }}
          </p>
        </div>
      </div>
    </div>

    <!--    Signing-->
    <div class="px-3 pt-3 pb-1 marker-option-group-name">
      <span>{{ "UPLOAD.Signing" | translate }}</span>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option"
          [class.disabled]="!currentMarker?.signatureRequire">
        <button *ngIf="currentMarker?.ddoc_use_28 && currentMarker?.m28_signature_preview === 'loading'"
            #loadingSignatureOptionBtn
            class="btn btn-white btn-circle-option"
            title="{{ 'UPLOAD.LOADING-M28-CERT' | translate }}"
            type="button">
          <span class="fad fa-hourglass-half"></span>
        </button>
        <button *ngIf="!(currentMarker?.ddoc_use_28 && currentMarker?.m28_signature_preview === 'loading')"
            #signatureOptionBtn
            class="btn btn-white btn-circle-option"
            title="{{ 'UPLOAD.LAY-DOWN-SIGNATURE-MARKER' | translate }}"
            type="button"
            (click)="onClickSignature()"
            [disabled]="!currentMarker?.signatureRequire"
            [ngClass]="{ 'disabled': !currentMarker?.signatureRequire}">
          <span class="fas fa-pen-alt"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            [class.disabled]="!currentMarker?.signatureRequire ||
              (currentMarker?.ddoc_use_28 && currentMarker?.m28_signature_preview === 'loading')"
            (click)="onClickSignature()">
          {{ 'UPLOAD.SIGNATURE' | translate }}
        </label>
      </a>
    </div>
    <div *ngIf="!isUsingDdoc" class="marker-option-slot">
      <a class="btn-marker-option"
          [class.disabled]="!canAddAnnotationInPdf">
        <button #approvedDateOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickApprovedDate()"
            [disabled]="!canAddAnnotationInPdf">
          <span class="fad fa-calendar-alt"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickApprovedDate()"
            [class.disabled]="!canAddAnnotationInPdf">
          {{ 'UPLOAD.APPROVED-DATE' | translate }}
        </label>
      </a>
    </div>
    <div *ngIf="!isUsingDdoc" class="marker-option-slot">
      <a class="btn-marker-option"
          [class.disabled]="!canAddAnnotationInPdf">
        <button #commentOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onCommentOptionClick()"
            [disabled]="!canAddAnnotationInPdf">
          <span class="fal fa-comment-alt-lines"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onCommentOptionClick()"
            [class.disabled]="!canAddAnnotationInPdf">
          {{ 'UPLOAD.COMMENT' | translate }}
        </label>
      </a>
    </div>

    <!--    Tools-->
    <div class="px-3 py-1 marker-option-group-name">
      <span>{{ "UPLOAD.Tool" | translate }}</span>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #dateOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickDate()">
          <span class="far fa-calendar-alt"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickDate()">
          {{ 'UPLOAD.DATE' | translate }}
        </label>
      </a>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #memoNumberOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickMemoNumber()">
          <span class="far fa-hashtag"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickMemoNumber()">
          {{ isInOut ? 'Official Letter Number' : ('UPLOAD.MEMO-NUMBER' | translate) }}
        </label>
      </a>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #insertTextOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickCustomWrite()">
          <span class="far fa-font-case"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickCustomWrite()">
          {{ 'UPLOAD.INSERT-TEXT' | translate }}
        </label>
      </a>
    </div>

    <ng-container *appFeatureFlag="'self_service_form'">
      <ng-container *ngIf="formPermission.canSetting">
        <div class="px-3 pt-3 pb-1 marker-option-group-name">
          <span>{{ "UPLOAD.Form" | translate }}</span>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button #insertTextOptionBtn
                class="btn btn-white btn-circle-option"
                (click)="onClickFormInputTextLine()">
              <span class="far fa-text"></span>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputTextLine()">
              {{ "UPLOAD.Text box" | translate }}
            </label>
          </a>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button #insertTextOptionBtn
                class="btn btn-white btn-circle-option"
                (click)="onClickFormInputTextDropdown()">
              <span class="far fa-chevron-down"></span>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputTextDropdown()">
              {{ "UPLOAD.Dropdown" | translate }}
            </label>
          </a>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button class="btn btn-white btn-circle-option"
                (click)="onClickFormInputDate()">
              <div>
                <svg-icon src="assets/svg/calendar.svg"
                    [svgStyle]="{'height.px': 14, 'width.px': 14}"
                    class="tool-icon"></svg-icon>
              </div>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputDate()">
              {{ "UPLOAD.Date" | translate }}
            </label>
          </a>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button class="btn btn-white btn-circle-option"
                (click)="onClickFormInputNumber()">
              <div>
                <svg-icon src="assets/svg/number.svg"
                    [svgStyle]="{'height.px': 14, 'width.px': 14}"
                    class="tool-icon"></svg-icon>
              </div>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputNumber()">
              {{ "UPLOAD.number" | translate }}
            </label>
          </a>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button #insertTextOptionBtn
                class="btn btn-white btn-circle-option"
                (click)="onClickFormInputCheckbox()">
              <span class="far fa-check-square"></span>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputCheckbox()">
              {{ "UPLOAD.Checkbox" | translate }}
            </label>
          </a>
        </div>
        <div class="marker-option-slot">
          <a class="btn-marker-option">
            <button #insertTextOptionBtn
                class="btn btn-white btn-circle-option"
                (click)="onClickFormInputRadio()">
              <span class="far fa-dot-circle"></span>
            </button>
            <label class="text-btn-circle"
                [class.d-none]="markerMenuDisplay === 'short'"
                (click)="onClickFormInputRadio()">
              {{ "UPLOAD.Radio" | translate }}
            </label>
          </a>
        </div>
      </ng-container>
    </ng-container>

    <div class="px-3 pt-3 pb-1 marker-option-group-name">
      <span>{{ "UPLOAD.Other" | translate }}</span>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #insertCheckOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickSymbol('radio_checkbox')">
          <span class="fas fa-check"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickSymbol('radio_checkbox')">
          {{ "UPLOAD.CHECK-MARK" | translate }}
        </label>
      </a>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #insertCircleOptionBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickSymbol('radio')">
          <span class="fas fa-circle"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickSymbol('radio')">
          {{ "UPLOAD.CIRCLE" | translate }}
        </label>
      </a>
    </div>
    <div class="marker-option-slot">
      <a *ngIf="!watermarkList.length; else waterMarkOptionContainer"
          class="btn-marker-option option-disable"
          container="body"
          placement="top"
          [ngbTooltip]="'UPLOAD.PLEASE-UPLOAD-WATERMARK' | translate">
        <ng-container *ngTemplateOutlet="waterMarkOption"></ng-container>
      </a>
      <ng-template #waterMarkOptionContainer>
        <a class="btn-marker-option">
          <ng-container *ngTemplateOutlet="waterMarkOption"></ng-container>
        </a>
      </ng-template>
      <ng-template #waterMarkOption>
        <button class="btn btn-white btn-circle-option"
            [disabled]="!watermarkList.length"
            [class.disabled]="!watermarkList.length"
            (click)="onClickWatermark()">
          <span class="fal fa-tint"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            [class.disabled]="!watermarkList.length"
            (click)="onClickWatermark()">
          {{ 'UPLOAD.WATERMARK' | translate }}
        </label>
      </ng-template>
    </div>
    <div class="marker-option-slot">
      <a class="btn-marker-option">
        <button #clearBtn
            class="btn btn-white btn-circle-option"
            (click)="onClickClear()">
          <span class="far fa-trash-alt"></span>
        </button>
        <label class="text-btn-circle"
            [class.d-none]="markerMenuDisplay === 'short'"
            (click)="onClickClear()">
          {{ 'UPLOAD.CLEAR' | translate }}
        </label>
      </a>
    </div>

  </div>
  <div class="p-md-4 p-2 col"
      [ngClass]="{'col-md-9': markerMenuDisplay !== 'short' && allowEdit,
      'h-75vh': isPdfLoading && checkPage === 'approvalEmailPage'}">
    <div *ngIf="!controlPageFromOutside"
        class="d-flex justify-content-center pb-md-4 pb-2">
      <div class="page-control">
        <button type="button"
            [disabled]="loadingPage"
            (click)="prevPage()">
          <span class="fal fa-angle-left"></span>
        </button>
        <div class="page-selection">
          <span>{{ 'UPLOAD.PAGE' | translate }}</span>
          <ng-select class="d-inline"
              [clearable]="false"
              [items]="totalPageDropdown"
              [(ngModel)]="currentPage"
              (change)="onSelectedPage($event)">
          </ng-select>
          <span>{{ 'UPLOAD.OF' | translate }} {{totalPage}}</span>
        </div>
        <button type="button"
            [disabled]="loadingPage"
            (click)="nextPage()">
          <span class="fal fa-angle-right"></span>
        </button>
      </div>
    </div>
    <div class="spinner-center"
        *ngIf="isPdfLoading && checkPage === 'approvalEmailPage'">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <div class="template-custom-pdf-view"
        #pdfView
        (resized)="onPdfViewResized($event)"
        [class.no-border]="controlPageFromOutside">
      <div *ngIf="pdfError">{{pdfError}}</div>
      <pdf-viewer [src]="checkIfDocumentIsString()? pdfPath: pdfPath.url"
          [ngStyle]="{'height': height + 'px', 'width': width + 'px'}"
          [ngClass]="{'pdf-viewer-scrollbar-gutter-stable': scrollbarGutterStable}"
          [(page)]="currentPage"
          [stick-to-page]="true"
          [render-text]="true"
          [render-text-mode]="1"
          [original-size]="false"
          [fit-to-page]="true"
          [show-all]="false"
          [show-borders]="false"
          (page-rendered)="pageRendered()"
          (after-load-complete)="onLoadComplete($event)"
          [c-maps-url]="'/assets/cmaps/'"
          (error)="onPdfError($event)">
      </pdf-viewer>
      <ng-container *ngIf="pdf && originalPageView">
        <ng-container *ngFor="let marker of markers; let i=index; trackBy: dragdropGroupTrackByFunc">
          <ng-container *ngFor="let positionKey of positionKeys">
            <!-- TODO - deprecate getPositionFilterByPage function which is impure function -->
            <!-- TODO - should back to use pipe instead and fix issue (1f484f4) that cause to use this function -->
            <ng-container *ngFor="let markerIdentity of getPositionFilterByPage(marker[positionKey]) || [];
                trackBy dragdropTrackByFunc">
              <div class="dragdrop"
                  #dragdropDiv
                  [ngClass]="[getDragDropClass(marker, markerIdentity),bgForInputFormRadioCheckbox(markerIdentity),
                  bgForInputFormInput(markerIdentity)]"
                  [class.disable-active-effect]="!allowEdit"
                  [ngStyle]="{
                    'min-width': marker.type === 'signature' ||
                    (!marker.type && markerIdentity.optionalType === 'positions' ) ||
                    isFormInputType(markerIdentity)
                      ? 'auto'
                      : convertToScreenMarkerWidth(markerIdentity) + 'px',
                    width: 'min-content', 'z-index': markerIdentity.optionalType === 'approved_date_positions' ||
                    markerIdentity.optionalType === 'comment_positions' ||
                    markerIdentity.optionalType === 'positions'? markerIdentity.zIndex: marker.zIndex
                  }"
                  cdkDrag
                  [cdkDragFreeDragPosition]="markerIdentity.screenCoordinate"
                  [cdkDragDisabled]="disableDragDrop(markerIdentity) || markerIdentity.enableSetting"
                  (cdkDragStarted)="onDragStart(markerIdentity); setDragDropOnTop(markerIdentity);"
                  (cdkDragEnded)="onChange(markerIdentity, $event, marker.name, 'position');checkDraggingOverOnDragEnded();">

                <div class="dragdrop-info"
                    (click)="setDragDropOnTop(markerIdentity);">
                  <p *ngIf="!allowEdit || marker.type !== 'custom'"
                      class="line-size"
                      [ngClass]="{'p-0': !allowEdit && marker.type === 'custom'}"
                      [style.line-height.px]="markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight"
                      [style.font-size.px]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                      [style.font-family]="getFontStyle(markerIdentity.fontStyle)"
                      [style.color]="markerIdentity.color">
                    <ng-container *ngIf="!['custom', 'memo_number', 'watermark'].includes(marker.type)
                          && markerIdentity.optionalType !== 'approved_date_positions'
                          && markerIdentity.optionalType !== 'comment_positions'">
                      <ng-container *ngIf="(marker.signature || (marker.ddoc_use_28 && marker.m28_signature_preview)) &&
                          !isApprovalRequest; else signatureName">
                        {{ marker.name | loaNameThai: (translate.currentLang !== 'th')}}
                        <app-resizable-draggable [allowEdit]="allowEdit"
                            [bindHeight]="'H'"
                            [bindWidth]="'W'"
                            [containment]="pdfView"
                            [sizeBoundary]="{
                              minHeight: 1.5 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5,
                              minWidth: 3 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5
                            }"
                            [size]="markerIdentity"
                            [lockRatio]="true"
                            [img]="marker.ddoc_use_28 ? marker.m28_signature_preview : marker.signature"
                            [resetButtonStyle]="{ top: '0', right: '0', left: 'auto' }"
                            [scale]="currentWidthScale"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'watermark_size')">
                        </app-resizable-draggable>
                      </ng-container>
                      <ng-template #signatureName>
                        <ng-container *ngIf="marker.type === 'date'; else signatureNormal"
                        >{{getDragDropInfo(marker, markerIdentity) | loaNameThai : (translate.currentLang !== 'th')}}
                          <br>{{textDateType}}
                        </ng-container>
                        <ng-template #signatureNormal>
                          {{ marker.name | loaNameThai: (translate.currentLang !== 'th')}}
                          <app-resizable-draggable [allowEdit]="allowEdit"
                              [bindHeight]="'H'"
                              [bindWidth]="'W'"
                              [containment]="pdfView"
                              [sizeBoundary]="{
                                minHeight: 1.5 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5,
                                minWidth: 3 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5
                              }"
                              [size]="markerIdentity"
                              [lockRatio]="true"
                              [isSignature]="true"
                              [isApprovalRequest]="isApprovalRequest"
                              [img]="translate.currentLang === 'th'? 'assets/images/sign-th.svg': 'assets/images/sign.svg'"
                              [resetButtonStyle]="{ top: '0', right: '0', left: 'auto' }"
                              [scale]="currentWidthScale"
                              (sizeChange)="onChange(markerIdentity, $event, marker.name, 'watermark_size')">
                          </app-resizable-draggable>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="markerIdentity.optionalType === 'approved_date_positions'">
                      <span class="m-0 white-space-initial"
                          [style.line-height.px]="(markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight) + 1">
                        {{getDragDropInfo(marker, markerIdentity)}}
                      </span>
                      <br>{{ marker.name | loaNameThai : (translate.currentLang !== 'th')}}
                      <br>{{ 'UPLOAD.APPROVED-DATE' | translate }}
                    </ng-container>
                    <ng-container *ngIf="markerIdentity.optionalType === 'comment_positions'">
                      <span class="m-0 white-space-initial"
                          [style.line-height.px]="(markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight) + 1">
                        {{ 'UPLOAD.COMMENT' |translate }}
                      </span>
                      <br>{{ marker.name | loaNameThai : (translate.currentLang !== 'th')}}
                      <br>{{ getDragDropInfo(marker, markerIdentity) }}
                    </ng-container>
                    <ng-container *ngIf="marker.type==='memo_number'">
                      <ng-container *ngIf="memoNumber">
                        {{ getDragDropInfo(marker, markerIdentity) }}
                      </ng-container>
                      <ng-container *ngIf="!memoNumber">
                        {{ isInOut ? 'Official Letter Number' : ('UPLOAD.MEMO-NUMBER' | translate) }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="marker.type === 'watermark'">
                      <app-resizable-draggable [allowEdit]="allowEdit"
                          [bindHeight]="'H'"
                          [bindWidth]="'W'"
                          [lockRatio]="true"
                          [containment]="pdfView"
                          [sizeBoundary]="{
                            minHeight: 1.5 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5,
                            minWidth: 3 * (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) * 1.2 / 1.5
                          }"
                          [size]="markerIdentity"
                          [img]="markerIdentity.watermarkPic"
                          [imgOpacity]="markerIdentity.watermarkOpacity || 0.2"
                          [scale]="currentWidthScale"
                          [resetButtonStyle]="{ top: '0', right: '0', left: 'auto' }"
                          (sizeChange)="onChange(markerIdentity, $event, marker.name, 'watermark_size')">
                      </app-resizable-draggable>
                    </ng-container>
                  </p>
                  <div *ngIf="marker.type === 'custom'">
                    <ng-container *ngIf="isFormInputType(markerIdentity) else normalCustom">
                      <ng-container *ngIf="markerIdentity.formInputType === 'text_line'">
                        <app-input-textline [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [fixedFontSize]="markerIdentity.options.fixedFontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            [rowTextarea]="markerIdentity.options.rowTextarea"
                            [canSettingForm]="formPermission.canSetting"
                            [canFillForm]="formPermission.canFillIn"
                            [pdfElementHeight]="originalPageView"
                            [isUploadTemplate]="isUploadTemplate"
                            (rowTextareaChange)="onChange(markerIdentity, $event, marker.name, 'form_input_rowTextarea')"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_textline_resize')"
                            (fontSizeChange)="onChange(markerIdentity, $event, marker.name, 'size')"
                            (fillTextEnter)="onChange(markerIdentity, $event, marker.name, 'form_input_fillTextEnter')"
                            (click)="setDragDropOnTop(markerIdentity)"
                        >
                        </app-input-textline>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="markerIdentity.formInputType === 'text_dropdown'">
                        <app-input-text-dropdown [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [fixedFontSize]="markerIdentity.options.fixedFontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            [dropdownOptions]="markerIdentity.options"
                            [canSettingForm]="formPermission.canSetting"
                            (setDragDropOnTop)="setDragDropOnTop(markerIdentity)"
                            [canFillForm]="formPermission.canFillIn"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_resize')"
                            (fontSizeChange)="onChange(markerIdentity, $event, marker.name, 'size')"
                            (selectedDropdown)="onChange(markerIdentity, $event, marker.name, 'form_input_selectedDropdown')"
                            [(draggingOver)]="draggingOverInputDropdown"
                            (mousemove)="onMouseEnterInputDropdown()"
                            (mousedown)="onMouseLeaveInputDropdown()">
                        </app-input-text-dropdown>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="markerIdentity.formInputType === 'date'">
                        <app-input-date [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [fixedFontSize]="markerIdentity.options.fixedFontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            [canSettingForm]="formPermission.canSetting"
                            [canFillForm]="formPermission.canFillIn"
                            [sizeBoundary]="{
                              minHeight: (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) / 1.5,
                              minWidth: (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) / 1.5
                            }"
                            (setDragDropOnTop)="setDragDropOnTop(markerIdentity)"
                            [pdfElementHeight]="originalPageView"
                            [isUploadTemplate]="isUploadTemplate"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_resize')"
                            (fontSizeChange)="onChange(markerIdentity, $event, marker.name, 'size')"
                            (fillTextEnter)="onChange(markerIdentity, $event, marker.name, 'form_input_fillTextEnter')">
                        </app-input-date>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="markerIdentity.formInputType === 'number'">
                        <app-input-number [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [fixedFontSize]="markerIdentity.options.fixedFontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            (textChange)="validateMinMax($event)"
                            [rowTextarea]="markerIdentity.options.rowTextarea"
                            [canSettingForm]="formPermission.canSetting"
                            [canFillForm]="formPermission.canFillIn"
                            [pdfElementHeight]="originalPageView"
                            [allowEdit]="allowEdit"
                            [isUploadTemplate]="isUploadTemplate"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_resize')"
                            (fontSizeChange)="onChange(markerIdentity, $event, marker.name, 'size')"
                            (fillTextEnter)="onChange(markerIdentity, $event, marker.name, 'form_input_fillTextEnter')">
                        </app-input-number>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="markerIdentity.formInputType === 'radio_checkbox'">
                        <app-input-radio-checkbox [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            [checked]="!!markerIdentity.data"
                            [radioOption]="markerIdentity.options"
                            [canSettingForm]="formPermission.canSetting"
                            [canFillForm]="formPermission.canFillIn"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_resize')"
                            (toggleRadio)="onChange(markerIdentity, $event, marker.name, 'form_input_toggleRadio')">
                        </app-input-radio-checkbox>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="markerIdentity.formInputType === 'radio'">
                        <app-input-radio [containment]="pdfView"
                            [size]="markerIdentity"
                            [scale]="currentWidthScale"
                            [fontFamily]="getFontStyle(markerIdentity.fontStyle)"
                            [fontSize]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [markerIdentity]="markerIdentity"
                            [checked]="!!markerIdentity.data"
                            [radioOption]="markerIdentity.options"
                            [canSettingForm]="formPermission.canSetting"
                            [canFillForm]="formPermission.canFillIn"
                            (sizeChange)="onChange(markerIdentity, $event, marker.name, 'form_input_resize')"
                            (toggleRadio)="onChange(markerIdentity, $event, marker.name, 'form_input_toggleRadio')">
                        </app-input-radio>
                        <div class="widget-text-error-msg">
                          <div class="text-red display-error">
                            {{widgetTextErrorMsg(markerIdentity)}}
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-template #normalCustom>
                      <app-resizable-draggable *ngIf="markerIdentity.symbol"
                          [allowEdit]="allowEdit"
                          [allowReset]="false"
                          [bindHeight]="'H'"
                          [bindWidth]="'W'"
                          [containment]="pdfView"
                          [sizeBoundary]="{
                            minHeight: (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) / 1.5,
                            minWidth: (markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight) / 1.5
                          }"
                          [size]="markerIdentity"
                          [lockRatio]="true"
                          [img]="'assets/images/' + markerIdentity.symbol + '.png'"
                          [scale]="currentWidthScale"
                          (sizeChange)="onChange(markerIdentity, $event, marker.name, 'watermark_size')">
                      </app-resizable-draggable>

                      <ng-container *ngIf="!markerIdentity.symbol">
                        <p *ngIf="!markerIdentity.string || markerIdentity.string === ''"
                            class="textarea-placeholder"
                            innerText="{{ 'UPLOAD.INSERT-TEXT' | translate }}"
                            [class.invisible]="!allowEdit && !markerIdentity.string"
                            [style.font-family]="getFontStyle(markerIdentity.fontStyle)"
                            [style.font-size.px]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [style.height.px]="calcTextareaHeight(this.translate.instant('UPLOAD.INSERT-TEXT'), markerIdentity)"
                            [style.line-height.px]="markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight"
                            [style.padding]="textMarkerContentPadding">
                        </p>
                        <pre #customTextareaLayout
                            class="textarea-layout"
                            [innerText]="(markerIdentity.string ? markerIdentity.string : '')"
                            [style.font-family]="getFontStyle(markerIdentity.fontStyle)"
                            [style.font-size.px]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [style.line-height.px]="markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight"
                            [style.padding]="textMarkerContentPadding"
                            [style.--c]="!allowEdit? markerIdentity.color : 'transparent'">
                        </pre>
                        <textarea *ngIf="allowEdit"
                            #customTextarea
                            [style.color]="markerIdentity.color"
                            [style.font-family]="getFontStyle(markerIdentity.fontStyle)"
                            [style.font-size.px]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                            [style.height]="calcTextareaHeight(customTextarea.value, markerIdentity)"
                            [style.line-height.px]="markerIdentity.fontSize | relativePdfLineHeight: pdfElementHeight"
                            [style.padding]="textMarkerContentPadding"
                            [ngModel]="markerIdentity.string"
                            (ngModelChange)="onChange(markerIdentity, $event, marker.name, 'text')"
                            (input)="customTextareaLayout.innerText = $event.currentTarget?.value">
                        </textarea>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>


                <div *ngIf="markerIdentity.enableSetting && isFormInputType(markerIdentity)">
                  <app-setting-panel [marker]="marker"
                      [markerIdentity]="markerIdentity"
                      [dragdropDiv]="dragdropDiv"
                      [(markers)]="markers"
                      (closeAllPage)="closeAllSettings(markerIdentity, $event)"
                      (refreshPdf)="refreshScreenPosition()"
                      (addRadio)="onAddRadioToGroup($event, markerIdentity)"></app-setting-panel>
                </div>

                <!-------------------------------- setting panel ----------------------------->
                <div *ngIf="showSettingButton(markerIdentity)"
                    class="dragdrop-options-btn">
                  <div class="btn-dragdrop-setting">
                    <svg-icon src="assets/svg/ellipsis-vertical.svg"
                        (click)="onClickDragDropSetting(markerIdentity, marker);$event.stopPropagation()"
                        (mouseenter)="onMouseEnterSettingBtn()"
                        (mouseleave)="onMouseLeaveSettingBtn()">
                    </svg-icon>
                  </div>
                  <span class="far fa-times"
                      *ngIf="!markerIdentity.formInputType"
                      aria-hidden="true"
                      (click)="onChange(markerIdentity, null, marker.name, 'removeDragDrop')"></span>
                </div>
                <div *ngIf="markerIdentity.enableSetting && !isFormInputType(markerIdentity)"
                    (clickOutside)="closeAllSettings(markerIdentity, $event)"
                    [excludeBeforeClick]="true"
                    [exclude]="'.dragdrop-options-btn,.modal-footer,.ng-dropdown-panel-items,.ng-option,.ng-dropdown-header'"
                    class="option-popup-window"
                    [ngStyle]="getOptionPopupWindowPosition(markerIdentity, dragdropDiv)">
                  <div class="row mb-1">
                    <div class="col-9">
                      <span class="font-weight-bold"
                          *ngIf="isFormInputType(markerIdentity)">
                        {{("UPLOAD." + markerIdentity.formInputType)|translate}}
                      </span>

                    </div>
                    <div class="col-3 d-flex justify-content-end">
                      <span class="delete-marker"
                          (click)="onChange(markerIdentity, null, marker.name, 'removeDragDrop')">{{'MEMOS.DELETE'|translate}}</span>
                    </div>
                  </div>
                  <!-- LOA -->
                  <div *ngIf="(!marker.type && markerIdentity.optionalType !== 'comment_positions')
                      || marker.type === 'signature'"
                      class="option-form mb-2">
                    <label for="setting-marker-loa">{{'UPLOAD.Line of Approval' | translate}}</label>
                    <ng-select bindLabel="name"
                        [style.margin-top]="'0.35rem'"
                        id="setting-marker-loa"
                        [clearable]="false"
                        [readonly]="true"
                        [items]="filterOnlySignatures()"
                        [(ngModel)]="marker.name">
                    </ng-select>
                  </div>
                  <!-- Date format -->
                  <div *ngIf="marker.type === 'date' || markerIdentity.optionalType === 'approved_date_positions'"
                      class="option-form mb-2">
                    <label for="dateFormatSelection"
                        class="mt-2">{{'UPLOAD.DATE-FORMAT'|translate}}<span class="text-red">*</span>
                    </label>
                    <ng-select [style.margin-top]="'0.35rem'"
                        id="dateFormatSelection"
                        [items]="markerIdentity.dateFormatList"
                        bindLabel="label"
                        bindValue="value"
                        [(ngModel)]="markerIdentity.dateFormat"
                        (change)="onChange(markerIdentity, $event.value, marker.name, 'date')"
                        placeholder="select date format"
                        [notFoundText]="'SELECT.No items found'|translate"
                        appendTo=".template-custom-pdf-view"
                        [closeOnSelect]="true"
                        [clearable]="false">
                    </ng-select>
                  </div>
                  <!-- Date type -->
                  <div *ngIf="marker.type === 'date'"
                      class="option-form mb-2">
                    <label for="dateFormatSelection"
                        class="mt-2">{{'UPLOAD.DATE-TYPE'|translate}}<span class="text-red">*</span>
                    </label>
                    <ng-select class=""
                        [style.margin-top]="'0.35rem'"
                        id="dateTypeSelection"
                        [items]="dateTypeList"
                        [(ngModel)]="markerIdentity.dateType"
                        [bindLabel]="translate.currentLang === 'th'? 'th': 'label'"
                        bindValue="value"
                        (change)="onChange(markerIdentity, $event.value, marker.name, 'date_type')"
                        placeholder="select date format"
                        [notFoundText]="'SELECT.No items found'|translate"
                        appendTo=".template-custom-pdf-view"
                        [closeOnSelect]="true"
                        [clearable]="false">
                    </ng-select>
                  </div>
                  <!-- Date Custom -->
                  <div *ngIf="marker.type === 'date' && markerIdentity.dateType === 'custom_date'"
                      class="option-form mb-2">
                    <label>{{'UPLOAD.DATE-CUSTOM'|translate}}</label>
                    <div class="input-group my-2"
                        dir="ltr"
                        style="width: 90%; height: 30px">
                      <input class="bg-calendar-input pointer form-control fn-18"
                          style="height: 30px; background: white"
                          [placeholder]="'UPLOAD.DATE-CUSTOM-PLACEHOLDER'|translate"
                          name="dp"
                          [(ngModel)]="markerIdentity.dateCustomBE"
                          ngbDatepicker
                          [readonly]="true"
                          #d="ngbDatepicker"
                          autocomplete="off"
                          (click)="d.toggle()"
                          [autoClose]="'outside'"
                          (ngModelChange)="onChange(markerIdentity, $event, marker.name, 'date_custom')">
                      <button class="input-group-addon"
                          (click)="d.toggle()"
                          type="button">
                        <i class="fa fa-calendar i-calendar"></i>
                      </button>
                    </div>
                  </div>
                  <!-- WATERMARK -->
                  <div *ngIf="marker.type === 'watermark'"
                      class="option-form mb-3">
                    <label>{{'UPLOAD.WATERMARK'|translate}}</label>
                    <ng-select [items]="watermarkList"
                        [style.margin-top]="'0.35rem'"
                        class="custom"
                        bindLabel="label"
                        bindValue="value"
                        [clearable]="false"
                        placeholder="{{'UPLOAD.WATERMARK' | translate}}"
                        [(ngModel)]="markerIdentity.watermarkPic"
                        (change)="onChange(markerIdentity, $event.value, marker.name, 'watermark')"
                        [notFoundText]="'SELECT.No items found'|translate"
                        appendTo=".template-custom-pdf-view"
                        [closeOnSelect]="false">
                      <ng-template ng-label-tmp
                          let-item="item">
                        <p class="text-truncate text-charcoal pt-1 m-0"
                            style="width: 145px">{{item.label}}</p>
                      </ng-template>
                      <ng-template ng-option-tmp
                          let-item="item"
                          let-index="index"
                          let-search="searchTerm">
                        <div class="text-center">
                          <img height="100"
                              [src]="item.value | secure | async"
                              alt="" />
                          <p class="text-truncate pt-1 m-0 text-charcoal"
                              style="width: 100%">{{item.label}}</p>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>

                  <!-- form inputs-->
                  <ng-container *ngIf="isFormInputType(markerIdentity)">
                    <ng-container *ngIf="markerIdentity.formInputType === 'text_line'">
                      <div class="option-form mb-2">
                        <label class="mt-2">{{ "UPLOAD.Database field name" | translate }}<span
                              class="text-red">*</span>
                        </label>
                        <input class="form-control"
                            type="text"
                            [ngModel]="markerIdentity.options.fieldName"
                            [ngClass]="markerIdentity.options.duplicateName?'is-invalid':''"
                            (keyup)="onChange(markerIdentity, $event.target.value, marker.name, 'form_input_placeholder')"
                            (keydown.enter)="onChange(markerIdentity, $event.target.value, marker.name, 'form_input_placeholder')">
                        <label class="text-red"
                            *ngIf="markerIdentity.options.duplicateName &&
                        markerIdentity.options.fieldName">
                          {{"UPLOAD.This field name is already taken"|translate}}
                        </label>
                        <label class="text-red"
                            *ngIf="!markerIdentity.options.fieldName">
                          <span>
                            {{"UPLOAD.Field Name is required"|translate}}
                          </span>
                        </label>
                        <div class="form-check my-1">
                          <input type="checkbox"
                              class="form-check-input big-checkbox"
                              [ngModel]="markerIdentity.require"
                              (ngModelChange)="onChange(markerIdentity, $event, marker.name, 'form_input_require')">
                          <label class="form-check-label ml-2 font-weight-normal">
                            {{ "UPLOAD.Required" | translate}}
                          </label>
                        </div>
                        <label class="mt-2">{{ "UPLOAD.Text Length" | translate }}
                        </label>
                        <input class="my-2"
                            type="number"
                            (keypress)="numberOnly($event)"
                            [ngModel]="markerIdentity.options.limitLength"
                            (ngModelChange)="onChange(markerIdentity, $event, marker.name, 'form_input_limitLength')">
                        <label class="mt-2">{{ "UPLOAD.Max line" | translate }}
                        </label>
                        <input class="my-2"
                            type="number"
                            [min]="1"
                            (keypress)="numberOnly($event)"
                            [ngModel]="markerIdentity.options.rowTextarea"
                            (ngModelChange)="onChange(markerIdentity, $event, marker.name, 'form_input_rowTextarea')">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="markerIdentity.formInputType === 'text_dropdown'">
                      <app-input-text-dropdown-setting [require]="markerIdentity.require"
                          (requireChange)="onChange(markerIdentity, $event, marker.name, 'form_input_require')"
                          [placeholder]="markerIdentity.options.fieldName"
                          (placeholderChange)="onChange(markerIdentity, $event, marker.name, 'form_input_placeholder')"
                          [dropdownOptions]="markerIdentity.options"
                          (dropdownOptionsChange)="onChange(markerIdentity, $event, marker.name, 'form_input_dropdownOptions')"
                          (selectedDefault)="onChange(markerIdentity, $event, marker.name, 'form_input_selectedDropdown')">
                      </app-input-text-dropdown-setting>
                    </ng-container>
                    <ng-container
                        *ngIf="markerIdentity.formInputType === 'radio' || markerIdentity.formInputType === 'radio_checkbox'">
                      <app-input-radio-setting [require]="markerIdentity.require"
                          (requireChange)="onChange(markerIdentity, $event, marker.name, 'form_input_requireRadio')"
                          [groupId]="markerIdentity.options.fieldName"
                          [markers]="markers"
                          [themeList]="themeList"
                          [fieldValue]="markerIdentity.options.fieldValue"
                          [markerIdentity]="markerIdentity"
                          (groupIdChange)="onChange(markerIdentity, $event, marker.name, 'form_input_radioGroup');"
                          (fieldValueChange)="onChange(markerIdentity, $event, marker.name, 'form_input_radioGroup', 'value');"
                          (addRadio)="onAddRadioToGroup($event, markerIdentity)">
                      </app-input-radio-setting>
                    </ng-container>
                  </ng-container>

                  <!-- page -->
                  <div *ngIf="(
                      !marker.type ||
                      ['signature','date', 'watermark', 'memo_number','custom'].includes(marker.type)
                    ) && !marker.ddoc_enable && !isFormInputType(markerIdentity)"
                      class="option-form mb-2">
                    <label for="pageStyleSelection"
                        class="mt-2">{{'UPLOAD.PAGE'|translate}}<span class="text-red">*</span>
                    </label>
                    <ng-select [style.margin-top]="'0.35rem'"
                        id="pageStyleSelection"
                        appendTo=".template-custom-pdf-view"
                        [(ngModel)]="markerIdentity.pageList"
                        [items]="itemPage"
                        [multiple]="true"
                        [clearable]="false"
                        [closeOnSelect]="false"
                        [notFoundText]="'SELECT.No items found'|translate"
                        (add)="updatePages(markerIdentity, $event, marker.name, 'add')"
                        (remove)="updatePages(markerIdentity, $event, marker.name, 'remove')">
                      <ng-template *ngIf="isSelectedAllPage(markerIdentity)"
                          ng-multi-label-tmp
                          let-items="items">
                        {{'user.select-all' | translate}}
                      </ng-template>
                      <ng-template ng-header-tmp>
                        <input id="item-all"
                            type="checkbox"
                            style="width: unset;"
                            [ngModel]="isSelectedAllPage(markerIdentity)"
                            (ngModelChange)="handleSelectedAllPage(markerIdentity, $event, marker.name)">
                        <label for="item-all"
                            class="ml-2">{{'user.select-all' | translate}}</label>
                      </ng-template>
                      <ng-template ng-option-tmp
                          let-item="item"
                          let-item$="item$"
                          let-index="index">
                        <input id="item-{{index}}"
                            type="checkbox"
                            [ngModel]="item$.selected"
                            style="width: unset;">
                        <label for="item-{{index}}"
                            class="ml-2">{{item}}</label>
                      </ng-template>
                      <ng-template ng-label-tmp
                          let-item="item"
                          let-clear="clear">
                        <label for="item-{{index}}"
                            class="mx-2">{{ item }}</label>
                        <span class="ng-value-icon right"
                            (click)="clear(item)"
                            aria-hidden="true">×</span>
                      </ng-template>
                    </ng-select>
                  </div>
                  <!-- size -->
                  <div class="option-form mb-2"
                      *ngIf="(marker.type && marker.type !== 'watermark' && !isFormInputType(markerIdentity) && !markerIdentity.symbol) ||
                        markerIdentity.optionalType === 'approved_date_positions' ||
                        markerIdentity.optionalType === 'comment_positions'">
                    <label for="fontSizeInput"
                        class="mt-2">{{'UPLOAD.SIZE'|translate}}<span class="text-red">*</span>
                    </label>
                    <input id="fontSizeInput"
                        [style.margin-top]="'0.35rem'"
                        type="number"
                        class="form-control"
                        (keypress)="onKeyPressNonNegative($event)"
                        [min]="1"
                        [value]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                        (change)="onChange(markerIdentity, $event.target.value, marker.name, 'size')">
                  </div>
                  <!-- font -->
                  <div class="option-form mb-2"
                      *ngIf="(marker.type && marker.type !== 'watermark' && !markerIdentity.symbol) ||
                        markerIdentity.optionalType === 'approved_date_positions'">
                    <ng-container
                        *ngIf="markerIdentity.formInputType !== 'radio' && markerIdentity.formInputType !== 'radio_checkbox'">
                      <label for="fontStyleSelection"
                          class="mt-2">{{'UPLOAD.FONT'|translate}}<span class="text-red">*</span>
                      </label>
                      <ng-select [style.margin-top]="'0.35rem'"
                          id="fontStyleSelection"
                          (change)="onChange(markerIdentity, $event, marker.name, 'font')"
                          [items]="fontList"
                          [(ngModel)]="markerIdentity.fontStyle"
                          bindLabel="name"
                          placeholder="select font"
                          [notFoundText]="'SELECT.No items found'|translate"
                          [clearable]="false"
                          appendTo=".template-custom-pdf-view"
                          [closeOnSelect]="true">
                      </ng-select>
                      <label for="fontSizeInput"
                          class="mt-2"
                          *ngIf="(marker.type && marker.type !== 'watermark' && isFormInputType(markerIdentity))">
                        {{'UPLOAD.SIZE'|translate}}</label>
                      <input id="fontSizeInput"
                          type="number"
                          (keypress)="numberOnly($event)"
                          class="mb-2"
                          *ngIf="(marker.type && marker.type !== 'watermark' && isFormInputType(markerIdentity))"
                          [value]="markerIdentity.fontSize | relativePdfFontSize: pdfElementHeight"
                          (change)="onChange(markerIdentity, $event.target.value, marker.name, 'size')">
                    </ng-container>
                  </div>

                  <!-- color -->
                  <div class="option-form mb-2"
                      *ngIf="marker.type && marker.type !== 'watermark' &&
                      (markerIdentity.formInputType !== 'radio' &&
                      markerIdentity.formInputType !== 'radio_checkbox' &&
                      !markerIdentity.symbol)">
                    <label class="mt-2">{{'UPLOAD.COLOR'|translate}}</label>
                    <!-- custom color-->
                    <div class="color-list row mt-2 mr-0">
                      <div class="color-item-border col-2 p-0"
                          style="margin-left: 2px">
                        <div class="color-item"
                            [style.background]="markerIdentity.color"></div>
                      </div>
                      <div class="color-custom my-1 col p-0">
                        <input #ignoredInput
                            type="text"
                            class="form-control"
                            [style.color]="markerIdentity.color"
                            [cpIgnoredElements]="[ignoredInput]"
                            [(ngModel)]="markerIdentity.color"
                            [(colorPicker)]="markerIdentity.color" />
                      </div>
                    </div>
                    <!-- default color list-->
                    <div class="color-list my-1"
                        style="margin-left: -4px">
                      <span class="color-item-border"
                          *ngFor="let color of signatureColorList"
                          [style.border-color]="color === markerIdentity.color ? color : 'white'"
                          (click)="onChange(markerIdentity, color, marker.name, 'color')">
                        <span class="color-item"
                            [style.background]="color"></span>
                      </span>
                      <span class="ml-2 btn-custom-color"
                          (click)="toggleCustomColor(markerIdentity)">+ Add</span>
                    </div>
                    <div class="color-custom my-1"
                        *ngIf="markerIdentity.showCustomColor">
                      <color-sketch disableAlpha="true"
                          [color]="markerIdentity.color"
                          [(ngModel)]="markerIdentity.color"
                          (onChangeComplete)="onChange(markerIdentity, $event.color.hex, marker.name, 'color')">
                      </color-sketch>
                    </div>
                  </div>

                  <!-- watermark opacity -->
                  <div *ngIf="marker.type === 'watermark'"
                      class="option-form">
                    <div class="row no-gutters align-items-center my-2">
                      <div class="col-8">
                        <color-alpha-picker width="50%"
                            (onChange)="onChange(markerIdentity, $event, marker.name, 'watermark_opacity')"
                            [color]="{ r: 0, g: 0, b: 0, a: markerIdentity.watermarkOpacity || 0.2 }">
                        </color-alpha-picker>
                      </div>
                      <div class="col-auto ml-2">
                        <input type="number"
                            [min]="1"
                            [max]="100"
                            (input)="restrictToThreeDigits($event)"
                            [ngModel]="round((markerIdentity.watermarkOpacity || 0.2) * 100)"
                            (ngModelChange)="onChange(markerIdentity, $event / 100, marker.name, 'watermark_opacity')">
                      </div>
                      <div class="col-auto ml-2">
                        %
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end mb-1 mt-3">
                    <button *ngIf="isFormInputType(markerIdentity)"
                        class="btn btn-outline p-btn"
                        [style.--c]="themeList?.header_table_color"
                        type="button"
                        (click)="onChange(markerIdentity, null, marker.name, 'form_input_reset')">
                      {{"UPLOAD.Reset setting"|translate}}
                    </button>
                    <button appSetTheme
                        type="button"
                        class="btn ml-1"
                        (click)="confirmSetting(markerIdentity, marker)">
                      {{ 'UPLOAD.OK' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #applyChangeConfirmationModal
    let-modal
    let-d="dismiss">
  <div class="modal-header col-12"
      ngbAutofocus>
    <h5 class="mb-0">
      {{'MEMOS.Confirm'|translate}}
    </h5>
    <svg-icon *ngIf="selectedChangeType === 'removeDragDrop'"
      src="assets/images/icons/btn-close.svg"
      [svgStyle]="{'width.px': 20, 'height.px': 20}"
      class="pointer icon-close"
      aria-label="Close"
      (click)="d('Cross click')"></svg-icon>
  </div>
  <div class="modal-body text-center pb-3">
    {{ (this.selectedChangeType === 'removeDragDrop'
    ? "MEMOS.APPLY-DELETE-TO-ALL-SELECTED-PAGES" : "MEMOS.APPLY-CHANGE-TO-ALL-SELECTED-PAGES") |translate }}
  </div>
  <div class="modal-footer border-top-0 justify-content-center">
    <button type="button"
        class="btn btn-outline-secondary p-btn"
        (click)="modal.close()">
      {{"MEMOS.ONLY-THIS-PAGE" | translate}}
    </button>
    <button type="button"
        class="btn p-btn"
        appSetTheme
        (click)="modal.close('multi')">
      {{"MEMOS.ALL-SELECTED-PAGES" | translate}}
    </button>
  </div>
</ng-template>

<ng-template #clearAllDataConfirmationModal
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{'MEMOS.CLEAR-CONFIRMATION'|translate}}
    </h4>
    <hr>
  </div>
  <div class="modal-body text-center f-18">
    {{'MEMOS.Are you sure you want to clear all widgets?'|translate}}
  </div>
  <div class="modal-footer">
    <button type="button"
        class="btn btn-outline btn-confirm"
        [style.--c]="themeList?.header_table_color"
        (click)="modal.close()">
      {{"MEMOS.CANCEL" | translate}}
    </button>
    <button type="button"
        class="btn btn-confirm"
        appSetTheme
        (click)="modal.close('true')">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
  </div>
</ng-template>
