import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NumberOption } from '../form-inputs.model';

@Component({
  selector: 'app-input-number-setting',
  templateUrl: './input-number-setting.component.html',
  styleUrls: ['./input-number-setting.component.scss'],
})
export class InputNumberSettingComponent implements OnInit {
  @Input() duplicateName;

  @Input() require: boolean;
  @Output() requireChange = new EventEmitter<boolean>();

  @Input() fieldName: string;
  @Output() fieldNameChange = new EventEmitter<string>();

  @Input() maxValue: number;
  @Output() maxValueChange = new EventEmitter<number>();

  @Input() minValue: number;
  @Output() minValueChange = new EventEmitter<number>();

  @Output() validateMinMaxSetting = new EventEmitter<boolean>();

  @Input() errorOption: NumberOption;
  @Output() errorOptionChange = new EventEmitter();
  error = { min: null, max: null };

  @Input() option;
  @Output() optionChange = new EventEmitter();

  ngOnInit(): void {
    const checkErrorMax = this.maxValue < this.minValue;
    if (this.errorOption.minError) {
      this.validateMinMax(String(this.minValue), 'min');
    }
    if (this.errorOption.maxError) {
      this.validateMinMax(String(this.maxValue), 'max');
    }
  }

  checkMinValue(event) {
    this.validateMinMax(event, 'min');
    this.minValueChange.emit(event);
  }

  checkMaxValue(event) {
    this.validateMinMax(event, 'max');
    this.maxValueChange.emit(event);
  }

  validateMinMax(event: string, type) {
    if (!event) {
      this.error = { min: null, max: null };
      this.errorOption.maxError = false;
      this.errorOption.minError = false;
      return;
    }

    const num = Number(event.replace(/,/g, ''));

    const minValue = this.minValue;
    if (
      (minValue || minValue === 0) &&
      num < minValue &&
      type === 'max'
    ) {
      this.errorOption.maxError = true;
      this.validateMinMaxSetting.emit(true);
      this.error.max = 'UPLOAD.Not less than';
      return;
    }

    const maxValue = this.maxValue;
    if (
      (maxValue || minValue === 0) &&
      num > maxValue &&
      type === 'min'
    ) {
      this.errorOption.minError = true;
      this.validateMinMaxSetting.emit(true);
      this.error.min = 'UPLOAD.Not more than';
      return;
    }
    this.errorOption.maxError = false;
    this.errorOption.minError = false;
    this.error = { min: null, max: null };
    this.validateMinMaxSetting.emit(false);
  }

  onKeyPressNonNegative(event: KeyboardEvent | any): boolean {
    const char = event.charCode;
    const isNumeric = char >= 48 && char <= 57;
    const isDecimalPoint = char === 46;
    const isBackspace = char === 8;

    return isBackspace || isNumeric || isDecimalPoint;
  }

  setValidate() {
    this.option.duplicateName = false;
  }
}
