<div class="option-form custom-box">
  <div class="mb-2">
    <label class="mt-2 mb-0">{{ "UPLOAD.Field Name" | translate }}<span class="text-red">*</span>
    </label>
    <input class="form-control text-charcoal"
        type="text"
        [ngClass]="markerIdentity.options.duplicateName ||!markerIdentity.options.fieldName ?'is-invalid':''"
        [ngModel]="markerIdentity.options.fieldName"
        (ngModelChange)="setValidate()"
        (keyup)="onChange($event.target.value, 'fieldName')"
        (keydown.enter)="onChange($event.target.value, 'fieldName')">
    <label class="text-red"
        *ngIf="markerIdentity.options.duplicateName &&
    markerIdentity.options.fieldName">
      {{"UPLOAD.Field Name has already been used"|translate}}
    </label>
    <label class="text-red"
        *ngIf="!markerIdentity.options.fieldName">
      <span>
        {{"UPLOAD.Field Name is required"|translate}}
      </span>
    </label>
  </div>

  <div class="mb-2 mt-3">
    <div class="form-check">
      <input type="checkbox"
          class="form-check-input big-checkbox"
          [ngModel]="markerIdentity.require"
          (ngModelChange)="onChange($event, 'require')">
      <label class="form-check-label ml-2 font-weight-normal f-14">
        {{ "UPLOAD.Required" | translate}}
      </label>
    </div>
  </div>

  <div class="mb-2">
    <label class="mt-2 mb-0">{{ "UPLOAD.Max line" | translate }}
    </label>
    <input id="maxLineInput"
        class="form-control text-charcoal"
        type="number"
        [min]="1"
        (keypress)="numberOnly($event)"
        [ngModel]="markerIdentity.options.rowTextarea"
        (ngModelChange)="onChange($event, 'rowTextarea')">
  </div>

</div>