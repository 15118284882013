<div class="container-img">
    <div #containerImage="ngResizable"
        class="position-reletive"
        rzHandles="se"
        [ngStyle]="resizableStyle"
        [ngResizable]="allowEdit"
        [style.height]="_height + 'px'"
        [style.width]="_width + 'px'"
        [rzAspectRatio]="lockRatio"
        [rzContainment]="containment"
        [rzMaxHeight]="sizeBoundary?.maxHeight"
        [rzMaxWidth]="sizeBoundary?.maxHeight"
        [rzMinHeight]="sizeBoundary?.minHeight"
        [rzMinWidth]="sizeBoundary?.minWidth"
        (rzStop)="onResizeStop($event)"
        (rzResizing)="onResizing($event)">
        <div [ngStyle]="{'background': isSignature && isApprovalRequest? '#cbefee':'',
        'border-radius': isSignature && isApprovalRequest? '5%':''}"
            [style.height]="_height !== null ? _height + 'px' : 'auto'"
            [style.width]="_width !== null ? _width + 'px' : 'auto'">
            <img #resizedImage
                alt=""
                class="d-block"
                [src]="(img | secure: false | async)"
                [style.height]="'100%'"
                [style.width]="'100%'"
                [style.object-fit]="'contain'"
                [style.opacity]="imgOpacity"
                (load)="onImageLoad()">
        </div>
        <div *ngIf="allowEdit && allowReset"
            class="icon-resize"
            [ngStyle]="resetButtonStyle"
            [style.height.px]="resetButtonSize * 1.6"
            [style.width.px]="resetButtonSize * 1.6">
            <i class="fas fa-history pointer"
                ngbTooltip="Reset size"
                [style.fontSize.px]="resetButtonSize"
                (click)="onReset(containerImage);$event.stopPropagation()">
            </i>
        </div>
    </div>
</div>