import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { AlertService } from '../../services/alert.service';
import { NavbarService } from '../navbar/navbar.service';
import { TitleService } from '../../../shared/service/title-service';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';
import { PermissionService } from '../../services/permission.service';
import { featureFlag } from 'src/environments/environment';
import {
  filter,
  map,
  pluck,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import {
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { DateFilterType } from '../../../modules/executive/components/executive-list/executive-list.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  navbarActive = false;
  fullWidth: number;
  flags;
  selectedFlags: string;
  profile: any;
  isAdmin = false;
  notificationsUnread: Array<any>;
  notificationsRead: Array<any>;
  notificationResult;
  notificationHeader: string;
  showNotificationToggle = false;
  showWarning = false;
  getWarningEnableOTPFirstTime = true;
  certificateStatus: string = null;
  earlier: string;
  loadMore: string;
  translateSidebar;
  page = 1;
  isLoading = false;
  typeNotification = {
    tag_comment_on_memo: 'memo_comment_tag',
    comment_reply_on_memo: 'memo_comment_reply',
    new_announcement: 'memo_announced',
    new_post_department: 3,
    new_post_public: 4,
    new_post_and_mention: 5,
    new_comment: 6,
    new_comment_mention: 7,
    new_comment_tag_comment: 8,
    memo_new_req_for_approval: 9,
    memo_update_status: 10,
  };

  checkLanguage = false;
  themeList: ThemeList;
  hoverList = {
    logout: false,
  };

  language: string;
  isFullSidebar = true;
  departmentSubMenu = true;
  manageUploadSubMenu = true;
  openSidebar = false;
  backgroundFade = null;
  usageInformation = true;
  manageCertificate = true;
  expandApprovalGroup = true;
  expandAssigneeGroup = true;
  expandOutgoingApprovalGroup = true;

  isPendingApprovalOverDueRoute = false;
  isPendingApprovalTodayDueRoute = false;
  isPendingApprovalNextDueRoute = false;

  initialRouteUrl: string;
  isInOutRoute = false;
  isInOutAnnouncementRoute = false;
  isInOutMemoListRoute = false;
  isActionRequireRoute = false;
  isAssignAssigneeOverDueRoute = false;
  isAssignAssigneeTodayDueRoute = false;
  isAssignAssigneeNextDueRoute = false;
  isOutgoingApprovalOverDueRoute = false;
  isOutgoingApprovalTodayDueRoute = false;
  isOutgoingApprovalNextDueRoute = false;
  destroy$ = new Subject();
  url$: Observable<string>;
  dueDateType$: Observable<DateFilterType>;
  eContract = true;

  subscription: Subscription[] = [];
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private translate: TranslateService,
    public notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private alert: AlertService,
    private profileService: ProfileService,
    private permission: PermissionService,
    private store: Store,
    private titleService: TitleService,
  ) {
    this.subscription.push(
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.openSidebar = false;
        }
      }),
    );

    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
        this.hexToRGBA();
      }),
    );
    this.subscription.push(
      this.titleService.getSidebar().subscribe((res) => {
        this.openSidebar = !this.openSidebar;
        this.isFullSidebar = res;
      }),
    );

    this.subscription.push(
      this.store.subscribe((state) => {
        this.profile = state.auth;
        if (
          featureFlag.ddoc &&
          this.getWarningEnableOTPFirstTime &&
          this.profile &&
          this.profile.id &&
          this.permission.checkAccess([
            'General',
            'Administrator',
            'Official Letter Admin',
            'Contract Management',
          ])
        ) {
          this.getWarningEnableOTPFirstTime = false;
          const subscription = this.profileService
            .getWarningEnableOTP(this.profile.person_id)
            .subscribe((data: any) => {
              this.showWarning = data.show_warning;
            });
          this.subscription.push(subscription);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.handleRouting();

    $('#menu-toggle').click((e) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedFlags = localStorage.getItem('lang');
    this.translateSidebar = this.translate;
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateNotification();
      },
    );
    this.notificationService.updateNotificationCount();
    this.getAllNotification();
    this.translateNotification();
    this.checkRouteForSubmenu();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  hexToRGBA(): void {
    let hex = this.themeList.hovered_text_color;
    const opacity = 0.1;
    const rgb =
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map((l) => {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')';
    this.backgroundFade = rgb;
  }

  checkRouteForSubmenu(): void {
    const manageOrg = [
      { url: 'department' },
      { url: 'users' },
      { url: 'loa' },
      { url: 'cc-group' },
      { url: 'memos' },
      { url: 'waiting-review' },
      { url: 'memos/trash' },
    ];

    const usage = [
      { url: 'kpi-dashboard' },
      { url: 'usage-dashboard' },
      { url: 'memo-report' },
      { url: 'operation-log' },
    ];

    const eContract = [
      { url: 'contract-package' },
      { url: 'contract-dashboard' },
    ];

    const certificate = [
      { url: 'manage-csr' },
      { url: 'certificate' },
    ];
    const url = this.router.url.split('/')[1];
    let checkPathOrg = null;
    let checkPathUsageInformation = null;
    let checkPathContract = null;
    let checkPathCertificate = null;
    checkPathOrg = manageOrg.some((res) => res.url === url);
    checkPathUsageInformation = usage.some((res) => res.url === url);
    checkPathContract = usage.some((res) => res.url === url);
    checkPathCertificate = certificate.some((res) => res.url === url);
    if (checkPathOrg) {
      this.departmentSubMenu = true;
    }
    if (url === 'manage-types-upload-memo') {
      this.manageUploadSubMenu = true;
    }
    if (checkPathUsageInformation) {
      this.usageInformation = true;
    }

    if (checkPathContract) {
      this.eContract = true;
    }

    if (checkPathCertificate) {
      this.manageCertificate = true;
    }
  }

  checkRouteActive(url: string): boolean {
    return url.split('/')[1] === this.router.url.split('/')[1];
  }

  translateNotification(): void {
    this.notificationHeader = this.translate.instant(
      'NAV-BAR.NOTIFICATION',
    );
    this.earlier = this.translate.instant('NAV-BAR.EARLIER');
    this.loadMore = this.translate.instant('NAV-BAR.LOAD-MORE');
    if (this.translate.currentLang === 'en') {
      this.checkLanguage = true;
    } else {
      this.checkLanguage = false;
    }
  }

  getAllNotification(): void {
    this.notificationService
      .getAllNotification({ page: 1 })
      .subscribe(
        (notification) => {
          this.notificationResult = notification;
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
  }

  canCreateUser(): boolean {
    return this.router.url === '/permission/profile/list';
  }

  navigateTo(): void {
    this.router.navigate(['/department', 'level']);
  }

  openNotification(): void {
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification(): void {
    this.showNotificationToggle = false;
  }

  // Notification
  clickNotification(notification): void {
    this.notificationService.updateIsRead(notification.id).subscribe(
      () => {
        _.forEach(this.notificationResult.results, (obj) => {
          if (notification.id === obj.id && obj.read === false) {
            obj.read = true;
            this.notificationResult.unread_count =
              this.notificationResult.unread_count - 1;
          }
        });
        [this.notificationsUnread, this.notificationsRead] =
          _.partition(this.notificationResult.results, [
            'read',
            false,
          ]);
      },
      (err) => {
        this.swal.toastNotification({
          type: 'error',
          content: err.status,
        });
      },
    );
    this.notificationRouter(notification);
  }

  notificationRouter(notification): void {
    this.showNotificationToggle = !this.showNotificationToggle;
    if (
      notification.notification.source_content_type_name === 'memo'
    ) {
      if (notification.action === 'memo_pending') {
        return this.navigateToPage(
          '/executive/list/',
          notification.notification.source_id,
        );
      } else {
        return this.navigateToPage(
          '/memo/memo/real/list/',
          notification.notification.source_id,
        );
      }
    } else if (
      notification.notification.source_content_type_name ===
      'announcement'
    ) {
      this.router.navigate(['/feed', 'list']);
    } else if (
      notification.notification.source_content_type_name ===
      'newsfeedpost'
    ) {
      this.navigateToPage(
        '/feed/list/',
        notification.notification.source_id,
      );
    }
  }

  navigateToPage(url: string, id?: number): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/', url + id ? id : '']);
      });
  }

  loadMoreNotification(): void {
    if (
      this.notificationResult.results.length <
      this.notificationResult.count
    ) {
      this.isLoading = true;
      this.page += 1;
      const params = { page: this.page.toString() };
      this.notificationService.getAllNotification(params).subscribe(
        (res) => {
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
    }
  }

  hoverText(type, value): void {
    this.hoverList[type] = value;
  }

  openFullSidebar() {
    this.isFullSidebar = !this.isFullSidebar;
  }

  onResize($event) {
    this.isFullSidebar = true;
  }

  handleRouting() {
    this.initialRouteUrl = this.router.url;

    this.url$ = this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((e) => e instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      startWith(this.initialRouteUrl),
      map((url) => url.split('?')[0]),
    );

    this.url$.subscribe((url) => {
      this.isInOutRoute = url.includes('in-out');
      this.isActionRequireRoute = url.includes(
        'in-out/pending-action/action-require',
      );
      // this.isAssignAssigneeRoute = url.includes(
      //   'in-out/pending-action/assign-assignee',
      // );
      this.isInOutAnnouncementRoute =
        this.isInOutRoute && url.includes('announcement');

      this.isInOutMemoListRoute =
        this.isInOutRoute &&
        !url.includes('pending-action') &&
        !this.isInOutAnnouncementRoute;
    });

    this.dueDateType$ = this.activateRoute.queryParams.pipe(
      map(({ type, due_date }) => {
        if (type) {
          return type;
        }
        if (due_date) {
          return this.determinedDateType(due_date);
        }
        return null;
      }),
    );

    combineLatest([this.dueDateType$, this.url$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([type, url]) => {
        if (url.includes('assign-assignee')) {
          switch (type) {
            case DateFilterType.OVER_DUE:
              this.isAssignAssigneeOverDueRoute = true;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.TODAY:
              this.isAssignAssigneeTodayDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.NEXT_DUE:
              this.isAssignAssigneeNextDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
          }
        } else if (url.includes('approval-request')) {
          // for outgoing LOA approval
          switch (type) {
            case DateFilterType.OVER_DUE:
              this.isOutgoingApprovalOverDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.TODAY:
              this.isOutgoingApprovalTodayDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.NEXT_DUE:
              this.isOutgoingApprovalNextDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              break;
          }
        } else if (url.includes('pending-approval')) {
          switch (type) {
            case DateFilterType.OVER_DUE:
              this.isPendingApprovalOverDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.TODAY:
              this.isPendingApprovalTodayDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalNextDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
            case DateFilterType.NEXT_DUE:
              this.isPendingApprovalNextDueRoute = true;
              this.isAssignAssigneeOverDueRoute = false;
              this.isAssignAssigneeTodayDueRoute = false;
              this.isAssignAssigneeNextDueRoute = false;
              this.isPendingApprovalOverDueRoute = false;
              this.isPendingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalOverDueRoute = false;
              this.isOutgoingApprovalTodayDueRoute = false;
              this.isOutgoingApprovalNextDueRoute = false;
              break;
          }
        } else {
          this.isAssignAssigneeOverDueRoute = false;
          this.isAssignAssigneeTodayDueRoute = false;
          this.isAssignAssigneeNextDueRoute = false;
          this.isPendingApprovalOverDueRoute = false;
          this.isPendingApprovalTodayDueRoute = false;
          this.isPendingApprovalNextDueRoute = false;
          this.isOutgoingApprovalOverDueRoute = false;
          this.isOutgoingApprovalTodayDueRoute = false;
          this.isOutgoingApprovalNextDueRoute = false;
        }
      });
  }

  determinedDateType(dueDateStr: string): DateFilterType {
    if (!dueDateStr) {
      return DateFilterType.NEXT_DUE;
    }
    const dueDate = new Date(dueDateStr);
    const today = new Date();
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const dueDateTime = dueDate.getTime();
    const todayTime = today.getTime();
    if (dueDateTime === todayTime) {
      return DateFilterType.TODAY;
    } else if (dueDateTime > todayTime) {
      return DateFilterType.NEXT_DUE;
    } else if (dueDateTime < todayTime) {
      return DateFilterType.OVER_DUE;
    }
  }

  isUserActive() {
    const currentRoute = this.router.url
      .split('/')[1]
      .substring(0, 5);
    if (currentRoute === 'users') {
      return 'content-user-height';
    } else {
      return 'content-height';
    }
  }
}

interface Result {
  next: string;
  previous?: string;
  count: number;
  results: Notification[];
  unread_count: number;
  read_count: number;
}
