<ng-template #content
    let-modal
    let-close="close">
  <div class="preview-helper d-flex flex-row flex-md-column"
    style="gap:10px"
    [ngStyle]="{'margin-top.rem': isMobile ? 3 : 0}"
    >
    <div>
      <a class="f-30 text-white icon-circle"
          href="javascript:void(0)"
          (click)="closePreview()">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="order-first order-md-last">
      <a class="f-30 text-white icon-circle"
          href="javascript:void(0)"
          *ngIf="checkIfDocumentIsString() && !isNeedToken"
          (click)="printDocument()">
        <svg-icon src="assets/images/icon_print2.svg"
            [svgStyle]="{'fill': '#ffffff', 'height.px': 15, 'width.px': 15}"></svg-icon>
      </a>
    </div>
    <div class="order-first order-md-last">
      <a class="f-30 text-white icon-circle"
        href="javascript:void(0)"
        *ngIf="checkIfDocumentIsString() && !isNeedToken"
        (click)="downloadDocument()">
        <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'fill': '#ffffff', 'height.px': 15, 'width.px': 15}"></svg-icon>
      </a>
    </div>
  </div>

  <div class="preview-content">
    <div *ngIf="isNeedToken">
      <pdf-viewer *ngIf="isPDF()"
          [src]="{url: pdfFile, httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders:null)}"
          (after-load-complete)="pageRendering()"
          [original-size]="true"
          [autoresize]="true"
          [fit-to-page]="true"
          [show-all]="true"
          [stick-to-page]="true"
          [c-maps-url]="'/assets/cmaps/'"
          style="width: 100%; height: 90vh"
          ></pdf-viewer>
      <div *ngIf="isImage()">
        <img [src]="document | secure | async">
      </div>
      <div *ngIf="isBadFile()">
        <div class="cannot-preview">
          Cannot preview this file type
        </div>
      </div>
    </div>

    <div class="pdf-viewer-viewer-window"
        *ngIf="!isNeedToken">
      <pdf-viewer *ngIf="isPDF()"
          [src]="{url: pdfFile, httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders:null)}"
          (after-load-complete)="pageRendering()"
          [original-size]="true"
          [autoresize]="true"
          [fit-to-page]="true"
          [show-all]="true"
          [stick-to-page]="true"
          [c-maps-url]="'/assets/cmaps/'"
          style="width: 100%; height: 90vh"
          ></pdf-viewer>

      <div *ngIf="isImage()">
        <img [src]="document.includes('blob') ? sanitize(document) : document | secure | async">
      </div>

      <div *ngIf="isBadFile()">
        <div class="cannot-preview">
          Cannot preview this file type<br>
          Please download to view <br>
          <a href="javascript:void(0)"
              (click)="downloadDocument()">
            Click here to download
          </a>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer"
      *ngIf="enableAck">
    <button class="custom-button-finger icon-circle"
        (click)="sidebarFinger();">
      <svg-icon class="glyphicon finger-print pointer-none"
          src="assets/images/icons/positive-vote.svg">
      </svg-icon>
    </button>
    <div class="custom-opacity"
        id="slide-wrapper"
        [ngClass]="fullSidebar ? 'width-sidebar':'width-sidebar-sm'">
      <div class="position-slide">
        <div class="ml-5">
          <button class="btn custom-button-approve"
              (click)="openComment(commentModal, true)">
            <svg-icon class="glyphicon icon-check pointer-none"
                src="assets/images/icon-check-in.svg"
                [svgStyle]="{'height.px': 20, 'width.px': 20}"></svg-icon>
            <span class="ml-1 pointer-none">{{"FEED.ACK"|translate}}</span>
          </button>
        </div>
        <div class="">
          <button class="btn custom-button-reject"
              (click)="openComment(commentModal, false)">
            <svg-icon class="glyphicon icon-check pointer-none"
                src="assets/images/icon-cross.svg"></svg-icon>
            <span class="ml-1 pointer-none">{{"FEED.NOT-ACK"|translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #commentModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header red">
    <h4 class="modal-title">
      {{"LOG.COMMENT" | translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <input type="text"
        class="form-control"
        [(ngModel)]="ackComment">
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary"
        (click)="c()">
      {{"FEED.CANCEL" | translate}}
    </button>
    <button class="btn btn-primary"
        (click)="acknowledge()">
      {{"FEED.SENT" | translate}}
    </button>
  </div>
</ng-template>
