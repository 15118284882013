import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ThemeList } from '../../../../../shared/service/theme.service';

export interface SelectionTabItem {
  name: string;
  hasNotification: boolean;
  numberNotification?: number;
  translateLabel?: string;
  index?: number;
  primaryColor?: string;
  secondaryColor?: string;
  icon?: string;
  value?: string;
}

@Component({
  selector: 'app-selection-tab',
  templateUrl: './selection-tab.component.html',
  styleUrls: ['./selection-tab.component.scss'],
})
export class SelectionTabComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() items: SelectionTabItem[];
  @Input() themeList: ThemeList;
  @Input() showArrow = true;
  @Input() checkPage: string;
  @Input() checkPageMemo: string;
  @Input() hasEvidence: string;

  /**
   * Specify the step of scrolling.
   * The `window` type will take scroll range as long as the client width of tab element.
   * The `item` type will take scroll range as long as tab item width.
   */
  @Input() slideStep: SlideStep = 'window';
  @Input() newDesign = false;
  @Input() muteBadge = true;

  @Output() selectedItem = new EventEmitter<SelectionTabItem>();
  @ViewChild('itemContainer')
  itemContiner: ElementRef<HTMLDivElement>;
  @ViewChild('tab') tabElement: ElementRef<HTMLDivElement>;

  @ViewChild('scrollContent', { static: true })
  scrollContent: ElementRef;
  @ViewChild('rightArrow', { static: true }) rightArrow: ElementRef;
  @ViewChild('leftArrow', { static: true }) leftArrow: ElementRef;

  @Input() activeItem: SelectionTabItem = null;
  private distance = 200;

  public onClickScrollRight(): void {
    this.scrollTo('+', this.distance);
  }

  public onClickScrollLeft(): void {
    this.scrollTo('-', this.distance);
  }
  public fnShowArrow(arrow: ElementRef): void {
    arrow.nativeElement.classList.remove('hide');
  }

  private fnHideArrow(arrow: ElementRef): void {
    arrow.nativeElement.className += ' hide';
  }

  private scrollTo(operator: string, distance: number): void {
    const operators = {
      '+': (a, b) => {
        return a + b;
      },
      '-': (a, b) => {
        return a - b;
      },
    };
    const op = operators[operator];
    this.scrollContent.nativeElement.scrollTo({
      left: op(this.scrollContent.nativeElement.scrollLeft, distance),
      behavior: 'smooth',
    });
  }

  activeItemName: string;
  arrowInvisibility = {
    left: false,
    right: false,
  };
  /** The width of each tab item after DOM rendering. */
  tabItemWidths: number[] = [];

  private handlePageDetail(pageWidth: number) {
    if (pageWidth >= 1350) {
      this.fnHideArrow(this.rightArrow);
    } else {
      this.fnShowArrow(this.rightArrow);
    }
  }

  private handlePageNewFeedAnnouncement(pageWidth: number) {
    if (pageWidth >= 1200) {
      this.fnShowArrow(this.rightArrow);
    } else if (pageWidth >= 820) {
      this.fnHideArrow(this.rightArrow);
    } else {
      this.fnShowArrow(this.rightArrow);
    }
  }

  private handlePageMemoList(pageWidth: number) {
    if (pageWidth <= 1600) {
      this.fnShowArrow(this.rightArrow);
    } else {
      this.fnHideArrow(this.rightArrow);
    }
  }

  private handlePageAllDocuments(pageWidth: number) {
    if (pageWidth <= 1150) {
      this.fnShowArrow(this.rightArrow);
    } else {
      this.fnHideArrow(this.rightArrow);
    }
  }

  private handlePageTheme(pageWidth: number) {
    if (pageWidth < 1180) {
      this.fnShowArrow(this.rightArrow);
    } else {
      this.fnHideArrow(this.rightArrow);
    }
  }
  constructor() {}

  ngOnInit(): void {
    const intViewportWidth = window.innerWidth;
    this.checkResize(intViewportWidth);
    if (this.items?.length > 0 && !this.activeItem) {
      this.activeItem = this.items[0];
    }
    if (this.hasEvidence === 'review_evidence') {
      this.activeItem = this.items.find(
        (item) => item.name === 'Evidence',
      );
    }
    const element = document.getElementById('tab');
    if (element?.scrollLeft === 0) {
      this.arrowInvisibility.left = true;
    }
    setTimeout(() => {
      if (this.activeItem) {
        const index = this.items?.findIndex((obj) => {
          return obj.name == this.activeItem.name;
        });
        if (index > this.items.length / 2) {
          this.scrollContent.nativeElement.scrollTo({
            left: this.scrollContent.nativeElement.scrollWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 50);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasEvidence) {
      if (this.hasEvidence === 'review_evidence') {
        this.activeItem = this.items.find(
          (item) => item.name === 'Evidence',
        );
        const element = document.getElementById('tab');
        if (element?.scrollLeft === 0) {
          this.arrowInvisibility.left = true;
        }
        setTimeout(() => {
          this.scrollContent.nativeElement.scrollTo({
            left: this.scrollContent.nativeElement.scrollWidth,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
  }

  checkResize(event: number) {
    const pageWidth = event;
    switch (this.checkPage) {
      case 'pageDetail':
        this.handlePageDetail(pageWidth);
        break;
      case 'pageNewFeedAnnouncement':
        this.handlePageNewFeedAnnouncement(pageWidth);
        break;
      case 'pageMemoList':
        this.handlePageMemoList(pageWidth);
        break;
      case 'pageAllDocuments':
        this.handlePageAllDocuments(pageWidth);
        break;

      case 'pageThemeSetting':
        this.handlePageTheme(pageWidth);
        break;
    }
  }

  onResize(event) {
    this.checkResize(event.target.innerWidth);
  }

  onScroll(): void {
    this.validateArrowVisibility();
    const { scrollLeft, scrollWidth, offsetWidth } =
      this.scrollContent.nativeElement;

    // Left arrow
    scrollLeft === 0
      ? this.fnHideArrow(this.leftArrow)
      : this.fnShowArrow(this.leftArrow);

    // Right arrow
    const total = offsetWidth + scrollLeft;
    total + 3 >= scrollWidth
      ? this.fnHideArrow(this.rightArrow)
      : this.fnShowArrow(this.rightArrow);
  }

  ngAfterViewInit(): void {
    this.tabItemWidths = this.getWidthSelectionItem();
  }

  /**
   * Calculate the next displacement using the step as `item`.
   * The result refer from `scrollLeft` of the tab element.
   */
  getItemScrollDisplacement(isReverse = false): number {
    const scrollLeft = this.tabElement.nativeElement.scrollLeft;
    return this.tabItemWidths
      .slice(0)
      .reduce((acc, width, _index, arr) => {
        const nextDisplacement = acc + width;
        if (nextDisplacement > scrollLeft) {
          arr.splice(1);
          return isReverse ? acc : nextDisplacement;
        } else if (nextDisplacement === scrollLeft) {
          if (isReverse) {
            arr.splice(1);
            return acc;
          }
        }
        return nextDisplacement;
      }, 0);
  }

  getScrollDepartment(isReverse = false): number {
    if (this.slideStep === 'item') {
      return this.getItemScrollDisplacement(isReverse);
    }
    return this.getWindowScrollDisplacement(isReverse);
  }

  /** Return the width of the tab items by getting from DOM. */
  getWidthSelectionItem(): number[] {
    const items = this.itemContiner.nativeElement?.children;
    if (!items) {
      return;
    }
    const itemWidths: number[] = [];
    for (let i = 0; i < items.length; i++) {
      itemWidths.push(items.item(i).clientWidth);
    }
    return itemWidths;
  }

  /**
   * Calculate the next displacement using the step as `window`.
   * The result refer from `scrollLeft` of the tab element.
   */
  getWindowScrollDisplacement(isReverse = false): number {
    const scrollLeft = this.tabElement.nativeElement.scrollLeft;
    const tabWidth = this.tabElement.nativeElement.clientWidth;
    const tabItemsWidth = this.tabElement.nativeElement.scrollWidth;
    const scrollLeftMax = tabItemsWidth - tabWidth;
    const nextScrollLeft =
      scrollLeft + (isReverse ? -tabWidth : +tabWidth);
    if (nextScrollLeft <= 0) {
      return 0;
    } else if (nextScrollLeft >= scrollLeftMax) {
      return scrollLeftMax;
    }
    return this.tabItemWidths
      .slice(0)
      .reduce((acc, width, _index, arr) => {
        const nextDisplacement = acc + width;
        if (nextDisplacement > nextScrollLeft) {
          arr.splice(1);
          return isReverse ? nextDisplacement : acc;
        } else if (nextDisplacement === nextDisplacement) {
          if (isReverse) {
            return nextDisplacement;
          }
        }
        if (nextDisplacement > scrollLeftMax) {
          arr.splice(1);
          return nextDisplacement;
        }
        if (nextDisplacement <= 0) {
          arr.splice(1);
          return 0;
        }
        return nextDisplacement;
      }, 0);
  }

  onClickItem(item: SelectionTabItem): void {
    this.activeItem = item;
    this.selectedItem.emit(this.activeItem);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.tabItemWidths = this.getWidthSelectionItem();
  }

  slideScroll(
    direction: 'left' | 'right',
    stepInterval: number,
    distance: number,
    step: number,
  ): void {
    const element = document.getElementById('tab');
    let scrollAmount = 0;
    const slideTimer = window.setInterval(() => {
      const validStep =
        scrollAmount + step > distance
          ? distance - scrollAmount
          : step;
      if (direction === 'left') {
        element.scrollLeft -= validStep;
      } else {
        element.scrollLeft += validStep;
      }
      this.validateArrowVisibility();
      scrollAmount += validStep;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, stepInterval);
  }

  /** Refresh the visibility of the arrows by checking the DOM property of the tab element  */
  validateArrowVisibility(): void {
    const element = this.tabElement.nativeElement;
    this.arrowInvisibility = {
      left: false,
      right:
        element.scrollLeft + element.clientWidth >=
        element.scrollWidth,
    };
  }
}

type SlideStep = 'window' | 'item';
