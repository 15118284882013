<div class="option-form custom-box">
  <div class="mb-2">
    <label class="mt-2">{{ "UPLOAD.Field Name" | translate }}<span class="text-red">*</span>
    </label>
    <input class="form-control text-charcoal"
        type="text"
        [ngModel]="fieldName"
        (ngModelChange)="setValidate()"
        [ngClass]="duplicateName || !fieldName ? 'is-invalid' : ''"
        (keyup)="fieldNameChange.emit($event.target.value)"
        (keydown.enter)="fieldNameChange.emit($event.target.value)">
    <label class="text-red"
        *ngIf="duplicateName && fieldName">
      {{"UPLOAD.This field name is already taken"|translate}}
    </label>

    <label class="text-red"
        *ngIf="!fieldName">
      {{"UPLOAD.Field Name is required"|translate}}
    </label>
  </div>

  <div class="my-2">
    <div class="form-check">
      <input type="checkbox"
          class="form-check-input big-checkbox"
          [ngModel]="require"
          (ngModelChange)="requireChange.emit($event)">
      <label class="form-check-label ml-2 font-weight-normal f-14">
        {{ "UPLOAD.Required" | translate }}
      </label>
    </div>
  </div>


  <div class="mb-2">
    <!-- date format -->
    <label class="mt-2"
        for="dateFormatSelection">
      {{'UPLOAD.DATE-FORMAT'|translate}}<span class="text-red">*</span>
    </label>
    <div>
      <ng-select
          id="dateFormatSelection"
          [items]="formatItems"
          bindLabel="label"
          bindValue="value"
          [ngModel]="format"
          (ngModelChange)="changeFormat($event)"
          placeholder="select date format"
          [notFoundText]="'SELECT.No items found'|translate"
          appendTo=".template-custom-pdf-view"
          [closeOnSelect]="false"
          [clearable]="false">
      </ng-select>
    </div>
  </div>


</div>