import { Injectable } from '@angular/core';

export interface MemoStatusColor {
  status: string;
  color: ColorSet;
}

export interface ColorSet {
  primary: string;
  secondary: string;
  progress: string;
  progressBackground: string;
}

@Injectable({
  providedIn: 'root',
})
export class MemoStatusColorService {
  statusColorList: MemoStatusColor[] = [
    {
      status: 'draft',
      color: {
        primary: '#767676',
        secondary: '#cecece',
        progress: '#FFFFFF',
        progressBackground: '#F7F7F7',
      },
    },
    {
      status: 'pending',
      color: {
        primary: '#29ADCE',
        secondary: '#C8EBF4',
        progress: '#FFDD94',
        progressBackground: '#FEF7E6',
      },
    },
    {
      status: 'approved',
      color: {
        primary: '#32A17A',
        secondary: '#E7FFC6',
        progress: '#A2E4A8',
        progressBackground: '#E4FCE6',
      },
    },
    {
      status: 'rejected',
      color: {
        primary: '#FF5C00',
        secondary: '#FFE39B',
        progress: '#A2B6F9',
        progressBackground: '#EDF0FD',
      },
    },
    {
      status: 'expired',
      color: {
        primary: '#E84191',
        secondary: '#FFDAEE',
        progress: '#E84191',
        progressBackground: '#FFDAEE',
      },
    },
    {
      status: 'terminated',
      color: {
        primary: '#E84B41',
        secondary: '#FFDCDA',
        progress: '#F99191',
        progressBackground: '#FEE9E9',
      },
    },
    {
      status: 'review',
      color: {
        primary: '#2D5B8F',
        secondary: '#DCECFF',
        progress: '#FEF7E6',
        progressBackground: '#FEF7E6',
      },
    },
  ];

  getStatusColorSet(status: string): ColorSet {
    return this.statusColorList.find(
      (statusColor) => statusColor.status === status,
    ).color;
  }
}
