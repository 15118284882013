<div class="container-input-radio">
  <div #resizeContainer="ngResizable"
      class="resizable-widget position-relative custom-radio custom-position"
      rzHandles="se"
      [ngClass]="{'mark-require': markerIdentity.require, 'show-resizable-diagonal': canSettingForm}"
      [ngStyle]="resizableStyle"
      [ngResizable]="canSettingForm"
      [rzAspectRatio]="true"
      [rzContainment]="containment"
      (rzStop)="onResizeStop($event, markerIdentity)"
      (rzResizing)="onResizing($event)">

    <span class="radio-el"
        [ngClass]="{'event-none': !canFillForm}"
        [style.width]="_width !== null ? (_width-5) + 'px' : 'auto'"
        [style.height]="_width !== null ? (_width-5) + 'px' : 'auto'">
      <input type="checkbox"
          [style.color]="markerIdentity.color"
          class="big-checkbox input-checkbox"
          [style.width]="_width !== null ? (_width-5) + 'px' : 'auto'"
          [style.height]="_width !== null ? (_width-5) + 'px' : 'auto'"
          [style.margin-left]="'1px'"
          [(ngModel)]="markerIdentity.data"
          (click)="toggleCheck();$event.stopPropagation()"
          (change)="toggleCheck()"
          [placeholder]="canSettingForm ? CHECK_TEXT : ''"
          [disabled]="!canFillForm">

      <div class="radio-circle"></div>
      <input type="text"
          [ngClass]="!canFillForm? '': 'hide-input'"
          [style.width]="_width !== null ? (_width-5) + 'px' : 'auto'"
          [style.height]="_width !== null ? (_width-5) + 'px' : 'auto'"
          class="form-control">
    </span>
  </div>
</div>