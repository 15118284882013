import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { ThemeService } from 'src/app/shared/service/theme.service';
import { MemoService } from '../../../service/memo.service';
import { ApiService } from 'src/app/core/http/api.service';
import { BASE_URL } from 'src/app/shared/service/api.constant';
import { createDownloadFile } from 'src/app/shared/utils/utils';
import { HistoryDetail } from '../../../model/memo.model';
import { AlertService } from '../../../../../core/services/alert.service';
import { HttpHeaders } from '@angular/common/http';
import { ApproveService } from '../../../../approval/approve.service';
import { Subscription } from 'rxjs';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-memo-real-history',
  templateUrl: './memo-real-history.component.html',
  styleUrls: ['./memo-real-history.component.scss'],
})
export class MemoRealHistoryComponent implements OnInit, OnDestroy {
  historyList: HistoryDetail[];
  currentPage = 1;
  count = 0;
  showResults = false;
  showDetail = false;
  isLoadingExcel = false;
  isMobile: boolean;
  subscription = new Subscription();

  @Input() memo;
  @Input() memoID: any;
  @Output() previewAttachment = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private httpClientService: ApiService,
    private themeService: ThemeService,
    private memoService: MemoService,
    private store: Store,
    private approveService: ApproveService,
    private alert: AlertService,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.themeService.isMobile();
    this.getMemoHistoryList();
    const sub = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.getMemoHistoryList();
      },
    );
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  preview(history): void {
    const fileUrl = BASE_URL + history.detail;

    if (this.isMobile) {
      this.httpClientService.openMediaUrlInNewWindow(fileUrl);
    } else {
      this.previewAttachment.emit({
        document: fileUrl,
        name: '',
        show: true,
      });
    }
  }

  downloadDocument(history): void {
    const fileUrl = BASE_URL + history.detail;
    this.httpClientService.download(fileUrl).subscribe(
      (res: any) => {
        const blob: Blob = new Blob([res]);
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'old_memo.pdf';
        link.click();
      },
      (error) => {
        this.store.dispatch(new ErrorNotification(error));
      },
    );
  }

  getMemoHistoryList(): void {
    this.showResults = false;
    const header = new HttpHeaders().set(
      'Authorization',
      this.memoID,
    );
    // Check condition is non-user
    (this.memoID
      ? this.approveService.getMemoHistory(header, {
          memo: this.memo.id,
        })
      : this.memoService.getMemoHistory({ memo: this.memo.id })
    ).subscribe(
      (res: any) => {
        this.historyList = res;
        this.showResults = true;
      },
      (error) => {
        this.store.dispatch(new ErrorNotification(error));
        this.showResults = true;
      },
    );
  }

  downloadHistory(): void {
    this.isLoadingExcel = true;
    const params = {
      memo: this.memo.id,
    };
    this.memoService.getHistoryLogCSV(params).subscribe(
      (res) => {
        const filename = 'History Log Memo.csv';
        createDownloadFile(res, filename);
        this.isLoadingExcel = false;
        this.getMemoHistoryList();
      },
      (err) => {
        this.isLoadingExcel = false;
      },
    );
  }
}
