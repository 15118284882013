import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Marker } from '../../pdf-signature-customizer.model';

@Component({
  selector: 'app-remove-widget-setting-panel-item',
  templateUrl: './remove-widget-setting-panel-item.component.html',
  styleUrls: ['./remove-widget-setting-panel-item.component.scss'],
})
export class RemoveWidgetSettingPanelItemComponent {
  @Input() markerIdentity;
  @Input() marker: Marker;

  @Input() markers: Marker[];
  @Output() markersChange = new EventEmitter();

  constructor() {}

  onClick() {
    const targetMarkers = this.markers.filter(
      (marker) => marker.type === this.marker.type,
    );
    // Execute deletion
    targetMarkers.forEach((targetMarker) => {
      const identityIndex = targetMarker.positions?.findIndex(
        (identity) => identity === this.markerIdentity,
      );
      if (identityIndex === null || identityIndex === -1) {
        return;
      }
      targetMarker.positions.splice(identityIndex, 1);
    });
    this.markersChange.emit(this.markers);
  }
}
