<div class="option-form custom-box">

  <div class="mb-2">
    <label class="mt-2">{{ "UPLOAD.Field Name" | translate }}<span class="text-red">*</span>
    </label>
    <input class="form-control text-charcoal"
        type="text"
        [ngModel]="groupId"
        (keyup)="groupIdChange.emit($event.target.value)"
        (ngModalChange)="setValidate()"
        [ngClass]="markerIdentity.options.duplicateName || !markerIdentity.options.fieldName ?'is-invalid':''"
        (keydown.enter)="groupIdChange.emit($event.target.value)"
        (keyup)="onChange($event.target.value, 'fieldName')"
        (keydown.enter)="onChange($event.target.value, 'fieldName')">
    <label class="text-red"
        *ngIf="markerIdentity.options.duplicateName && markerIdentity.options.fieldName">
      {{"UPLOAD.This field name is already taken"|translate}}
    </label>
    <label class="text-red"
        *ngIf="!markerIdentity.options.fieldName">
      {{"UPLOAD.Field Name is required"|translate}}
    </label>
  </div>
  


  <div class="form-check mb-2">
    <input type="checkbox"
        class="form-check-input big-checkbox"
        [ngModel]="require"
        (ngModelChange)="onChange($event, 'require')">
    <label class="form-check-label ml-2 font-weight-normal f-14">
      {{ "UPLOAD.Required" | translate }}
    </label>
  </div>

  
    <div class="row mb-2">
      <label class="col-auto align-self-center"
          *ngIf="markerIdentity.formInputType === 'radio'">
        {{'UPLOAD.Radio group'|translate}}
      </label>
      <label class="col-auto align-self-center"
          *ngIf="markerIdentity.formInputType === 'radio_checkbox'">
        {{'UPLOAD.Checkbox group'|translate}}
      </label>
      <span class="col px-1"> {{numberInGroup}}</span>
    </div>
  
    <div class="mb-2">
    <label class="mt-2">{{ "UPLOAD.Field Value" | translate }}<span class="text-red">*</span>
    </label>
    <input class="form-control text-charcoal"
        type="text"
        [ngModel]="fieldValue"
        (keyup)="fieldValueChange.emit($event.target.value)"
        [ngClass]="markerIdentity.options.duplicateValue || !markerIdentity.options.fieldValue?'is-invalid':''"
        (keydown.enter)="fieldValueChange.emit($event.target.value)"
        (keyup)="onChange($event.target.value, 'fieldValue')"
        (keydown.enter)="onChange($event.target.value, 'fieldValue')">
    <label class="text-red"
        *ngIf="markerIdentity.options.duplicateValue && markerIdentity.options.fieldValue">
      {{"UPLOAD.This field value is already taken"|translate}}
    </label>
    <label class="text-red"
        *ngIf="!markerIdentity.options.fieldValue">
      {{"UPLOAD.Field Value is required"|translate}}
    </label>
  </div>
  
</div>