<div class="chat-container">
  <div class="chat-list" [style.--h]="normalHeight ? '0px' : '50px'">
    <div *ngIf="(commentList.length === 0 && showResult) else hasComment" class="no-comment text-center">
      <svg-icon src="assets/images/icons/no-comment.svg" [svgStyle]="{'width.px': 180, 'height.px': 180}"></svg-icon>
      <div class="text-muted">{{"DASHBOARD.NO-COMMENT"|translate}}</div>
    </div>
    <ng-template #hasComment>
      <ng-container *ngIf="showResult else showSpinner">
        <div class="chat-item" *ngFor="let stream of commentList">
          <div class="chat-photo">
            <i *ngIf="stream.created_user_photo === null" class="fas fa-user-circle"></i>
            <img *ngIf="stream.created_user_photo !== null" [src]="stream.created_user_photo | secure | async">
          </div>

          <div class="chat-info">
            <div class="chat-info-row">
              <span class="chat-owner-name">{{ stream.created_user_name }}</span>
              <span class="chat-time">{{ stream.time_since }}</span>
              <div class="chat-delete" *ngIf="(memoID ? isOwner(stream) : canDeleteComment(stream))">
                <app-delete-confirmation-modal [data]="stream.id" [useNewDesign]="true"
                  (submitted)="deleteComment($event.data)">
                </app-delete-confirmation-modal>
              </div>
            </div>

            <div class="chat-info-row" *ngIf="stream.message || stream.commentTh">
              <div class="chat-message flex-column" [innerHTML]="stream.message | linkify |safeHtml" *ngIf="checkLanguageEn"></div>
              <div class="chat-message flex-column" [innerHTML]="stream.commentTh | linkify | safeHtml" *ngIf="!checkLanguageEn"></div>
            </div>

            <div class="chat-info-row" *ngIf="stream.file">
              <ng-container *ngIf="checkImageURL(stream.file) else notImageFile">
                <img class="chat-image" [src]="stream.file | secure | async" (click)="preview(stream.file, stream.file_name, stream)">
              </ng-container>
              <ng-template #notImageFile>
                <div class="chat-file" (click)="preview(stream.file,stream.file_name, stream)">
                  <div class="file-type" [ngClass]="stream.extension">{{stream.extension}}</div>
                  <span>{{ stream.file_name }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #showSpinner>
        <app-loading-spinner *ngIf="!showResult"></app-loading-spinner>
      </ng-template>
    </ng-template>
  </div>
  <app-comment [key]="memoID" [memoId]="data" (submitted)="addComment($event)" (uploaded)="uploadFile($event)"></app-comment>
</div>

<div *ngIf="showPreview">
  <app-memo-preview [document]="documentData"
    [fileName]="documentName"
    [idMemo]="data"
    [downloadLogType]="documentDownloadLogType"
    (closed)="closePreview()">
  </app-memo-preview>
</div>
