<div class="history-container">
  <app-loading-spinner *ngIf="!showResults else showHistory" [loading]="!showResults"></app-loading-spinner>

  <ng-template #showHistory>
    <div class="h-100">
    <div class="history-list">
      <div *ngIf="(historyList.length===0 && showResults) else hasHistory" class="no-history text-center">
        <svg-icon src="assets/images/icons/no-history.svg" [svgStyle]="{'width.px': 180, 'height.px': 180}"></svg-icon>
        <div class="text-muted">{{"DASHBOARD.NO-HISTORY"|translate}}</div>
      </div>

      <ng-template #hasHistory>
        <div class="history-item" *ngFor="let stream of historyList">
          <div class="profile-photo">
            <i *ngIf="!stream.actor_photo" class="fas fa-user-circle"></i>
            <img *ngIf="stream.actor_photo" [src]="stream.actor_photo | secure | async">
          </div>
          <div class="history-item-info">
            <div class="info-detail">
              <p class="owner-name">{{ stream.actor?.full_name || ('MEMOS.System' | translate) }}</p>
              <p class="info-time">{{stream.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
            </div>
            <p *ngIf="!stream.transfer_type" class="info-status">{{ stream.action | translate}}</p>
            <p *ngIf="stream.transfer_type" class="info-status">
              {{ "HISTORY." + stream.transfer_type | translate}} {{ stream.personFrom}} {{ "HISTORY.TO" | translate}} {{ stream.personTo}}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="text-center">
      <button class="btn"
          appSetTheme
          type="button" 
          (click)="downloadHistory()">
        <span>{{ "LOG.DOWNLOAD-CSV" | translate }}</span>
      </button>
    </div>
    </div>
  </ng-template>
</div>
