import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/http/api.service';
import { Observable } from 'rxjs';
import { CeleryTaskStatus } from './manage-types-upload-memo.models';
import { API_URL } from 'src/app/shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class ManageTypesUploadMemoService {
  constructor(private http: ApiService) {}

  getUploadMemoTypeList(data?) {
    return this.http.get(`/api/memo-upload-type/`, data);
  }

  getUploadMemoTypeListById(id?) {
    return this.http.get(`/api/memo-upload-type/${id}`);
  }

  addUploadMemoType(data) {
    return this.http.post(`/api/memo-upload-type/`, data);
  }

  deleteUploadMemoType(id) {
    return this.http.delete(`/api/memo-upload-type/` + id + '/');
  }

  editUploadMemoType(id, data) {
    return this.http.patch(`/api/memo-upload-type/` + id + '/', data);
  }

  batchUploadUploadMemoType(data) {
    return this.http.post(
      `/api/batch-uploads/upload-memo-type/`,
      data,
    );
  }

  batchUploadUploadMemoTypeExample() {
    return this.http.getBlob(`/api/batch-uploads/upload-memo-type/`);
  }

  getCeleryTaskStatus(
    taskId: string,
    params?,
  ): Observable<CeleryTaskStatus> {
    return this.http.get(`/api/celery-tasks/` + taskId + '/', params);
  }

  cancelCeleryTask(taskId: string) {
    return this.http.delete(`/api/celery-tasks/` + taskId + '/');
  }

  getUploadMemoTemplateList(data?) {
    return this.http.get(`/api/memo-upload-template/`, data);
  }

  getUploadMemoTemplateListById(id?) {
    return this.http.get(`/api/memo-upload-template/${id}`);
  }

  getUploadMemoTemplate(id) {
    return this.http.get(`/api/memo-upload-template/${id}/`);
  }

  createUploadMemoTemplate(data) {
    return this.http.post(`/api/memo-upload-template/`, data);
  }

  updateUploadMemoTemplate(id, data) {
    return this.http.patch(`/api/memo-upload-template/${id}/`, data);
  }

  deleteUploadMemoTemplate(id) {
    return this.http.delete(`/api/memo-upload-template/` + id + '/');
  }

  getLOAData(id, params?) {
    return this.http.get(`/api/loa-groups/${id}/`, params);
  }

  getSearch(params?: { [type: string]: string }) {
    return this.http.get(API_URL.upload_memo_type, params);
  }
}
