import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { AngularResizableDirective } from 'angular2-draggable';

@Component({
  template: '',
})
export abstract class BaseInputComponent {
  state = '';

  /** The property name in `size` for binding height */
  @Input() bindHeight = 'H';
  /** The property name in `size` for binding width */
  @Input() bindWidth = 'W';
  @Input() containment: HTMLElement;
  @Input() resizableStyle: { [styleName: string]: string | number };
  @Input() lockRatio = false;
  /** Scale has purpose for responsive display */
  @Input() scale = 1;
  @Input() size: Partial<Size> = { height: null, width: 100 };
  @Input() sizeBoundary: SizeBoundary;
  @Input() resetButtonSize = 16;
  @Input() resetButtonStyle: { [styleName: string]: string };
  @Input() canFillForm = false;
  @Input() canSettingForm = false;
  @Output() sizeChange = new EventEmitter<Size>();

  @ViewChild('resizeContainer')
  resizeContainer: AngularResizableDirective;

  onResizeStart(event: IResizeEvent): void {
    this.state = 'Resize Started';
    this._setSize(event.size);
  }

  onResizing(event: IResizeEvent): void {
    this.state = 'Resizing';
    this._setSize(event.size);
  }

  onResizeStop(event: IResizeEvent): void {
    this.state = 'Resize Stopped';
    this._setSize(event.size);
    this.sizeChange.emit({
      height: this.size[this.bindHeight],
      width: this.size[this.bindWidth],
    });
  }

  onReset(block: AngularResizableDirective): void {
    block.resetSize();
    const status = block.getStatus();
    this._setSize(status.size);
  }

  protected _setSize(size: Size) {
    this._height = size.height;
    this._width = size.width;
  }

  get _height(): number {
    return this.size[this.bindHeight] * this.scale;
  }

  set _height(val: number) {
    const height = val / this.scale;
    this.size[this.bindHeight] = height;
  }

  get _width(): number {
    return this.size[this.bindWidth] * this.scale;
  }

  set _width(val: number) {
    const width = val / this.scale;
    this.size[this.bindWidth] = width;
  }
}

export interface Size {
  height: number;
  width: number;
}

export interface SizeBoundary {
  maxHeight: number;
  maxWidth: number;
  minHeight: number;
  minWidth: number;
}
