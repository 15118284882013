<ng-container>
  <div class="attachment-container text-right">

    <ng-container *ngIf="!showResults else showAttachment">
      <app-loading-spinner></app-loading-spinner>
    </ng-container>

    <ng-template #showAttachment>
      <div class="attachment-list">
        <div class="mb-2 pointer" (click)="openAttachmentModal()">
          <svg-icon src="assets/images/icons/Add.svg"
            [svgStyle]="{'width.px': 14, 'height.px': 14}"
            [style.fill]="themeList?.header_table_color"></svg-icon>
          <span [style.color]="themeList?.header_table_color"
            class="underline ml-2">{{ 'MEMOS.UPLOAD-ATTACHMENT'|translate }}</span>
        </div>

        <div *ngIf="(attachmentList.length===0 && showResults) else hasAttachment" class="no-attach-file text-center">
          <svg-icon src="assets/images/icons/no-attach.svg" [svgStyle]="{'width.px': 180, 'height.px': 180}"></svg-icon>
          <div class="text-muted">{{"DASHBOARD.NO-ATTACHMENT"|translate}}</div>
        </div>

        <ng-template #hasAttachment>
<!--          <div class="h-100 overflow-auto">-->
<!--            <div class="attach-item" *ngFor="let attachment of attachmentList; let index = index">-->
<!--              <div class="attach-item-info width-box" (click)="preview(attachment)">-->
<!--                <div class="file-type" [ngClass]="attachment.extension" (click)="preview(attachment)">-->
<!--                  {{attachment.extension}}-->
<!--                </div>-->
<!--                &lt;!&ndash; <a class="text-muted text-bold">{{ index + 1 }}. </a> &ndash;&gt;-->
<!--                <a href="javascript:void(0)" (click)="preview(attachment)" class="text-muted text-bold text-break name-ellipsis">-->
<!--                  {{ index + 1 }}. {{ attachment.short_name }}-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="attach-item-btn">-->
<!--                <svg-icon class="mr-2 pointer" src="assets/images/icon_download2.svg"-->
<!--                  [svgStyle]="{'width.px': 15, 'height.px': 15,'fill': '#4D4D4D'}"-->
<!--                  (click)="downloadDocument(attachment)"></svg-icon>-->
<!--                <app-delete-confirmation-modal [data]="attachment"-->
<!--                  [useNewDesign]="true"-->
<!--                  (submitted)="deleteAttachmentFile($event)">-->
<!--                </app-delete-confirmation-modal>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div class="h-100">
            <div class="w-100"
              *ngFor="let group of attachmentGroupByPerson">
              <p class="text-left pb-2 font-weight-bold">Attached by : {{group.full_name}}</p>
              <div class="attach-item"
                *ngFor="let attachment of group.attachments; let index = index">
                <div class="attach-item-info width-box"
                  (click)="preview(attachment)">
                  <div class="file-type"
                    [ngClass]="attachment.extension"
                    (click)="preview(attachment)">
                    {{attachment.extension}}
                  </div>
                  <!-- <a class="text-muted text-bold">{{ index + 1 }}. </a> -->
                  <a href="javascript:void(0)"
                    (click)="preview(attachment)"
                    class="text-muted text-bold text-break name-ellipsis">
                    {{ index + 1 }}. {{ attachment.short_name }}
                  </a>
                </div>
                <div class="attach-item-btn">
                  <svg-icon class="mr-2 pointer"
                    src="assets/images/icon_download2.svg"
                    [svgStyle]="{'width.px': 15, 'height.px': 15,'fill': '#4D4D4D'}"
                    (click)="downloadDocument(attachment)"></svg-icon>
                  <app-delete-confirmation-modal [data]="attachment"
                    [useNewDesign]="true"
                    (submitted)="deleteAttachmentFile($event)">
                  </app-delete-confirmation-modal>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>

  </div>
</ng-container>

<div *ngIf="showPreview">
  <app-memo-preview [document]="documentData"
    [fileName]="documentName"
    [idMemo]="memo.id"
    downloadLogType="attachment"
    (closed)="closePreview()">
  </app-memo-preview>
</div>

<app-attachment-modal #attachModal
  [isLoading]="isLoading"
  [canUploadUnlimited]="canUploadUnlimited"
  (submitted)="uploadFile($event)"></app-attachment-modal>
