import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-textline-setting',
  templateUrl: './input-textline-setting.component.html',
  styleUrls: ['./input-textline-setting.component.scss'],
})
export class InputTextlineSettingComponent {
  @Input() markerIdentity;
  @Output() markerIdentityChange = new EventEmitter();
  @ViewChild('maxLineInput') maxLineInput: ElementRef;

  constructor(private translate: TranslateService) {}

  onChange(value, field: string) {
    if (field === 'require') {
      this.markerIdentity.require = value;
      return;
    }
    this.markerIdentity.options[field] = value;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  disabledOnWheel(event: WheelEvent) {
    event.preventDefault();
    this.maxLineInput?.nativeElement.blur();
  }

  setValidate() {
    this.markerIdentity.options.duplicateName = false;
  }
}
