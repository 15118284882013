import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { InputTextlineComponent } from '../input-textline/input-textline.component';
import { DateFormatService } from 'src/app/shared/service/date-format.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormInputMarkerIdentity } from '../form-inputs.model';
import { SizeBoundary } from '../base-input/base-input.component';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { Router } from '@angular/router';
import { AngularResizableDirective } from 'angular2-draggable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: [
    '../base-input/base-input.component.scss',
    '../input-textline/input-textline.component.scss',
    './input-date.component.scss',
  ],
})
export class InputDateComponent
  extends InputTextlineComponent
  implements OnChanges, AfterContentChecked
{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Input() markerIdentity: FormInputMarkerIdentity<'date'>;
  @Input() sizeBoundary: SizeBoundary;

  @Output() setDragDropOnTop = new EventEmitter();

  @ViewChild('sizeBoxDate', { static: true })
  sizeBoxDate: ElementRef;
  @ViewChild('invf', { static: false })
  datePickerRef;

  @ViewChild('widthForDate', { static: true })
  widthForDate;

  @ViewChild('resizeContainer')
  resizeContainer: AngularResizableDirective;

  dateFormats;
  previewDate = null;
  heightDate;
  checkUrl;
  checkUrlPreview;
  spaceDate: number;
  currentFontSize: number;
  convertWidth = false;
  newSizeBoundary: number;
  subscription = new Subscription();
  constructor(
    public translateService: TranslateService,
    private service: DateFormatService,
    private memoService: MemoService,
    private router: Router,
    private cdref: ChangeDetectorRef,
  ) {
    super();
    this.dateFormats = this.service.constructFormatListFromDate();
    this.memoService.inputDateFormat.subscribe((event: any) => {
      if (this.markerIdentity.options.fieldName === event.name) {
        const date = new Date();
        const today =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate();
        const current = today;
        this.previewDate = this.service.getDateLabelWithDate(
          this.rawDateToNgbDateStruct(current),
          event.event,
        );
      }
    });

    const sub = this.memoService.widthForDate.subscribe(
      (event: string) => {
        this.convertWidth = true;
        this.newSizeBoundary = this.sizeBoundary?.minHeight * 2;
        if (event === 'format') {
          this.setWidthWidget();
        }
      },
    );
    this.subscription.add(sub);

    this.checkUrl = this.router.url.split('/')[1];
    this.checkUrlPreview = this.router.url.split('/')[2];
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.markerIdentity?.dateCustomBE) {
      this.previewDate = this.formattedDate(
        this.markerIdentity?.dateCustomBE,
      );
    } else {
      const date = new Date();
      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate();
      const current = today;
      this.previewDate = this.formattedDate(current);
    }
    this.setWidthWidget();
  }

  setWidthWidget() {
    const textWidth = this.calculateTextWidthPx(this.fontSize) + 4;
    setTimeout(() => {
      if (this._width < textWidth) {
        this._width = textWidth;
      } else {
        this.convertWidth = false;
      }
      this._height = this.fontSize * 1.1;
    }, 100);
  }

  onTextChange(event: string) {
    this.markerIdentity.options.rawDate = event;
    this.markerIdentity.data = this.formattedDate(event);
  }

  formattedDate(rawDate): string {
    return this.service.getDateLabelWithDate(
      this.rawDateToNgbDateStruct(rawDate),
      this.markerIdentity.options.dateFormat,
    );
  }

  toggleDatePicker(event: MouseEvent) {
    event.stopPropagation();
    if (this.canFillForm) {
      this.datePickerRef.toggle();
      this.setDragDropOnTop.emit();
    }
  }

  rawDateToNgbDateStruct(rawDate: string): NgbDateStruct {
    const [year, month, day] = rawDate.split('-');
    return {
      year: Number(year),
      month: Number(month),
      day: Number(day),
    };
  }

  onClear() {
    this.markerIdentity.options.rawDate = null;
    this.markerIdentity.data = null;
    this.datePickerRef.toggle();
  }

  checkPlacement(): string {
    let placement = 'auto';
    if (this.markerIdentity.Y < 25) {
      placement = 'bottom';
    }

    return placement;
  }

  calculateTextWidthPx(fontSize: number) {
    if (!this.canvasCtx) {
      return 0;
    }
    this.canvasCtx.font = `${fontSize}px ${this.fontFamily}`;
    return this.canvasCtx.measureText(this.previewDate).width;
  }

  getWidth() {
    const hideIconSetting = 24;
    const hideIconDate = 24;
    if (!this.canSettingForm && !this.canFillForm) {
      return this._width + hideIconSetting + hideIconDate;
    } else if (this.canFillForm) {
      return this._width + hideIconDate;
    }
    return this._width;
  }

  get checkPositionX(): boolean {
    const widthDate =
      this.sizeBoxDate?.nativeElement?.offsetWidth < 203;
    if (widthDate) {
      this.spaceDate =
        203 - this.sizeBoxDate?.nativeElement?.offsetWidth;
    }
    let leftPosition = false;
    if (this.markerIdentity.X < 5 && widthDate) {
      leftPosition = true;
    }
    return leftPosition;
  }

  get checkHeightBox(): boolean {
    this.heightDate = this.sizeBoxDate?.nativeElement?.offsetHeight;
    const heightDate =
      this.sizeBoxDate?.nativeElement?.offsetHeight < 20;
    if (this.sizeBoundary?.minHeight < 12 && heightDate) {
      return false;
    }
    return true;
  }

  get calculateWidthCols(): number {
    const test = this.widthForDate?.nativeElement?.offsetWidth;
    return test;
  }
}
