<ng-template #content
    let-modal
    let-close="close">
  <div class="preview-helper d-flex flex-row flex-md-column"
    style="gap:10px"
    [ngStyle]="{'margin-top.rem': isMobile ? 3 : 0}"
    >
    <div>
      <a class="f-30 text-white icon-circle"
          href="javascript:void(0)"
          (click)="closePreview()">
        <i class="fas fa-times"></i>
      </a>
    </div>

    <div class="order-first order-md-last">
      <a class="f-30 text-white icon-circle"
        href="javascript:void(0)"
        (click)="downloadDocument()">
        <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'fill': '#ffffff', 'height.px': 15, 'width.px': 15}"></svg-icon>
      </a>
    </div>
  </div>

  <div class="preview-content">
    <div class="pdf-viewer-viewer-window">
      <pdf-viewer
          [src]="{data: _fileBuffer, httpHeaders: httpHeaders}"
          (after-load-complete)="pageRendering()"
          [original-size]="false"
          [autoresize]="true"
          [fit-to-page]="true"
          [show-all]="true"
          [stick-to-page]="true"
          style="width: 100%; height: 90vh"
      ></pdf-viewer>

<!--      <div *ngIf="isBadFile()">-->
<!--        <div class="cannot-preview">-->
<!--          Cannot preview this file type<br>-->
<!--          Please download to view <br>-->
<!--          <a href="javascript:void(0)"-->
<!--              (click)="downloadDocument()">-->
<!--            Click here to download-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</ng-template>
