import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileSystemFileEntry } from 'ngx-file-drop';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { ErrorNotification } from '../../../../../store/memo/memo.actions';
import { MemoService } from '../../../service/memo.service';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss'],
})
export class AttachmentModalComponent {
  modal: NgbModalRef;
  files: any[] = [];
  uploadDocument: any;
  upload: any;
  cancel: any;
  fileUpload: File;
  totalFile = 0;
  @Input() data: any;
  @Input() isLoading = false;
  @Input() canUploadUnlimited = false;
  @Output() submitted = new EventEmitter();
  @ViewChild('attachmentModal', { static: true })
  attachmentModal: ElementRef;

  scanFileSpinner = 'scanFileSpinner';

  constructor(
    private modalService: NgbModal,
    private alert: AlertService,
    private translate: TranslateService,
    private memoService: MemoService,
    private store: Store,
    private spinner: NgxSpinnerService,
  ) {}

  open(content: ElementRef): void {
    this.uploadDocument = this.translate.instant(
      'MEMOS.UPLOAD-DOCUMENT',
    );
    this.upload = this.translate.instant('MEMOS.UPLOAD');
    this.cancel = this.translate.instant('MEMOS.CANCEL');
    this.modal = this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
    });
  }

  openFromOutside(): void {
    this.open(this.attachmentModal);
  }

  submit(): void {
    this.submitted.emit(this.files);
    if (!this.isLoading) {
      this.modal.close();
      this.files = [];
      this.totalFile = 0;
    }
  }

  checkFileSize(file: File): boolean {
    if (this.canUploadUnlimited) {
      return true;
    }
    if (file != null && file.size / (1024 * 1024) >= 25) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.Please upload attachment files less than 25 MB',
        ),
      );
      return false;
    }
    return true;
  }

  checkTotalFileSize(file: File): boolean {
    if (this.canUploadUnlimited) {
      return true;
    }
    this.totalFile += file.size;
    if (file != null && this.totalFile / (1000 * 1000) >= 45) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.The total file size exceeds 45 MB',
        ),
      );
      this.totalFile -= file.size;
      return false;
    }
    return true;
  }

  dropped(event): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry =
          droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!file) {
            return;
          }
          if (!this.checkFileSize(file)) {
            return;
          }
          if (!this.checkTotalFileSize(file)) {
            return;
          }
          this.addFile(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!file) {
        return;
      }
      if (!this.checkFileSize(file)) {
        return;
      }
      if (!this.checkTotalFileSize(file)) {
        return;
      }
      this.addFile(file);
    });
    fileInput.target.value = '';
  }

  removeFile(fileIndex, item) {
    this.files.splice(fileIndex, 1);
    this.totalFile -= item.size;
    return this.totalFile;
  }

  closeModal(): void {
    this.files = [];
    this.modal.close();
  }

  addFile(file: File): void {
    this.spinner.show(this.scanFileSpinner);
    const fd = new FormData();
    fd.append('file', file);
    this.memoService.validateFiles(fd).subscribe(
      () => {
        this.spinner.hide(this.scanFileSpinner);
        this.files.push(file);
      },
      (error) => {
        this.spinner.hide(this.scanFileSpinner);
        this.store.dispatch(new ErrorNotification(error));
      },
    );
  }
}
